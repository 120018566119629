import React from 'react';
import { makeStyles } from '@mui/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
//LOCAL IMPORTS
import { styleDesigns } from '../../theme/styles';

const useStyles = makeStyles(theme => ({
  typo: {
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    fontSize: '16px',
    marginTop: '3px',
    color: '#000000'
  }
}))

export default function BasicSwitch(props) {
  const classes = useStyles();
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={props && props.checked}
            onChange={props && props.switchChange}
            inputProps={{ 'aria-label': 'admin switch view' }}
          />
        }
        label={<Typography className={classes.typo}>{props && props.label}</Typography>}
      />
    </FormGroup>
  );
}