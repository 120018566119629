import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

//FOR TENANT CONFIGS
export const getTenantConfig = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.tenantConfigs.getTenantConfig}`
  axios.get(url, { params: payload }, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    }
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR LOGIN PAGE BANNER CONTENT
export const getLoginBannerContent = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.tenantConfigs.getLoginBannerContent}`
  axios.get(url, payload, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    }
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR GETTING THE COURSE PROVIDER IMAGES
export const getCourseProvderLogo = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.tenantConfigs.getCourseProviderLogos}?name=${payload.name}`
  axios.get(url, payload, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    }
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING THE ORG DEPT LIST
export const fetchOrgDepartments = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.tenantConfigs.fetchDepartments}`
  axios.get(url)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING THE COURSE CATEGORIES
export const getCourseCategories = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.tenantConfigs.getCourseCategories}`
  axios.get(url, { params: payLoad })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FETCH ORG COURSE CATEGORIES LIST
export const fetchCourseTagsList = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.tenantConfigs.fetchCourseTags}`
  axios.get(url, {
    params: payload,
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FETCH ORG DESIGNATIONS
export const fetchOrgDesignations = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.tenantConfigs.fetchOrgDesignations}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}