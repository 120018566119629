import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
//LOCAL IMPORTS
import { styleDesigns } from '../../theme/styles';
import { TextInputField } from '../../Shared/InputFields/textInputField';
import { ConfirmButton } from '../../Shared/Buttons/confirmBtn';
import { changePassword } from '../../store/Auth/actionCreator';
import InlineAlerts from '../../Shared/AlertMessages/inlineAlerts';
import i18n from '../../i18n/i18n'

const useStyles = makeStyles(theme => ({
  bodyWrapper: {
    paddingTop: '50px',
    maxWidth: '1200px',
    paddingLeft: '24px',
    paddingRight: '24px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    display: 'block',
    textAlign: 'center'
  },
  subTitle: {
    margin: '0px',
    lineHeight: '1.5',
    fontSize: '1rem',
    fontFamily: styleDesigns.fontFamily._heading,
    fontWeight: 400,
    color: styleDesigns.fontColour._grey
  },
  forgotText: {
    fontSize: `${styleDesigns.fontSize._heading} !important`,
    margin: '0px 0px 16px !important',
    fontWeight: `${styleDesigns.fontWeight._heading} !important`,
    lineHeight: 1.5,
    fontFamily: `${styleDesigns.fontFamily._heading} !important`
  },
  requestIcon: {
    marginBottom: '40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '160px'
  }
}));

function ChangePassword(props) {
  const userToken = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [errStatus, setErrStatus] = useState({
    status: false,
    errMessage: ''
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const successCallBack = res => {
    setErrStatus({
      status: false,
      errMessage: ''
    });
    setInProgress(false);
    navigate('/');
  }
  const failureCallBack = err => {
    if (err && err.status && err.status === 400) {
      setErrStatus({
        status: true,
        errMessage: err && err.data && err.data.password && err.data.password[0] ? err.data.password[0] : "Something went wrong! Please try again!"
      })
    } else {
      setErrStatus({
        status: true,
        errMessage: i18n.t('Something went wrong! Please try again later!')
      })
    }
    setInProgress(false);
  }

  const onChangePassword = event => {
    event.preventDefault()
    setErrStatus({
      status: false,
      errMessage: ''
    });
    setInProgress(false);
    const password = document.getElementById('password').value
    const confirmPassword = document.getElementById('confirmpassword').value
    if (password === '' || confirmPassword === '') {
      setErrStatus({
        status: true,
        errMessage: i18n.t('Please enter required information!')
      });
    } else if (password !== confirmPassword) {
      setErrStatus({
        status: true,
        errMessage: i18n.t('Both passwords should be same!')
      });
    } else {
      const payload = {
        password: document.getElementById('password').value,
        token: userToken && userToken.userToken
      }
      setInProgress(true);
      changePassword(payload, successCallBack, failureCallBack)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.bodyWrapper}>
        <Container component="main" maxWidth="sm">
          <Typography className={classes.forgotText}>{i18n.t('Reset password')}</Typography>
          <p className={classes.subTitle}>{i18n.t('Enter your new password below.')}</p>
          {errStatus.status ? (
            <InlineAlerts status={errStatus.status ? 'error' : 'success'} message={errStatus.errMessage} />
          ) : null}
          <form className={classes.form} noValidate onSubmit={onChangePassword}>
            <TextInputField
              type={showPassword ? 'text' : 'password'}
              id="password"
              textField="password"
              ariaLabel="Please add new password"
              label={i18n.t("New Password")}
              name="newpassword"
              startIcon="password"
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              required={true}
              autoFocus={true}
            />
            <TextInputField
              type={showPassword ? 'text' : 'password'}
              id="confirmpassword"
              textField="password"
              ariaLabel="Please confirm your password"
              label={i18n.t("Confirm New Password")}
              name="confirmnewpassword"
              startIcon="password"
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              required={true}
            />
            <ConfirmButton
              id="changepassword"
              ariaLabel="change password"
              label={i18n.t("Change Password")}
              onBtnClick={onChangePassword}
              disabled={inProgress}
            />
          </form>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    authDetails: state.authReducer.authDetails,
  }
}
export default connect(
  mapStateToProps, {}
)(ChangePassword);