import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import "@fontsource/roboto";
import axios from 'axios';
//LOCAL IMPORTS
import history from './history';
import MainLayout from './Layout/MainLayout';
import './i18n/i18n';
import { getDomainInfo, generateJwtToken, getCookie, getEnv } from './Helpers/basic';
import { getTenantConfig } from './store/TenantConfigs/actionCreator';
import { apiConfig } from './Configs/apiConfig';
import myAthinaLoader from './assests/athinaLoader.gif';

axios.interceptors.request.use(
  config => {
    if ((config && config.url !== apiConfig.tenantConfigs.getTenantConfig) || (config && config.url !== apiConfig.tenantConfigs.getLoginBannerContent)) {
      if (getDomainInfo() !== null) {
        const domainDet = getDomainInfo();
        let token = '';
        if (domainDet.domainTokenLabel !== undefined) {
          var cookieArr = document.cookie.split(";");
          for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");
            if (domainDet.domainTokenLabel === cookiePair[0].trim()) {
              token = decodeURIComponent(cookiePair[1]);
            }
          }
        }

        //FOR REFRESH AND ACCESS TOKENS
        let accessToken = ''
        if (domainDet.allowAccessRefreshToken && domainDet.domainUserAccessToken !== undefined) {
          accessToken = getCookie(domainDet.domainUserAccessToken)
        }
        if (token !== '') {
          if (domainDet.allowAccessRefreshToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
          } else {
            config.headers.Authorization = `Token ${token}`;
          }
        }
      }
      return config;
    }
  },
  error => {
    Promise.reject(error)
  }
);

let isRefreshing = false;
let oldApisArray = [];

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error && error.response && error.response.status === 428) {
    window.location = '/welcome?from=cHJvZmlsZW1hbmRhdGU='
    return new Promise(() => { });
  } else if (axios.isCancel(error)) {
    return new Promise(() => { });
  } else {
    const domainInfo = getDomainInfo();
    const myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 1);

    //FOR CHECKING THE TENANT CONFIG AND 401 STATUS OF API
    if (domainInfo && Object.keys(domainInfo) && Object.keys(domainInfo).length) {
      if (domainInfo.allowAccessRefreshToken && error.response.status === 401) {
        const { config, response: { status } } = error;
        const originalRequest = config; //it will have the entire api information incliding header, url and all

        if (status === 401) {
          //IF ISREFRESHING IS FALSE THEN ONLY IT WILL GO INSIDE
          //IF ITS TRUE IT WILL WAIT UNTIL OLD REFRESH CALL GET DONE
          if (!isRefreshing) {
            isRefreshing = true;

            //FOR HITTING THE REFRESH ACCESS UPDATE API
            const apiUrl = apiConfig.tenantConfigs.getNewRefreshToken;
            const refreshToken = getCookie(domainInfo.domainUserRefreshToken);
            axios.post(apiUrl, { "refresh": refreshToken })
              .then(({ data }) => {
                isRefreshing = false;
                const { access, refresh } = data;
                document.cookie = domainInfo.domainUserAccessToken + "=" + access + ";path=/;expires=" + myDate + ";domain=" + domainInfo.domainName + ";secure";
                document.cookie = domainInfo.domainUserRefreshToken + "=" + refresh + ";path=/;expires=" + myDate + ";domain=" + domainInfo.domainName + ";secure";
                //FOR PASSING THE NEWLY UPDATED ACCESS TOKEN TO RUN OLD API CALLS
                runOldApiCalls(data.access);
              })
              .catch(e => {
                document.cookie = domainInfo.domainTokenLabel + '=; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' + domainInfo.domainName;
                document.cookie = domainInfo.domainUserLabel + '=; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' + domainInfo.domainName;
                document.cookie = domainInfo.domainUserAccessToken + '=; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' + domainInfo.domainName;
                document.cookie = domainInfo.domainUserRefreshToken + '=; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' + domainInfo.domainName;
                localStorage.removeItem('user_active_menus', []);
                localStorage.removeItem(window.location.origin, "");
                window.location = '/';
              })
          }

          //FOR STORING THE OLD API CALLS WHICH ARE FAILED DUE TO EXPIRY TOKEN ISSUE
          const retryOrigReq = new Promise((resolve, reject) => {
            storeOldApiCall(accessToken => {
              // replace the expired accessToken and retry
              originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
              resolve(axios(originalRequest));
            });
          });
          return retryOrigReq;

        } else {
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    }
  }
});

const storeOldApiCall = (cb) => {
  oldApisArray.push(cb);
}

const runOldApiCalls = (accessToken) => {
  oldApisArray.map(cb => cb(accessToken));
  oldApisArray = [];
}


function App() {
  const [loadApp, setLoadApp] = useState(false);
  const successCallBack = res => {
    if (res && res.results) {
      const parsedTenantInfo = generateJwtToken(res.results)
      localStorage.setItem(window.location.origin, parsedTenantInfo)
    }
    setLoadApp(false);
  }

  const failureCallBack = err => {
    setLoadApp(false);
  }

  useEffect(() => {
    let tenantConfig = localStorage.getItem(window.location.origin)
    if (tenantConfig === null) {
      setLoadApp(true);
      let payload = {}
      if (getEnv() && getEnv() === 'dev_uxv2') {
        payload = {
          'localhost': true
        }
      }
      getTenantConfig(payload, successCallBack, failureCallBack)
    }
    window.FreshworksWidget('hide');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback="Please Wait">
      <Router location={history.location} history={history}>
        <div className="App">
          {loadApp ? (
            <img
              src={myAthinaLoader}
              alt="loader"
              style={{
                height: '60%',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '60%',
                paddingTop: '80px'
              }}
            />
          ) : <MainLayout location={history.location} history={history} />}
        </div>
      </Router>
    </Suspense>
  );
}

export default App;
