import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
//LOCAL IMPORTS
import en from './en.json';
import sp from './sp.json';
import te from './te.json';
import hi from './hi.json';

// the translations
const resources = {
  en: {
    translation: en
  },
  sp: {
    translation: sp
  },
  te: {
    translation: te
  },
  hi: {
    translation: hi
  }
};


i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources, //for loading the translation files
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // backend: {
    //   // for all available options read the backend's repository readme file
    //   crossDomain: false,
    //   loadPath: "https://kalyan-continualengine.github.io/en.json",
    //   allowMultiLoading: true
    // }
  });

export default i18n;