import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
//LOCAL IMPORTS
import { getTenant } from '../../Configs/tenantConfig';
import { getCookie } from '../../Helpers/basic';
import Header from './header';
import LeftMenu from './leftMenu';
import AppRouter from '../appRouter';
import { LeftMenuContext } from '../../Shared/Context/leftMenuContext';

import { StyledEngineProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const cache = createCache({
  key: 'css',
  prepend: true,
});

export default function MainLayout(props) {
  const pathName = props && props.location && props.location.pathname;
  const tenantInfo = getTenant();
  const token = (tenantInfo && getCookie(tenantInfo.domainTokenLabel)) ? true : false
  const showLeftMenu = token && pathName !== '/redirect' && pathName !== '/welcome' && pathName !== '/forgotpassword' && pathName !== '/' && !pathName.includes('/auth/sso-mard/') && !pathName.includes('/Y2hhbmdlX3Bhc3N3b3Jk')
  const [open, setOpen] = useState(false);
  const [isLeftMenuPinned, setIsLeftMenuPinned] = useState(false);
  const [bodyContentLeftMargin, setBodyContentLeftMargin] = useState(showLeftMenu ? 50 : 0)

  //FOR MENU EXPAND, SELECTED INFORMATION CONTEXT THINGS
  const [selectedMenu, setSelectedMenu] = useState('');
  const contextValue = { menuExpanded: isLeftMenuPinned, selectedMenu, setSelectedMenu };

  const [clickedClose, setClickedClose] = useState(false);

  useEffect(() => {
    if (tenantInfo && tenantInfo.name && tenantInfo.name === 'PREP' && token) {
      if (!isLeftMenuPinned && !clickedClose) {
        setOpen(true)
        setIsLeftMenuPinned(true)
        setBodyContentLeftMargin(215)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantInfo])

  const handleDrawerOpen = () => {
    setOpen(true);
    setClickedClose(false)
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setClickedClose(true);
  };
  const onMenuMouseOver = () => {
    if (!isLeftMenuPinned) {
      setOpen(true)
    }
  }
  const onMenuMouseLeave = () => {
    if (!isLeftMenuPinned) {
      setOpen(false)
    }
  }
  const onPinLeftMenu = (status) => {
    setIsLeftMenuPinned(status)
    setBodyContentLeftMargin(status ? 215 : 43)
  }
  return (
    <CacheProvider value={cache}>
      <StyledEngineProvider injectFirst>
        <Box>
          <CssBaseline />
          <LeftMenuContext.Provider value={contextValue}>
            {showLeftMenu ? (
              <React.Fragment>
                <Header
                  handleDrawerOpen={handleDrawerOpen}
                  open={open}
                  onPinLeftMenu={onPinLeftMenu}
                  handleDrawerClose={handleDrawerClose}
                  showCompare={contextValue.selectedMenu === '/search'}
                />
                <LeftMenu
                  location={props.location}
                  history={props.history}
                  handleDrawerOpen={handleDrawerOpen}
                  handleDrawerClose={handleDrawerClose}
                  open={open}
                  onMenuMouseOver={onMenuMouseOver}
                  onMenuMouseLeave={onMenuMouseLeave}
                  onPinLeftMenu={onPinLeftMenu}
                />
              </React.Fragment>
            ) : null}
            <Box sx={{ flexGrow: 1, p: showLeftMenu ? 3 : 0 }} style={{ marginLeft: bodyContentLeftMargin, marginTop: showLeftMenu ? '63px' : '0px', paddingRight: '0px' }}>
              <AppRouter location={props} history={props.history} />
            </Box>
          </LeftMenuContext.Provider>
        </Box>
      </StyledEngineProvider>
    </CacheProvider>
  );
}
