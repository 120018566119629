import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';
import { mockInterests } from './mockSkills';

export const fetchProfile = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.fetch}`
  axios.get(url, { params: payLoad })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const updateProfile = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.update}${payLoad.user_id}${'/'}`
  axios.put(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchSkills = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.fetchSkills}`
  axios.get(url)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchInterests = () => {
  return mockInterests
}

export const changePassword = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.changePassword}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.response)
    })
}

export const updateProfilePic = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.updateProfilePic}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.response)
    })
}

export const fetchProfilePic = (payLoad, successCallBack, failureCallBack) => dispatch => {
  const url = `${apiConfig.profile.fetchProfilePic}`
  axios.get(url)
    .then(function (response) {
      dispatch({ type: 'FETCH_USER_PROFILE', payload: response })
      successCallBack(response.data)
    })
    .catch(function (error) {
      dispatch({ type: 'REMOVE_USER_PROFILE', payload: [] })
      failureCallBack(error)
    })
}

export const removeProfilePic = (formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.removeProfilePic}`
  axios.delete(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.response)
    })
}

//PROFILE V2 API CALLBACKS

//FOR FETCHING THE USER COURSE COUNTS
export const fetchUserCrsCounts = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.fetchUserCrsCounts}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.response)
    })
}

//FOR FETCHING THE SKILL SUMMARY
export const fetchV2SkillSummary = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.fetchV2SkillSummary}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.response)
    })
}

//FOR FETCHING THE USER SKILLS
export const fetchV2UserSkills = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.fetchV2UserSkills}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.response)
    })
}

//FOR FETCHING THE USER SKILLS
export const fetchV2LearningGoals = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.fetchV2LearningGoals}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.response)
    })
}
//FOR FETCHING THE USER WORK TIME ALLOCATIONS
export const fetchV2WorkTimeAllocations = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.fetchV2WorkTimeAllocations}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.response)
    })
}

//FOR FETCHING THE EACH SKILL SUMMARY SKILL COURSES
export const fetchSkillSummarySkillCourses = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.fetchSkillSummarySkillCourses}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.response)
    })
}

//ON ASK TO UPDATE PROFILE ACTIONS CLICKS
export const postActionAskToUpdateProfile = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.postActionAskToUpdateProfile}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data);
    })
    .catch(function (error) {
      //failureCallBack(error.response)
      successCallBack({})
    })
}

