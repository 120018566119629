import Medium from '../assests/articlesImages/medium.jpg';
import Wikipedia from '../assests/articlesImages/wikipedia.jpg'
import Stackoverflow from '../assests/articlesImages/stackoverflow.jpg'

export const articleImgMap = {
  Medium: {
    img: Medium
  },
  Wikipedia: {
    img: Wikipedia
  },
  Stackoverflow: {
    img: Stackoverflow
  }
}