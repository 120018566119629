export const courseLevel = [
  {
    id: 1,
    name: 'Beginner Level'
  },
  {
    id: 2,
    name: 'Intermediate Level'
  },
  {
    id: 3,
    name: 'Expert Level'
  }
]
export const enableDetailsPage = false;
export const enableShareOption = {
  enabled: false,
  options: {
    url: false,
    fb: false,
    ln: false
  }
}
export const courseComparison = {
  enabled: true,
  limit: 3,
}
export const providerImgMap = {}
