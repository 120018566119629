import React from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
//LOCAL IMPORTS
import { styleDesigns } from '../../theme/styles'

export const TextInputField = (props) => {
  //FOR RENDERING THE INPUT TEXT FIELD
  //TYPE IS FOR DEFINEING THE TYPE OF TEXT FIELD
  //REQUIRED IS FOR RENDERING THE REQUIRED FIELD OR NORMAL FIELD
  //ERROR IS FOR EMPTY ERROR CHECK
  //MULTILINE IS FOR MULTILINE TEXTFIELD
  //ROWS WILL DEFINE HOW MANY ROWS WILL BE NEEDED IN MULTILINE
  //HELPER TEXT IS FOR SHOWING THE HELPER TEXT IN FIELD
  //ID FOR UNIQUIE IDENTITY
  //AUTO COMPLETE TO SUGGETIONS
  //ARIA LABEL FOR ACCESSBILITY
  //LABEL FOR RENDERING THE TITLE OF TEXT FIELD
  //NAME FOR THE TEXT FILED NAME IDENTITY
  //DEFAULT VALUE FOR RENDERING THE ANY DEFAULT VALUE
  //INPUTPROPS IS FOR MAX COUNT OF TEXT FILED
  //AUTO FOCUS IS TEXT THEN IT WILL BE TRUE
  //startIcon is to rendering the start icon of text field
  //variant will define what type of field outlined or standard
  //size for text field size
  //TEXT FIELD FOR RENDERIGN THE ICONS
  //AUTOFOCUS FOR AUTO FOCUS OF TEXT FIELD
  //disabled FOR DISABLING THE TEXT FIELD
  const classes = useStyles();
  return (
    <TextField
      style={{ marginTop: props.marginTop && props.marginTop }}
      variant={props.variant ? props.variant : "standard"}
      margin="normal"
      type={props.type}
      required={props.required}
      fullWidth
      onChange={props && props.onChange ? props.onChange : null}
      size={props && props.size ? props.size : null}
      error={props.error ? props.error : false}
      multiline={props.multiline ? props.multiline : false}
      rows={props.rows ? props.rows : ''}
      helperText={props.helperText ? props.helperText : ''}
      id={props.id}
      autoComplete={props.type === 'password' ? "current-password" : "off"}
      aria-label={props.ariaLabel}
      label={props.label}
      name={props.name}
      defaultValue={props && props.defaultValue}
      disabled={props.disabled ? true : false}
      inputProps={{ maxLength: props.maxWordsCount ? props.maxWordsCount : 1000 }}
      autoFocus={props.autoFocus ? true : false}
      InputLabelProps={{
        classes: {
          root: props.from === 'normalType' ? classes.normalTypeLabel : classes.cssLabel
        },
      }}
      InputProps={{
        classes: {
          root: props.from === 'discussionReply' ? classes.discussionReplyMultiOutlinedInput : (props.multiline && props.from === 'authorcourses') ? classes.authorcssMultiOutlinedInput : props.from === 'authorcourses' ? classes.authorcssOutlinedInput : props.from === 'normalType' ? classes.normalcssOutlinedInput : classes.cssOutlinedInput,
          notchedOutline: props.from === 'discussionReply' ? classes.discussionReplyOutline : props.from === 'authorcourses' ? classes.authornotchedOutline : props.from === 'normalType' ? classes.normalnotchedOutline : classes.notchedOutline,
        },
        startAdornment:
          props.startIcon === 'username' ? (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ) : props.startIcon === 'password' ? (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ) : null,
        endAdornment:
          props.textField === 'password' &&
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle secret visibility"
              onClick={props.handleClickShowPassword}
              onMouseDown={props.handleMouseDownPassword}
            >
              {props.type === 'text' ? <VisibilityIcon style={{ color: 'rgb(99, 115, 129)' }} /> : <VisibilityOffIcon style={{ color: 'rgb(99, 115, 129)' }} />}
            </IconButton>
          </InputAdornment>
      }}
    />
  )
}


const useStyles = makeStyles(theme => ({
  cssOutlinedInput: {
    '$notchedOutline': {
      fontFamily: `${styleDesigns.textField._fontFamily} !important`,
      borderColor: `${styleDesigns.textField._borderColor} !important`,
    },
    borderRadius: '8px !important',
    webkitBoxShadow: '0 0 0px 1000px white inset !important',
  },
  normalcssOutlinedInput: {
    '$notchedOutline': {
      fontFamily: `${styleDesigns.textField._fontFamily} !important`,
      borderColor: `${styleDesigns.fontFamily._appBlue} !important`,
    },
    borderRadius: '8px !important',
    webkitBoxShadow: '0 0 0px 1000px white inset !important',
  },
  authorcssOutlinedInput: {
    '$notchedOutline': {
      fontFamily: `${styleDesigns.textField._fontFamily} !important`,
      borderColor: '#e5e5e5 !important',
    },
    borderRadius: '18px !important',
    height: '50px',
    webkitBoxShadow: '0 0 0px 1000px white inset !important',
  },
  authorcssMultiOutlinedInput: {
    '$notchedOutline': {
      fontFamily: `${styleDesigns.textField._fontFamily} !important`,
      borderColor: '#e5e5e5 !important',
    },
    borderRadius: '18px !important',
    webkitBoxShadow: '0 0 0px 1000px white inset !important',
  },
  cssLabel: {
    fontSize: styleDesigns.textField._fontSize,
    fontFamily: `${styleDesigns.textField._fontFamily} !important`,
    color: `${styleDesigns.textField._borderColor}`,
    "&.Mui-focused": {
      color: `${styleDesigns.textField._borderColor}`
    }
  },
  normalTypeLabel: {
    fontSize: styleDesigns.textField._fontSize,
    fontFamily: `${styleDesigns.textField._fontFamily} !important`,
    color: `${styleDesigns.fontColour._appBlue}`,
    "&.Mui-focused": {
      color: `${styleDesigns.fontColour._appBlue}`
    }
  },
  notchedOutline: {
    borderWidth: styleDesigns.textField._borderWidth,
    borderColor: `${styleDesigns.textField._borderColor} !important`
  },
  normalnotchedOutline: {
    borderWidth: styleDesigns.textField._borderWidth,
    borderColor: `${styleDesigns.fontColour._appBlue} !important`
  },
  authornotchedOutline: {
    borderWidth: styleDesigns.textField._borderWidth,
    borderColor: '#e5e5e5 !important'
  },
  discussionReplyMultiOutlinedInput: {
    '$notchedOutline': {
      fontFamily: `${styleDesigns.textField._fontFamily} !important`,
      borderColor: '#e5e5e5 !important',
    },
    borderRadius: '13px !important',
    height: '44px',
    marginTop: '-20px',
    webkitBoxShadow: '0 0 0px 1000px white inset !important',
  },
  discussionReplyOutline: {
    borderWidth: styleDesigns.textField._borderWidth,
    borderColor: '#e5e5e5 !important'
  }
}))