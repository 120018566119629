import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
//LOCAL IMPORTS
import { styleDesigns } from '../../theme/styles';
import CircularLoader from '../Loaders/CircularLoaders/circularLoader';

const useStyles = makeStyles(theme => ({
  btnWrapper: {
    '&:hover': {
      backgroundColor: `${styleDesigns.cnfirmButton._hover} !important`,
      boxShadow: 'none !important'
    },
    paddingTop: '10px',
    fontWeight: `${styleDesigns.cnfirmButton._fontWeight} !important`,
    fontSize: `${styleDesigns.cnfirmButton._fontSize} !important`,
    textTransform: `${styleDesigns.cnfirmButton._textTransform} !important`,
    fontFamily: `${styleDesigns.cnfirmButton._fontFamily} !important`,
    borderRadius: `${styleDesigns.cnfirmButton._borderRadius} !important`,
    color: `${styleDesigns.cnfirmButton._color} !important`,
    backgroundColor: `${styleDesigns.cnfirmButton._backgroundColor}`,
    boxShadow: `${styleDesigns.cnfirmButton._boxShadow}`,
    height: `${styleDesigns.cnfirmButton._height} !important`,
  },
  green: {
    '&:hover': {
      backgroundColor: `${styleDesigns.fontColour._appGreen} !important`,
      boxShadow: 'none !important'
    },
    paddingTop: '10px',
    fontWeight: `${styleDesigns.cnfirmButton._fontWeight} !important`,
    fontSize: `21px !important`,
    textTransform: `${styleDesigns.cnfirmButton._textTransform} !important`,
    fontFamily: `${styleDesigns.cnfirmButton._fontFamily} !important`,
    borderRadius: `${styleDesigns.cnfirmButton._borderRadius} !important`,
    color: `${styleDesigns.cnfirmButton._color} !important`,
    backgroundColor: styleDesigns.fontColour._appGreen,
    boxShadow: `${styleDesigns.cnfirmButton._boxShadow}`,
    height: `${styleDesigns.cnfirmButton._height} !important`,
  },
  tableView: {
    '&:hover': {
      backgroundColor: `${styleDesigns.cnfirmButton._hover} !important`,
      boxShadow: 'none !important'
    },
    paddingTop: '10px',
    fontWeight: `${styleDesigns.cnfirmButton._fontWeight} !important`,
    textTransform: `${styleDesigns.cnfirmButton._textTransform} !important`,
    fontFamily: `${styleDesigns.cnfirmButton._fontFamily} !important`,
    borderRadius: `${styleDesigns.cnfirmButton._borderRadius} !important`,
    color: `${styleDesigns.cnfirmButton._color} !important`,
    backgroundColor: `${styleDesigns.cnfirmButton._backgroundColor}`,
    boxShadow: `${styleDesigns.cnfirmButton._boxShadow}`,
    height: `35px !important`,
  },
  red: {
    '&:hover': {
      backgroundColor: `rgb(183, 33, 54) !important`,
      boxShadow: 'none !important'
    },
    paddingTop: '10px',
    fontWeight: `${styleDesigns.cnfirmButton._fontWeight} !important`,
    textTransform: `${styleDesigns.cnfirmButton._textTransform} !important`,
    fontFamily: `${styleDesigns.cnfirmButton._fontFamily} !important`,
    borderRadius: `${styleDesigns.cnfirmButton._borderRadius} !important`,
    color: `${styleDesigns.cnfirmButton._color} !important`,
    backgroundColor: 'rgb(255, 72, 66)',
    boxShadow: `rgb(255 72 66 / 24%) 0px 8px 16px 0px`,
    height: `35px !important`,
  },
  normalType: {
    '&:hover': {
      backgroundColor: `${styleDesigns.fontColour._appBlue} !important`,
      boxShadow: 'none !important'
    },
    paddingTop: '10px',
    fontWeight: `${styleDesigns.cnfirmButton._fontWeight} !important`,
    textTransform: `${styleDesigns.cnfirmButton._textTransform} !important`,
    fontFamily: `${styleDesigns.cnfirmButton._fontFamily} !important`,
    color: styleDesigns.fontColour._white,
    borderRadius: `${styleDesigns.cnfirmButton._borderRadius} !important`,
    backgroundColor: styleDesigns.fontColour._appBlue,
    boxShadow: `${styleDesigns.fontColour._appBlue}`,
    height: `32px !important`,
    width: '100%'
  },
  normalTypeGreen: {
    '&:hover': {
      backgroundColor: `${styleDesigns.fontColour._appGreen} !important`,
      boxShadow: 'none !important'
    },
    paddingTop: '10px',
    fontWeight: `${styleDesigns.cnfirmButton._fontWeight} !important`,
    textTransform: `${styleDesigns.cnfirmButton._textTransform} !important`,
    fontFamily: `${styleDesigns.cnfirmButton._fontFamily} !important`,
    color: styleDesigns.fontColour._white,
    backgroundColor: styleDesigns.fontColour._appGreen,
    boxShadow: `${styleDesigns.fontColour._appGreen}`,
    borderRadius: `4px !important`,
    height: `35px !important`,
    width: '100%'
  },
  outlinedRed: {
    '&:hover': {
      borderColor: styleDesigns.fontColour._red,
      backgroundColor: 'none !important',
      boxShadow: 'none !important'
    },
    paddingTop: '10px',
    fontWeight: `${styleDesigns.cnfirmButton._fontWeight} !important`,
    textTransform: `${styleDesigns.cnfirmButton._textTransform} !important`,
    fontFamily: `${styleDesigns.cnfirmButton._fontFamily} !important`,
    color: styleDesigns.fontColour._red,
    borderRadius: `${styleDesigns.cnfirmButton._borderRadius} !important`,
    borderColor: styleDesigns.fontColour._red,
    boxShadow: `${styleDesigns.fontColour._appBlue}`,
    height: `32px !important`,
    width: '100%'
  },
  profile: {
    marginRight: '84px',
    borderRadius: '45px',
    height: '55px',
    width: '269px',
    color: styleDesigns.fontColour._white,
    fontWeight: 'bold',
    fontSize: '20px',
    textTransform: 'none',
    backgroundColor: styleDesigns.fontColour._appGreen,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._appGreen,
      boxShadow: 'none !important'
    },
  },
  welcomeactive: {
    marginRight: '10px',
    borderRadius: '45px',
    height: '46px',
    width: '211px',
    color: styleDesigns.fontColour._white,
    fontWeight: 'bold',
    fontSize: '20px',
    backgroundColor: styleDesigns.fontColour._appGreen,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._appGreen,
      boxShadow: 'none !important'
    },
  },
  welcomestart: {
    marginRight: '10px',
    borderRadius: '45px',
    height: '52px',
    width: '261px',
    color: styleDesigns.fontColour._white,
    fontWeight: 'bold',
    fontSize: '29px',
    backgroundColor: styleDesigns.fontColour._appGreen,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._appGreen,
      boxShadow: 'none !important'
    },
  },
  welcomeinactive: {
    marginRight: '10px',
    borderRadius: '45px',
    height: '46px',
    width: '211px',
    color: styleDesigns.fontColour._white,
    fontWeight: 'bold',
    fontSize: '20px',
    backgroundColor: '#adadad',
    '&:hover': {
      backgroundColor: '#adadad',
      boxShadow: 'none !important'
    },
  },
  authorCoursesNative: {
    marginRight: '10px',
    borderRadius: '45px',
    height: '39px',
    width: '190px',
    color: '#555555',
    fontWeight: 'bold',
    fontSize: '16px',
    boxShadow: 'none !important',
    backgroundColor: '#eaeaea',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#eaeaea',
      boxShadow: 'none !important'
    }
  },
  authorCourses: {
    marginRight: '10px',
    borderRadius: '45px',
    height: '39px',
    width: '190px',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '16px',
    boxShadow: 'none !important',
    textTransform: 'none',
    backgroundColor: styleDesigns.fontColour._appGreen,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._appGreen,
      boxShadow: 'none !important'
    }
  },
  gradeUpdate: {
    marginRight: '10px',
    borderRadius: '45px',
    height: '39px',
    width: '168px',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '16px',
    boxShadow: 'none !important',
    textTransform: 'none',
    backgroundColor: styleDesigns.fontColour._appGreen,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._appGreen,
      boxShadow: 'none !important'
    }
  },
  assessments: {
    marginRight: '10px',
    borderRadius: '45px',
    height: '40px',
    width: '166px',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '16px',
    boxShadow: 'none !important',
    textTransform: 'none',
    backgroundColor: styleDesigns.fontColour._appGreen,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._appGreen,
      boxShadow: 'none !important'
    }
  },
  greenWhiteBorder: {
    border: '1px solid #ffffff',
    marginRight: '10px',
    borderRadius: '45px',
    height: '39px',
    width: '190px',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '16px',
    boxShadow: 'none !important',
    textTransform: 'none',
    backgroundColor: styleDesigns.fontColour._appGreen,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._appGreen,
      boxShadow: 'none !important'
    }
  },
  publishcancel: {
    marginRight: '10px',
    borderRadius: '45px',
    height: '34px',
    width: '157px',
    color: '#707070',
    fontWeight: 'bold',
    fontSize: '16px',
    boxShadow: 'none !important',
    backgroundColor: '#dcdcdc',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#dcdcdc',
      boxShadow: 'none !important'
    }
  },
  discussions: {
    marginRight: '10px',
    borderRadius: '45px',
    height: '34px',
    width: '157px',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '16px',
    boxShadow: 'none !important',
    textTransform: 'none',
    backgroundColor: styleDesigns.fontColour._appGreen,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._appGreen,
      boxShadow: 'none !important'
    }
  },
  discussionsresponse: {
    marginLeft: '10px',
    height: '31px',
    width: '138px',
    color: '#ffffff',
    fontSize: '12px',
    borderRadius: '7px',
    boxShadow: 'none !important',
    textTransform: 'none',
    backgroundColor: styleDesigns.fontColour._appGreen,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._appGreen,
      boxShadow: 'none !important'
    }
  },
  discussionsreply: {
    marginLeft: '10px',
    height: '31px',
    width: '130px',
    color: '#ffffff',
    fontSize: '13px',
    borderRadius: '7px',
    boxShadow: 'none !important',
    textTransform: 'none',
    backgroundColor: styleDesigns.fontColour._appGreen,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._appGreen,
      boxShadow: 'none !important'
    }
  },
  postresponse: {
    height: '31px',
    width: '118px',
    color: '#ffffff',
    fontSize: '12px',
    marginRight: '10px',
    borderRadius: '10px',
    boxShadow: 'none !important',
    textTransform: 'none',
    backgroundColor: styleDesigns.fontColour._appGreen,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._appGreen,
      boxShadow: 'none !important'
    }
  },
  gradepost: {
    marginTop: '11px',
    marginLeft: '10px',
    height: '31px',
    width: '88px',
    color: '#ffffff',
    fontSize: '14px',
    marginRight: '10px',
    borderRadius: '10px',
    boxShadow: 'none !important',
    textTransform: 'none',
    backgroundColor: styleDesigns.fontColour._appGreen,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._appGreen,
      boxShadow: 'none !important'
    }
  },
  cancelresponse: {
    marginRight: '10px',
    borderRadius: '10px',
    height: '31px',
    width: '118px',
    color: '#555555',
    fontSize: '12px',
    boxShadow: 'none !important',
    backgroundColor: '#eaeaea',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#eaeaea',
      boxShadow: 'none !important'
    }
  }
}));

export const ConfirmButton = (props) => {
  //ID WILL DEFINE THE BUTTON ID FOR UNIQUE IDENTITY     id
  //ARIALABEL IS FOR ACCESSIBILITY                       ariaLabel
  //ONCLICK IS FOR ON CLICK EVENT TRIGGER                onBtnClick
  //DISABLED IS TO DISABLE THE BUTTON                    disabled
  //HIDEINLOADER IS TO SHOW OR HIDE THE LOADER INSIDE THE BUTTON NEXT TO LABLE hideInLoader
  //LABLE IS FOR BUTTON NAME OR TITLE OF BUTTON          label
  //START ICON WILL DEFINE THE START ICON OF BUTTON      startIcon
  //END ICON WILL DEFINE THE END ICON OF BUTTON          endIcon

  //FROM normaltype for normal buttons design as ux team
  const classes = useStyles();
  return (
    <Button
      className={props.from ? clsx(classes[props.from]) : classes.btnWrapper}
      id={props && props.id && props.id}
      aria-label={props && props.ariaLabel && props.ariaLabel}
      fullWidth
      variant={props.variant ? props.variant : "contained"}
      sx={{ mt: 3, mb: 2 }}
      startIcon={props.startIcon ? props.startIcon : null}
      endIcon={props.endIcon ? props.endIcon : null}
      onClick={props && props.onBtnClick}
      disabled={props.disabled}
      type="submit"
    >
      <React.Fragment>
        {props.disabled && !props.hideInLoader && <CircularLoader size={25} color="#fff" style={{ marginRight: '10px', marginTop: '-4px' }} />}
        {props && props.label && props.label}
      </React.Fragment>
    </Button>
  )
}