import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';
const qs = require('querystring')

export const makeVisitedCourse = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.makeVisitedCourse}`
  axios.post(
    url,
    qs.stringify(payLoad)
  )
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
export const getCourseDetails = (courseId, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.detailsUrl}?courseId=${courseId}`
  axios.get(url)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
export const fetchRelatedCourses = (searchParams, successCallBack, failureCallBack) => {

  const url = `${apiConfig.course.relatedCourses}`;
  let paramObj = {
    searchKeyword: searchParams.keyword,
    related_course_id: searchParams.courseId,
    number: 6,
    offset: 0
  }
  axios.get(url, {
    params: paramObj
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
export const recommendTo = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.search.recommendToUrl}`
  axios.post(
    url,
    payLoad
  )
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
