const brandingColor = "#4B87C7";
const brandingColorShadow = "rgb(75 135 199 / 24%)";
const brandingColorHover = "#6f9fd2";

// const brandingColor = "#26b34b";
// const brandingColorShadow = "rgb(38 179 75 / 24%)";
// const brandingColorHover = "#388e3c";

// const brandingColor = "rgb(0, 69, 255)"
// const brandingColorShadow = "rgb(0, 69, 255/ 24%)"
// const brandingColorHover = "rgb(0, 39, 183)"

const brandingFontFamily = "Acumin Pro"

const myAthinaGreen = "#26b34b";
const myAthinaGreenShadow = "rgb(38 179 75 / 24%)";
const white = "#fff";
const lightGrey = "#637381";
const red = "#EE554E";
const blue = "#3299F7";
const hoverColor = "rgba(145, 158, 171, 0.08)";
const orange = "#7A4F01";
const error = "#FCECEA";
const info = "#D1F2FD";
const warning = "#FFC107";
const success = "#E9FCD4";
const whitesmoke = "#f5f5f5"
const appBlue = "#2e8eec"; //NEW NESTERSHUB BLUE COLOR
const appGreen = "#46af61"; //NEW NESTERHUB GREEN COLOR

//'Public Sans, sans-serif',

export const styleDesigns = {
  hoverColors: {
    _hoverBrandColor: brandingColorHover
  },
  fontColour: {
    _myAthinaBlue: brandingColor,
    _myAthinaBlueShadow: brandingColorShadow,
    _myAthinaGreen: myAthinaGreen,
    _myAthinaGreenShadow: myAthinaGreenShadow,
    _lightGrey: hoverColor,
    _grey: lightGrey,
    _whitesmoke: whitesmoke,
    _red: red,
    _blue: blue,
    _white: white,
    _orange: orange,
    _errorColor: error,
    _infoColor: info,
    _warningColor: warning,
    _successColor: success,
    _appBlue: appBlue,
    _appGreen: appGreen
  },
  fontSize: {
    _heading: '2rem',
    _infoHeading: '1rem',
    _subHeading: '1rem',
  },
  fontWeight: {
    _heading: 700,
    _infoHeading: 400
  },
  fontWeightGeneric: {
    _heading: 600,
    _subHeading: 400,
    _description: 300,
  },
  fontFamily: {
    _heading: brandingFontFamily,
  },
  cnfirmButton: {
    _backgroundColor: brandingColor,
    _fontWeight: 700,
    _fontSize: '0.9375rem',
    _textTransform: 'none',
    _fontFamily: brandingFontFamily,
    _borderRadius: '8px',
    _color: white,
    _boxShadow: `${brandingColorShadow} 0px 8px 16px 0px`,
    _height: '48px',
    _hover: brandingColorHover
  },
  cancelButton: {
    _fontWeight: 700,
    _fontSize: '0.9375rem',
    _textTransform: 'none',
    _fontFamily: brandingFontFamily,
    _borderRadius: '8px',
    _color: brandingColor,
    _height: '48px',
    _hover: brandingColorShadow,
    _marginTop: '0px'
  },
  textField: {
    _borderColor: brandingColor,
    _fontFamily: brandingFontFamily,
    _fontSize: 17,
    _borderWidth: 1
  },
};
