const initState = {
  userProfilePicture: null
}
const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'FETCH_USER_PROFILE':
      return {
        userProfilePicture: action.payload.data.avatar_url
      }
    case 'REMOVE_USER_PROFILE':
      return {
        userProfilePicture: null
      }
    default:
      return state
  }
}
export default reducer;