import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
//LOCAL IMPORTS
import rootReducer from './store/rootReducer';

export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk)
  );
}