import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

export const fetchStepperComponents = (payLoad, successCallBack, failureCallBack, userData) => {
  const url = `${apiConfig.userProfile.fetchComponents}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data, userData)
    })
    .catch(function (error) {
      failureCallBack(error, userData)
    })
}

export const fetchRoles = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userProfile.fetchRoles}`
  axios.get(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchSkills = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userProfile.fetchSkiils}`
  axios.get(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchInterest = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userProfile.fetchInterest}`
  axios.get(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchWorkAllocations = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userProfile.worktimeallocation}`
  axios.get(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const submitDetails = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userProfile.submitDetails}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.response)
    })
}

//NEW UX FLOW WELCOME PAGE REVAMP

//FOR FETCHING THE CATEGORYWISE SKILLS
export const fetchCategoryWiseSkills = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userProfile.fetchCategoryWiseSkills}`
  axios.get(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.response)
    })
}
//FOR FETCHING THE CATEGORYWISE INTERESTS
export const fetchCategoryWiseInterests = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userProfile.fetchCategoryWiseInterests}`
  axios.get(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.response)
    })
}