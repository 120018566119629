import React, { Suspense, lazy } from 'react';
import myAthinaLoader from '../assests/athinaLoader.gif';

// ------------------------------------- LANDING PAGE --------------------------------------------
const LandingPageComponent = lazy(() => import('../Components/LandingPage'));
//FOR LANDING PAGE
export const LandingPage = () => {
  return (
    <div>
      <Suspense fallback={<div>
        <img
          src={myAthinaLoader}
          alt="loader"
          style={{
            height: '70%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '70%'
          }}
        />
      </div>}>
        <LandingPageComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- DASHBOARD --------------------------------------------
const DashboardComponent = lazy(() => import('../Components/Dashboard'));
//FOR DASHBOARD PAGE
export const Dashboard = () => {
  return (
    <div>
      <Suspense fallback={<div>
        <img
          src={myAthinaLoader}
          alt="loader"
          style={{
            height: '70%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '70%'
          }}
        />
      </div>}>
        <DashboardComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- SKILL MAP --------------------------------------------
const SkillmapComponent = lazy(() => import('../Components/SkillMap'));
//FOR SKILLMAP PAGE
export const SkillMap = () => {
  return (
    <div>
      <Suspense fallback={<div>
        <img
          src={myAthinaLoader}
          alt="loader"
          style={{
            height: '70%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '70%'
          }}
        />
      </div>}>
        <SkillmapComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- SKILL INDEX --------------------------------------------
const SkillIndexComponent = lazy(() => import('../Components/SkillIndex'));
//FOR SKILL INDEX PAGE
export const SkillIndex = () => {
  return (
    <div>
      <Suspense fallback={<div>
        <img
          src={myAthinaLoader}
          alt="loader"
          style={{
            height: '70%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '70%'
          }}
        />
      </div>}>
        <SkillIndexComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- MANAGE BUDGET --------------------------------------------
const ManageBudgetComponent = lazy(() => import('../Components/ManageBudget'));
//FOR MANAGE BUDGET PAGE
export const ManageBudget = () => {
  return (
    <div>
      <Suspense fallback={<div>
        <img
          src={myAthinaLoader}
          alt="loader"
          style={{
            height: '70%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '70%'
          }}
        />
      </div>}>
        <ManageBudgetComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- MYTEAM --------------------------------------------
const MyTeamComponent = lazy(() => import('../Components/MyTeam'));
//FOR MYTEAM PAGE
export const MyTeam = () => {
  return (
    <div>
      <Suspense fallback={<div>
        <img
          src={myAthinaLoader}
          alt="loader"
          style={{
            height: '70%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '70%'
          }}
        />
      </div>}>
        <MyTeamComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- SEARCH --------------------------------------------
const SearchComponent = lazy(() => import('../Components/Search'));
//FOR SEARCH PAGE
export const Search = () => {
  return (
    <div>
      <Suspense fallback={<div>
        <img
          src={myAthinaLoader}
          alt="loader"
          style={{
            height: '70%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '70%'
          }}
        />
      </div>}>
        <SearchComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- MY COURSES --------------------------------------------
const MyCoursesComponent = lazy(() => import('../Components/MyCourses'));
//FOR MYCOURSES PAGE
export const MyCourses = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <MyCoursesComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- MY LEARNING PATH --------------------------------------------
const MyLearningPathComponent = lazy(() => import('../Components/MyLearningPath'));
//FOR MY LEARNING PATH PAGE
export const MyLearningPath = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <MyLearningPathComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- MY BUDGET --------------------------------------------
const MyBudgetComponent = lazy(() => import('../Components/MyBudget'));
//FOR MYBUDGET PAGE
export const MyBudget = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <MyBudgetComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- APPROVAL REQUESTS --------------------------------------------
const ApprovalRequestsComponent = lazy(() => import('../Components/ApprovalRequests'));
//FOR APPROVAL REQUEST PAGE
export const ApprovalRequests = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <ApprovalRequestsComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- VERIFY APPROVALS --------------------------------------------
const VerifyApprovalsComponent = lazy(() => import('../Components/VerifyApprovals'));
//FOR VERIFY APPROVALS PAGE
export const VerifyApprovals = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <VerifyApprovalsComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- MY EVENTS --------------------------------------------
const MyEventsComponent = lazy(() => import('../Components/MyEvents'));
//FOR MYEVENTS PAGE
export const MyEvents = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <MyEventsComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- MY CERTIFICATES --------------------------------------------
const MyCertificatesComponent = lazy(() => import('../Components/MyCertificates'));
//FOR MY CERTIFICATES PAGE
export const MyCertificates = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <MyCertificatesComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- MY DOCS --------------------------------------------
const MyDocsComponent = lazy(() => import('../Components/MyDocs'));
//FOR MYDOCS PAGE
export const MyDocs = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <MyDocsComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- INTERNAL COURSES --------------------------------------------
const InternalCoursesComponent = lazy(() => import('../Components/InternalCourses'));
//FOR INTERNAL COURSES PAGE
export const InternalCourses = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <InternalCoursesComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- PROFILE --------------------------------------------
const ProfileComponent = lazy(() => import('../Components/Profile'));
//FOR PROFILE PAGE
export const Profile = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <ProfileComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- USER PROFILE WELCOME --------------------------------------------
const UserProfileComponent = lazy(() => import('../Components/UserProfile'));
//FOR SEARCH PAGE
export const UserProfile = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <UserProfileComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- SETTINGS --------------------------------------------
const SettingsComponent = lazy(() => import('../Components/Settings'));
//ONBOARDING UI
export const UserSettings = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <SettingsComponent />
      </Suspense>
    </div>
  );
}

// ------------------------------------- DEVELOPER CONFIGS --------------------------------------------
const DevConfigsComponent = lazy(() => import('../Components/DeveloperConfigs'));
//FOR ONLY DEVELOPER CONFIGS
export const DeveloperConfigs = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <DevConfigsComponent />
      </Suspense>
    </div>
  )
}

// ------------------------------------- ANSR GEMS CLIENTS --------------------------------------------
const ManageClientsComponent = lazy(() => import('../Components/ManageClients'));
//FOR ONLY DEVELOPER CONFIGS
export const ManageClients = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <ManageClientsComponent />
      </Suspense>
    </div>
  )
}
// ------------------------------------- ANSR GEMS CLIENT --------------------------------------------
const ManageClientDashboard = lazy(() => import('../Components/ClientDashboard'));
//FOR ONLY DEVELOPER CONFIGS
export const ClientDashboard = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <ManageClientDashboard />
      </Suspense>
    </div>
  )
}
// ------------------------------------- ANSR GEMS CLIENT MANAGE GROUPS --------------------------------------------
const ManageClientGroups = lazy(() => import('../Components/ManageGroups'));
//FOR ONLY DEVELOPER CONFIGS
export const ManageGroups = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <ManageClientGroups />
      </Suspense>
    </div>
  )
}
// ------------------------------------- ANSR GEMS CLIENT MANAGE GROUP LEARNERS--------------------------------------------
const ManageClientGroupLearners = lazy(() => import('../Components/ManageGroupLearners'));
//FOR ONLY DEVELOPER CONFIGS
export const ManageGroupLearners = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <ManageClientGroupLearners />
      </Suspense>
    </div>
  )
}
// ------------------------------------- ANSR GEMS CLIENT MANAGE GROUP LEARNERS--------------------------------------------
const ManageClientCoupons = lazy(() => import('../Components/ManageCoupons'));
//FOR ONLY DEVELOPER CONFIGS
export const ManageCoupons = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <ManageClientCoupons />
      </Suspense>
    </div>
  )
}
// ------------------------------------- ANSR GEMS CLIENT COUPONS DASHBOARD--------------------------------------------
const ManageCouponDashboard = lazy(() => import('../Components/CouponDashboard'));
//FOR ONLY DEVELOPER CONFIGS
export const CouponDashboard = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <ManageCouponDashboard />
      </Suspense>
    </div>
  )
}

// ------------------------------------- AUTHOR COURSES --------------------------------------------
const AuthorCoursesComponent = lazy(() => import('../Components/AuthorCourses'));
//FOR AUTHOR COURSES PAGE
export const AuthorCourses = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <AuthorCoursesComponent />
      </Suspense>
    </div>
  );
}
//FOR INITIATE COURSE
const InitiateCourseComponent = lazy(() => import('../Components/AuthorCourses/CourseInfo'));
export const InitiateCourse = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <InitiateCourseComponent />
      </Suspense>
    </div>
  )
}

//FOR EDIT COURSE
const EditCourseComponent = lazy(() => import('../Components/AuthorCourses/MyCourse'));
export const EditAuthoredCourse = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <EditCourseComponent />
      </Suspense>
    </div>
  )
}

//FOR ASSESSMENTS
const AssessmentComponent = lazy(() => import('../Components/AuthorCourses/Assessments'));
export const Assessments = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <AssessmentComponent />
      </Suspense>
    </div>
  )
}

//FOR ASSIGNMENTS
const AssignmentComponent = lazy(() => import('../Components/AuthorCourses/Assignments'));
export const Assignments = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <AssignmentComponent />
      </Suspense>
    </div>
  )
}

//FOR DISCUSSIONS
const DiscussionComponent = lazy(() => import('../Components/AuthorCourses/Discussions'));
export const Discussions = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <DiscussionComponent />
      </Suspense>
    </div>
  )
}

//FOR COURSE PUBLISH SCREEN
const CoursePublishComponent = lazy(() => import('../Components/AuthorCourses/CoursePublish'));
export const CoursePublish = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <CoursePublishComponent />
      </Suspense>
    </div>
  )
}

//FOR COURSE REPORT SCREEN
const CourseReportComponent = lazy(() => import('../Components/AuthorCourses/CourseReport'));
export const CourseReport = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <CourseReportComponent />
      </Suspense>
    </div>
  )
}

//FOR GRADEBOOK SCREEN
const CourseGradeBookComponent = lazy(() => import('../Components/AuthorCourses/GradeBook'));
export const GradeBook = () => {
  return (
    <div>
      <Suspense fallback={<div><img
        src={myAthinaLoader}
        alt="loader"
        style={{
          height: '70%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '70%'
        }}
      /></div>}>
        <CourseGradeBookComponent />
      </Suspense>
    </div>
  )
}

