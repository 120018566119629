import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';




export const fetchMembers = (payLoad, successCallBack, failureCallBack) => {
  // const url =`${apiConfig.members.listUrl}?user_id=${payLoad.user_id}&offset=${payLoad.offset}&number=${payLoad.number}`
  const url = `${apiConfig.members.listUrl}${payLoad.user_id}/&offset=${payLoad.offset}&number=${payLoad.number}`
  axios.get(url)
    .then(function (response) {
      successCallBack(response.data)
      // successCallBack({count: 0})
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchTeams = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.teams.listURL}`
  axios.get(url)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const addMembers = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.members.addMembers}`
  axios.post(url, { data: payLoad })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchMemberProfile = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.fetch}`
  axios.get(
    url,
    {
      params: payLoad
    })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const toggleAddMemberAccess = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.members.toggleAddMemberAccess}${payLoad.user_id}${'/'}`
  axios.put(url, { can_add_user: payLoad.can_add_user })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack()
    })
}

export const removingUser = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.members.removingUser}${payLoad.user_id}${'/'}`
  axios.put(url, { active: payLoad.active })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack()
    })
}

// const members = {
//   "results": [
//     { "id": 8, "department_id": 3, "name": "Ganesan D", "email": "ganesan.d@continualengine.com" },
//     { "id": 11, "department_id": 4, "name": "Dhanap Prabhu", "email": "dhana.prabhu@continualengine.com" },
//     { "id": 12, "department_id": 4, "name": "Kalyan K", "email": "kalyan.kumar@continualengine.com" }]
// }

export const fetchMembersList = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.members.membersList}`
  axios.get(
    url,
    {
      params: payLoad
    })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchOrgMembersList = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.members.orgMembersList}`
  axios.get(
    url,
    {
      params: payLoad
    })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

// export const teamList = [{
//   id: 1,
//   name: 'Production',
//   team_members: [{
//     id: 101,
//     name: 'Francis Jacob',
//     email: 'francis.jacob@ce.com'
//   }, {
//     id: 102,
//     name: 'Robert Langden',
//     email: 'Robert.Langden@ce.com'
//   }, {
//     id: 103,
//     name: 'Elvis Persley',
//     email: 'epersley@ce.com'
//   }]
// }, {
//   id: 2,
//   name: 'Marketing',
//   team_members: [{
//     id: 104,
//     name: 'Nicolas Cage',
//     email: 'nocolas.cage@ce.com'
//   }, {
//     id: 105,
//     name: 'Robert Drowney',
//     email: 'robert.drowney@ce.com'
//   }, {
//     id: 106,
//     name: 'Austin Stevens',
//     email: 'stevens.austinb@ce.com'
//   }]
// }, {
//   id: 3,
//   name: 'HR',
//   team_members: [{
//     id: 107,
//     name: 'Rango Marl',
//     email: 'rango.marl@ce.com'
//   }, {
//     id: 108,
//     name: 'Stephen Hawking',
//     email: 'stephen.hawking@ce.com'
//   }, {
//     id: 109,
//     name: 'Nathan John',
//     email: 'john.nathan@ce.com'
//   }]
// }]

// export const teamList = {
//   "data":
//     [{ "id": 1, "name": "HR", "team_members": [{ "id": 12, "name": "Kalyan K", "email": "kalyan.kumar@continualengine.com" }, { "id": 13, "name": "Mousumi Kapoor", "email": "mousumi@continualengine.com" }, { "id": 8, "name": "Ganesan D", "email": "ganesan.d@continualengine.com" }, { "id": 11, "name": "Dhanap Prabhu", "email": "dhana.prabhu@continualengine.com" }, { "id": 9, "name": "Vikram Prasanna", "email": "vikram@continualengine.com" }, { "id": 10, "name": "Sayantika Banik", "email": "sayantika.banik@continualengine.com" }, { "id": 7, "name": "Laukik Sarode", "email": "laukik.sarode@continualengine.com" }] },
//     { "id": 2, "name": "CE", "team_members": [{ "id": 15, "name": "AAA", "email": "kalyan.kumar@continualengine.com" }, { "id": 157, "name": "Mousumi Kapoor", "email": "mousumi@continualengine.com" }, { "id": 9, "name": "Ganesan D", "email": "ganesan.d@continualengine.com" }, { "id": 101, "name": "Dhanap Prabhu", "email": "dhana.prabhu@continualengine.com" }, { "id": 120, "name": "Vikram Prasanna", "email": "vikram@continualengine.com" }, { "id": 150, "name": "Sayantika Banik", "email": "sayantika.banik@continualengine.com" }, { "id": 121, "name": "Laukik Sarode", "email": "laukik.sarode@continualengine.com" }] },
//     { "id": 3, "name": "CE", "team_members": [{ "id": 15, "name": "AAA", "email": "kalyan.kumar@continualengine.com" }, { "id": 157, "name": "Mousumi Kapoor", "email": "mousumi@continualengine.com" }, { "id": 9, "name": "Ganesan D", "email": "ganesan.d@continualengine.com" }, { "id": 101, "name": "Dhanap Prabhu", "email": "dhana.prabhu@continualengine.com" }, { "id": 120, "name": "Vikram Prasanna", "email": "vikram@continualengine.com" }, { "id": 150, "name": "Sayantika Banik", "email": "sayantika.banik@continualengine.com" }, { "id": 121, "name": "Laukik Sarode", "email": "laukik.sarode@continualengine.com" }] }
//     ]
// }

export const fetchTeamsList = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.teams.listURL}`
  axios.get(
    url,
    {
      params: payload
    })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
