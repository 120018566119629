import React, { useState } from 'react';
import { connect } from 'react-redux';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
//LOCAL IMPORTS
import { generateJwtToken, getActiveStepComponent } from '../../Helpers/basic';
import { getTenant } from '../../Configs/tenantConfig';
import { styleDesigns } from '../../theme/styles';
import { ConfirmButton } from '../../Shared/Buttons/confirmBtn';
import { TextInputField } from '../../Shared/InputFields/textInputField';
import { login } from '../../store/Auth/actionCreator';
import { fetchStepperComponents } from '../../store/UserProfile/actionCreator';
import InlineAlerts from '../../Shared/AlertMessages/inlineAlerts';
import i18n from '../../i18n/i18n';

const useStyles = makeStyles(theme => ({
  linkWrapper: {
    color: `${styleDesigns.fontColour._myAthinaBlue} !important`,
    textDecoration: 'none !important',
    fontFamily: `${styleDesigns.fontFamily._heading} !important`
  },
  form: {
    width: '100%'
  },
  signInWrapper: {
    margin: '0px 0px 8px',
    fontWeight: styleDesigns.fontWeight._heading,
    lineHeight: 1.5,
    fontSize: styleDesigns.fontWeight._subHeading,
    fontFamily: styleDesigns.fontFamily._heading
  },
}))

function Login(props) {
  const classes = useStyles();
  const tenantInfo = getTenant();
  const [showPassword, setShowPassword] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [errStatus, setErrStatus] = useState({
    status: false,
    errMessage: ''
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //FOR CHECKING THE WELCOME PAGE DATA FILLING
  const componentsWelcomeSuccessCB = (res, userData) => {
    const stepperList = res && res.results && res.results.component && res.results.component;
    let stepsArrayList = []
    Object.entries(stepperList).forEach(([key, value]) => {
      stepsArrayList.push(value && value.key)
    });
    const filledData = res && res.results && res.results.user_data && res.results.user_data;
    const defaultStep = getActiveStepComponent(stepsArrayList, filledData);
    if (defaultStep === 'allFilled' || defaultStep === 'finish') {
      if (localStorage.getItem('mailRedirectRoute')) {
        const path = localStorage.getItem('mailRedirectRoute')
        window.location = `/${path}`
        localStorage.removeItem('mailRedirectRoute')
      } else {
        window.location = userData.defaultLandingPage
      }
    } else {
      window.location = '/welcome'
    }
  }
  const componentsWelcomeFailureCB = (err, userData) => {
    window.location = userData.defaultLandingPage
  }

  //FOR LOGIN SUCCESS CALLBACKS
  const successCallBack = res => {
    setErrStatus({
      status: false,
      errMessage: ''
    });
    setInProgress(false);

    const myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 1);

    //FOR SETTING UP THE USER_ACTIVE_MENUS
    if (res && res.active_menus && res.active_menus.length) {
      let menus = JSON.stringify(res.active_menus)
      const parsedLeftMenus = generateJwtToken(menus)
      localStorage.setItem('user_active_menus', parsedLeftMenus);
    } else {
      localStorage.setItem('user_active_menus', [])
    }

    document.cookie = tenantInfo.domainTokenLabel + "=" + res.token + ";path=/;expires=" + myDate + ";domain=" + tenantInfo.domainName;
    const user_token = generateJwtToken(res.user)
    myDate.setMonth(myDate.getMonth() + 1);
    document.cookie = tenantInfo.domainUserLabel + "=" + user_token + ";path=/;expires=" + myDate + ";domain=" + tenantInfo.domainName;

    //FOR ACCESS AND REFRESH TOKENS
    if (tenantInfo.allowAccessRefreshToken) {
      document.cookie = tenantInfo.domainUserAccessToken + "=" + res.access + ";path=/;expires=" + myDate + ";domain=" + tenantInfo.domainName + ";secure";
      document.cookie = tenantInfo.domainUserRefreshToken + "=" + res.refresh + ";path=/;expires=" + myDate + ";domain=" + tenantInfo.domainName + ";secure";
    }

    if (tenantInfo && tenantInfo.show_welcomepage) {
      const payload = {
        user_id: res && res.user && res.user.id
      }
      fetchStepperComponents(payload, componentsWelcomeSuccessCB, componentsWelcomeFailureCB, res.user)
    } else {
      if (localStorage.getItem('mailRedirectRoute')) {
        const path = localStorage.getItem('mailRedirectRoute');
        window.location = `/${path}`
        localStorage.removeItem('mailRedirectRoute')
      } else {
        window.location = res && res.user && res.user.defaultLandingPage
      }
    }
  }
  const failureCallBack = err => {
    let errMsg = ''
    if (err && err.response && err.response.status === 400) {
      errMsg = i18n.t("Invalid credentials!")
    } else {
      errMsg = i18n.t("Something went wrong! Please try again later!")
    }
    setInProgress(false);
    setErrStatus({
      status: true,
      errMessage: errMsg
    })
  }

  const onSignInClick = (event) => {
    event.preventDefault();
    setErrStatus({
      status: false,
      errMessage: ''
    })
    const userName = document.getElementById('userName').value
    const password = document.getElementById('password').value
    if (userName !== '' && password !== '') {
      const payload = {
        username: userName,
        password: password
      }
      setInProgress(true);
      props.login(payload, successCallBack, failureCallBack)
    } else {
      setErrStatus({
        status: true,
        errMessage: i18n.t('Please enter required information!')
      })
    }
  }


  return (
    <React.Fragment>
      <h3 className={classes.signInWrapper}>
        {i18n.t('Sign in')}
      </h3>
      {errStatus.status ? (
        <InlineAlerts status={errStatus.status ? 'error' : 'success'} message={errStatus.errMessage} />
      ) : null}
      <form className={classes.form} noValidate onSubmit={onSignInClick}>
        <TextInputField
          type="text"
          id="userName"
          ariaLabel="Please add your email address"
          label={i18n.t("Username")}
          name="email"
          startIcon="username"
          required={true}
          autoFocus={true}
        />
        <TextInputField
          type={showPassword ? 'text' : 'password'}
          id="password"
          textField="password"
          ariaLabel="Please add your password"
          label={i18n.t("Password")}
          name="password"
          startIcon="password"
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          required={true}
        />
        <Grid container>
          <Grid item xs />
          <Grid item>
            <Link href="/forgotpassword" variant="body2" className={classes.linkWrapper}>
              {i18n.t('Forgot your password?')}
            </Link>
          </Grid>
        </Grid>
        <ConfirmButton
          id="signin"
          ariaLabel="Sign In"
          label={i18n.t("Login")}
          onBtnClick={onSignInClick}
          disabled={inProgress}
        />
      </form>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    authDetails: state.authReducer.authDetails,
  }
}
export default connect(
  mapStateToProps, { login }
)(Login);