import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

export const getRecommendedCourseCount = (successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.search.recommendTracking}`
  axios.get(url)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR CHANGING THE COURSE STATUS
export const changeCourseStatus = (payLoad, successCallBack, failureCallBack) => {
  let url = ''
  if (payLoad.is_externalcourse_request) {
    url = `${apiConfig.course.myCourses.requestToExternalCourse}`
  } else {
    url = `${apiConfig.course.myCourses.mycourses}${payLoad.userCourseId}/`
  }
  axios.put(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR COMPLETING THE COURSES
export const completeCourse = (formData, courseId, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.myCourses.mycourses}${courseId}/`
  axios.put(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const requestToExtendDueDate = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.myCourses.requestToExtendDueDate}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
export const externalCoursesTrack = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userCoursesTrack.externalCourses}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
const mockAssessmentDataTrue = {
  count: 20,
  results: []
}
const mockAssessmentDatafalse = {
  results: [
    {
      "id": 1, //ASSESSMENT ID IN RENDERING
      "title": "Test Assessment-1",
      "desc": "Testing",
      "percent": 90,
      "test_type": "mandatory",
      "ques_count": 4,
      "due_date": "2021-01-10T18:30:00Z",
      "completed_date": "2021-01-09T18:30:00Z",
      "my_score": 90,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 2, //ASSESSMENT ID IN RENDERING
      "title": "Test Assessment-2",
      "desc": "Testing",
      "percent": 80,
      "test_type": "mandatory",
      "ques_count": 2,
      "due_date": "2021-01-10T18:30:00Z",
      "completed_date": "2021-01-09T18:30:00Z",
      "my_score": 80,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 3, //ASSESSMENT ID IN RENDERING
      "title": "Test Assessment-3",
      "desc": "Testing",
      "percent": 50,
      "test_type": "mandatory",
      "ques_count": 4,
      "due_date": "2021-01-10T18:30:00Z",
      "completed_date": "2021-01-09T18:30:00Z",
      "my_score": 30,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 4, //ASSESSMENT ID IN RENDERING
      "title": "Test Assessment-4",
      "desc": "Testing",
      "percent": 80,
      "test_type": "mandatory",
      "ques_count": 4,
      "due_date": "2021-01-10T18:30:00Z",
      "completed_date": "2021-01-09T18:30:00Z",
      "my_score": 60,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 5, //ASSESSMENT ID IN RENDERING
      "title": "Test Assessment-5",
      "desc": "Testing",
      "percent": 90,
      "test_type": "mandatory",
      "ques_count": 2,
      "due_date": "2021-01-10T18:30:00Z",
      "completed_date": "2021-01-09T18:30:00Z",
      "my_score": 90,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 6, //ASSESSMENT ID IN RENDERING
      "title": "Test Assessment-6",
      "desc": "Testing",
      "percent": 90,
      "test_type": "mandatory",
      "ques_count": 4,
      "due_date": "2021-01-10T18:30:00Z",
      "completed_date": "2021-01-09T18:30:00Z",
      "my_score": 0,
      "tenant": "mdb-ab050520"
    }
  ]
}
const mockAssignmentDataTrue = {
  count: 30,
  results: []
}
const mockAssignmentDatafalse = {
  results: [
    {
      "id": 1, //ASSIGNMENT ID IN RENDERING
      "title": "Test assignment-1",
      "desc": `<p>Testing</p>`,
      "points": 100,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "my_score": 100,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 2, //ASSIGNMENT ID IN RENDERING
      "title": "Test assignment-2",
      "desc": `<p>Desc</p>`,
      "points": 100,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "my_score": 90,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 3, //ASSIGNMENT ID IN RENDERING
      "title": "Test assignment-3",
      "desc": `<p>This is desc for assignment 3</p>`,
      "points": 100,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "my_score": 90,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 4, //ASSIGNMENT ID IN RENDERING
      "title": "Test assignment-4",
      "desc": `<p>Desc</p>`,
      "points": 100,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "my_score": 90,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 5, //ASSIGNMENT ID IN RENDERING
      "title": "Test assignment-5",
      "desc": `<p>Desc</p>`,
      "points": 90,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "my_score": 90,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 6, //ASSIGNMENT ID IN RENDERING
      "title": "Test assignment-6",
      "desc": `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">This course introduces the basics of Python 3, including conditional execution and iteration as control structures, and strings and lists as data structures. You'll program an on-screen Turtle to draw pretty pictures.</span></p>`,
      "points": 25,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "my_score": 0,
      "tenant": "mdb-ab050520"
    }
  ]
}
export const fetchExternalAssessments = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userCoursesTrack.fetchExternalAssessments}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      //failureCallBack(error)
      if (payload.count) {
        successCallBack(mockAssessmentDataTrue)
      } else {
        successCallBack(mockAssessmentDatafalse)
      }
    })
}
export const fetchExternalAssignments = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userCoursesTrack.fetchExternalAssignments}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      //failureCallBack(error)
      if (payload.count) {
        successCallBack(mockAssignmentDataTrue)
      } else {
        successCallBack(mockAssignmentDatafalse)
      }
    })
}
export const internalCoursesTrack = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userCoursesTrack.internalCourses}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
export const trainingsTracking = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userCoursesTrack.tranings}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
