import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
//LOCAL IMPORTS
import { styleDesigns } from '../../theme/styles';

const useStyles = makeStyles(theme => ({
  btnWrapper: {
    '&:hover': {
      backgroundColor: `${styleDesigns.cancelButton._hover} !important`,
      boxShadow: 'none !important'
    },
    paddingTop: '10px',
    fontWeight: `${styleDesigns.cancelButton._fontWeight} !important`,
    fontSize: `${styleDesigns.cancelButton._fontSize} !important`,
    textTransform: `${styleDesigns.cancelButton._textTransform} !important`,
    fontFamily: `${styleDesigns.cancelButton._fontFamily} !important`,
    borderRadius: `${styleDesigns.cancelButton._borderRadius} !important`,
    color: `${styleDesigns.cancelButton._color}`,
    height: `${styleDesigns.cancelButton._height} !important`,
    marginTop: `${styleDesigns.cancelButton._marginTop} !important`
  },
  tableView: {
    '&:hover': {
      backgroundColor: `${styleDesigns.cancelButton._hover} !important`,
      boxShadow: 'none !important'
    },
    paddingTop: '10px',
    fontWeight: `${styleDesigns.cancelButton._fontWeight} !important`,
    fontSize: `${styleDesigns.cancelButton._fontSize} !important`,
    textTransform: `${styleDesigns.cancelButton._textTransform} !important`,
    fontFamily: `${styleDesigns.cancelButton._fontFamily} !important`,
    borderRadius: `${styleDesigns.cancelButton._borderRadius} !important`,
    color: `${styleDesigns.cancelButton._color}`,
    height: `35px !important`,
  },
  normalType: {
    '&:hover': {
      backgroundColor: `#cdcbcb !important`,
      boxShadow: 'none !important',
    },
    paddingTop: '10px',
    fontWeight: `${styleDesigns.cancelButton._fontWeight} !important`,
    fontSize: `${styleDesigns.cancelButton._fontSize} !important`,
    textTransform: `${styleDesigns.cancelButton._textTransform} !important`,
    fontFamily: `${styleDesigns.cancelButton._fontFamily} !important`,
    color: styleDesigns.fontColour._appBlue,
    borderRadius: `4px !important`,
    backgroundColor: '#f5f5f5',
    boxShadow: `#cdcbcb !important`,
    height: `35px !important`,
  }
}));

export const CancelButton = (props) => {
  //ID WILL DEFINE THE BUTTON ID FOR UNIQUE IDENTITY
  //ARIALABEL IS FOR ACCESSIBILITY
  //ONCLICK IS FOR ON CLICK EVENT TRIGGER
  //DISABLED IS TO DISABLE THE BUTTON
  //LABLE IS FOR BUTTON NAME OR TITLE OF BUTTON
  const classes = useStyles();
  return (
    <Button
      className={props.from ? clsx(classes[props.from]) : classes.btnWrapper}
      id={props && props.id && props.id}
      aria-label={props && props.ariaLabel && props.ariaLabel}
      fullWidth
      sx={{ mt: 3, mb: 2 }}
      onClick={props.onCancelBtnClick}
      disabled={props.disabled}
    >
      {props && props.label && props.label}
    </Button>
  )
}