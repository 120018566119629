import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

export const showRecommendDialog = (payLoad) => dispatch => {
    dispatch({ type: 'SHOW_RECOMMEND_DIALOG', payload: payLoad })
}

export const closeRecommendDialog = () => dispatch => {
    dispatch({ type: 'CLOSE_RECOMMEND_DIALOG', payload: {} })
}





export const postRecommendedCourseTracking = (successCallBack, failureCallBack) => {
    const url = `${apiConfig.course.search.recommendTracking}`
    axios.post(url)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}

export const mockRecList = {
        "data": {
          "myRecommendations_internal": [
            {
              "id": 17,
              "title": "Java Script",
              "level": 3,
              "category": 4,
              "banner_url": "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fe/cac0f0db0811e5998f01359b89fd0c/1.png?auto=format%2Ccompress&dpr=1",
              "skill_tags": ['java'],
              "published": true,
              "course_duration": "4",
              "provider": 'Continual Engine',
              "desc": "This course introduces the basics of Python 3, including conditional execution and iteration as control structures, and strings and lists as data structures. You'll program an on-screen Turtle to draw pretty pictures. You'll also learn to draw reference diagrams as a way to reason about program executions, which will help to build up your debugging skills.",
              "status": "completed",
              "recommended_to": [
                {
                  "name": "sdsd sdsd",
                  "designation": null
                },
                {
                  "name": "Ganesan D",
                  "designation": null
                }
              ]
            },
          ],
          "recommendedToMe_internal": [
            {
              "id": 19,
              "title": "Test1 internal",
              "level": 3,
              "category": 4,
              "banner_url": "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fe/cac0f0db0811e5998f01359b89fd0c/1.png?auto=format%2Ccompress&dpr=1",
              "skill_tags": ['java',"python","java"],
              "published": true,
              "course_duration": "13",
              "provider": 'Continual Engine',
              "desc": "This course introduces the basics of Python 3, including conditional execution and iteration as control structures, and strings and lists as data structures. You'll program an on-screen Turtle to draw pretty pictures. You'll also learn to draw reference diagrams as a way to reason about program executions, which will help to build up your debugging skills.",
              "status": "completed",
                "recommended_by_users": [
                  {
                    "name": "test user",
                    "designation": "UI"
                  }
                ]
              },
              {
                "id": 18,
                "title": "Test2",
                "level": 3,
                "category": 4,
                "banner_url": "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fe/cac0f0db0811e5998f01359b89fd0c/1.png?auto=format%2Ccompress&dpr=1",
                "skill_tags": ['java'],
                "published": true,
                "course_duration": "2",
                "provider": 'Continual Engine',
                "desc": "This course introduces the basics of Python 3, including conditional execution and iteration as control structures, and strings and lists as data structures. You'll program an on-screen Turtle to draw pretty pictures. You'll also learn to draw reference diagrams as a way to reason about program executions, which will help to build up your debugging skills.",
                "status": "completed",
                  "recommended_by_users": [
                    {
                      "name": "Laukik Sarode",
                      "designation": "UI"
                    }
                  ]
                }
          ],
          "myRecommendations": [
              {
                "desc": `The “What Is Social?" MOOC is for business owners, executives, and marketing professionals who want to significantly improve their abilities to grow their social strategy using effective, proven methodologies.`,
                "duration": "Approx. 9 hours to complete",
                "id": 21219,
                "img": "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/fe/cac0f0db0811e5998f01359b89fd0c/1.png?auto=format%2Ccompress&dpr=1",
                "level": "",
                "name": "What is Social?",
                "price": "₹3,503 per month",
                "provider": "Coursera",
                "ratings": 4.6,
                "recommended_to": [{name: "Ganesan D", designation: "UI Lead"}],
                "reviews": [],
                url: "https://www.coursera.org/learn/what-is-social"
              }
          ],
          "recommendedToMe": [
            {
              "id": 123643,
              "name": "Python Basics",
              "duration": "Approx. 22 hours to complete",
              "img": "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/77/17ca30e9cf11e8931fddf70eb768e1/pythonfluency_1x1_course_1.png?auto=format%2Ccompress&dpr=1",
              "ratings": 4.8,
              "level": "Beginner Level",
              "price": "₹3,503 per month",
              "provider": "Coursera",
              "url": "https://www.coursera.org/learn/python-basics",
              "desc": "This course introduces the basics of Python 3, including conditional execution and iteration as control structures, and strings and lists as data structures.",
              "recommended_by_users": [
                {
                  "name": "admin user",
                  "designation": "UI"
                }
              ]
            },
            {
                "id": 123643,
                "name": "Python Basics",
                "duration": "Approx. 22 hours to complete",
                "img": "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/77/17ca30e9cf11e8931fddf70eb768e1/pythonfluency_1x1_course_1.png?auto=format%2Ccompress&dpr=1",
                "ratings": 4.8,
                "level": "Beginner Level",
                "price": "₹3,503 per month",
                "provider": "Coursera",
                "url": "https://www.coursera.org/learn/python-basics",
                "desc": "This course introduces the basics of Python 3, including conditional execution and iteration as control structures, and strings and lists as data structures.",
                "recommended_by_users": [
                  {
                    "name": "Laukik Sarode",
                    "designation": "UI"
                  }
                ]
              },
              {
                "id": 123643,
                "name": "Python Basics",
                "duration": "Approx. 22 hours to complete",
                "img": "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/77/17ca30e9cf11e8931fddf70eb768e1/pythonfluency_1x1_course_1.png?auto=format%2Ccompress&dpr=1",
                "ratings": 4.8,
                "level": "Beginner Level",
                "price": "₹3,503 per month",
                "provider": "Coursera",
                "url": "https://www.coursera.org/learn/python-basics",
                "desc": "This course introduces the basics of Python 3, including conditional execution and iteration as control structures, and strings and lists as data structures.",
                "recommended_by_users": [
                  {
                    "name": "Laukik Sarode",
                    "designation": "UI"
                  }
                ]
              }
          ],
          "count_myrecommendation": 2,
          "count_tomerecommendation": 4
        }
      }


export const getRecommendedCourseList = (successCallBack, failureCallBack) => {
    const url = `${apiConfig.course.search.recommendedCourseList}`
    axios.get(url)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}

export const recommendedCourseList = [
    {
        "recommendedBy": 'Mousumi Kapoor',
        "title": 'CEO',
        "courses": [
            {
                "id": 65836,
                "name": "Docker Essentials for Python Developers",
                "duration": "8 hours",
                "img": "https://i.udemycdn.com/course/480x270/2563796_fd2f_2.jpg",
                "ratings": 5.0,
                "reviews": "",
                "type": "Beginner Level",
                "price": "₹6,400",
                "url": "https://www.udemy.com/course/docker-essentials-for-python-developers/",
                "course": [
                    ""
                ],
                "provider": "Udemy",
                "status": "completed",
                "review": "ok",
                "rating": 2.5,
                "currency": "₹",
                "amount": 100.0,
                "certificate": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/certificates/20200703095040954827_insta.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=XVnOEjwyPaGG6rPr1jetA%2FyN%2Fh0%3D&Expires=1594017878",
                "receipt": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/receipts/20200703095042738448_insta.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=wYKMu0KduYfSTDBFNhVekgtgFzU%3D&Expires=1594017878",
                "reason": null,
                "last_modified": "2020-07-03T09:50:44.619371Z",
                "rejected_by": "",
                "desc": "Docker &Containers are Foundations of modern DevOps practices. These are must-have skills of Full Stack Developers.Containers have become a standard in Production-grade Deep Learning applications.Every Python Developer must be fluent and comfortable in using Containers at every step of Application lifecycle.You learn Docker and Containers quickly in this Course.It is designed to be very practical and give you Quick Win without spending too much time. I use Minimal Manual teaching approach: each idea, concept or skill has a dedicated Lecture. This way you are going to learn much faster.Here you learn everything important to prove you know Containers:How to build and run Containers with Python AppsContainerize Flask-based Microservices and Django Web AppsUse Docker in Data Science and Machine Learning EnvironmentsCreate complex Development &Test Environments with Docker ComposeYou are going to get practical results in first hour of using this Course!Don't wait any longer, start using Containers now!Course Introduction section is free to watch, as well as first Lectures of each Section. Check them out!"
            },
            {
                "id": 65837,
                "name": "AWS vs Firebase",
                "duration": "8 hours",
                "img": "https://i.udemycdn.com/course/480x270/1701488_a80e.jpg",
                "ratings": 5.0,
                "reviews": "",
                "type": "Beginner Level",
                "price": "₹6,400",
                "url": "https://www.udemy.com/course/docker-essentials-for-python-developers/",
                "course": [
                    ""
                ],
                "provider": "Udemy",
                "status": "completed",
                "review": "ok",
                "rating": 2.5,
                "currency": "₹",
                "amount": 100.0,
                "certificate": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/certificates/20200703095040954827_insta.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=XVnOEjwyPaGG6rPr1jetA%2FyN%2Fh0%3D&Expires=1594017878",
                "receipt": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/receipts/20200703095042738448_insta.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=wYKMu0KduYfSTDBFNhVekgtgFzU%3D&Expires=1594017878",
                "reason": null,
                "last_modified": "2020-07-03T09:50:44.619371Z",
                "rejected_by": "",
                "desc": "Docker &Containers are Foundations of modern DevOps practices. These are must-have skills of Full Stack Developers.Containers have become a standard in Production-grade Deep Learning applications.Every Python Developer must be fluent and comfortable in using Containers at every step of Application lifecycle.You learn Docker and Containers quickly in this Course.It is designed to be very practical and give you Quick Win without spending too much time. I use Minimal Manual teaching approach: each idea, concept or skill has a dedicated Lecture. This way you are going to learn much faster.Here you learn everything important to prove you know Containers:How to build and run Containers with Python AppsContainerize Flask-based Microservices and Django Web AppsUse Docker in Data Science and Machine Learning EnvironmentsCreate complex Development &Test Environments with Docker ComposeYou are going to get practical results in first hour of using this Course!Don't wait any longer, start using Containers now!Course Introduction section is free to watch, as well as first Lectures of each Section. Check them out!"
            }
        ]
    },
    {
        "recommendedBy": 'Laukik Sarode',
        "title": 'Product Owner',
        "courses": [
            {
                "id": 22844,
                "name": "Python Data Visualization",
                "duration": "Approx. 8 hours to complete",
                "img": "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/d9/85d5c09d7911e7bdab454d6583c382/Python-Developer.jpg?auto=format%2Ccompress&dpr=1",
                "ratings": 4.8,
                "reviews": "",
                "type": "Beginner Level",
                "price": "₹3,503 per month",
                "url": "https://www.coursera.org/learn/python-visualization",
                "course": [
                    "Rice University"
                ],
                "provider": "Coursera",
                "status": "completed",
                "review": "Good",
                "rating": 4.0,
                "currency": "₹",
                "amount": 2000.0,
                "certificate": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/certificates/20200512054908943934_skillsdev_infographic.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=dRR5rg7tTRkj5tdVULzYBL4vUnw%3D&Expires=1594017878",
                "receipt": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/receipts/20200512054911343893_skillsdev_infographic.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=rn8Q%2BKVIRzfwVsAgFS9F624oeC8%3D&Expires=1594017878",
                "reason": null,
                "last_modified": "2020-05-12T05:49:13.681500Z",
                "rejected_by": "",
                "desc": "This if the final course in the specialization which builds upon the knowledge learned in Python Programming Essentials, Python Data Representations, and Python Data Analysis."
            },
            {
                "id": 22845,
                "name": "React and Node JS",
                "duration": "Approx. 8 hours to complete",
                "img": "https://i.udemycdn.com/course/480x270/2334586_703f_2.jpg",
                "ratings": 4.8,
                "reviews": "",
                "type": "Beginner Level",
                "price": "₹3,503 per month",
                "url": "https://www.coursera.org/learn/python-visualization",
                "course": [
                    "Rice University"
                ],
                "provider": "Coursera",
                "status": "completed",
                "review": "Good",
                "rating": 4.0,
                "currency": "₹",
                "amount": 2000.0,
                "certificate": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/certificates/20200512054908943934_skillsdev_infographic.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=dRR5rg7tTRkj5tdVULzYBL4vUnw%3D&Expires=1594017878",
                "receipt": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/receipts/20200512054911343893_skillsdev_infographic.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=rn8Q%2BKVIRzfwVsAgFS9F624oeC8%3D&Expires=1594017878",
                "reason": null,
                "last_modified": "2020-05-12T05:49:13.681500Z",
                "rejected_by": "",
                "desc": "This if the final course in the specialization which builds upon the knowledge learned in Python Programming Essentials, Python Data Representations, and Python Data Analysis."
            }
        ]
    },
    {
        "recommendedBy": 'Ganesan',
        "title": 'Team Lead',
        "courses": [
            {
                "id": 65836,
                "name": "Docker Essentials for Python Developers",
                "duration": "8 hours",
                "img": "https://i.udemycdn.com/course/480x270/2563796_fd2f_2.jpg",
                "ratings": 5.0,
                "reviews": "",
                "type": "Beginner Level",
                "price": "₹6,400",
                "url": "https://www.udemy.com/course/docker-essentials-for-python-developers/",
                "course": [
                    ""
                ],
                "provider": "Udemy",
                "status": "completed",
                "review": "ok",
                "rating": 2.5,
                "currency": "₹",
                "amount": 100.0,
                "certificate": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/certificates/20200703095040954827_insta.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=XVnOEjwyPaGG6rPr1jetA%2FyN%2Fh0%3D&Expires=1594017878",
                "receipt": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/receipts/20200703095042738448_insta.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=wYKMu0KduYfSTDBFNhVekgtgFzU%3D&Expires=1594017878",
                "reason": null,
                "last_modified": "2020-07-03T09:50:44.619371Z",
                "rejected_by": "",
                "desc": "Docker &Containers are Foundations of modern DevOps practices. These are must-have skills of Full Stack Developers.Containers have become a standard in Production-grade Deep Learning applications.Every Python Developer must be fluent and comfortable in using Containers at every step of Application lifecycle.You learn Docker and Containers quickly in this Course.It is designed to be very practical and give you Quick Win without spending too much time. I use Minimal Manual teaching approach: each idea, concept or skill has a dedicated Lecture. This way you are going to learn much faster.Here you learn everything important to prove you know Containers:How to build and run Containers with Python AppsContainerize Flask-based Microservices and Django Web AppsUse Docker in Data Science and Machine Learning EnvironmentsCreate complex Development &Test Environments with Docker ComposeYou are going to get practical results in first hour of using this Course!Don't wait any longer, start using Containers now!Course Introduction section is free to watch, as well as first Lectures of each Section. Check them out!"
            },
            {
                "id": 65837,
                "name": "AWS vs Firebase",
                "duration": "8 hours",
                "img": "https://i.udemycdn.com/course/480x270/1701488_a80e.jpg",
                "ratings": 5.0,
                "reviews": "",
                "type": "Beginner Level",
                "price": "₹6,400",
                "url": "https://www.udemy.com/course/docker-essentials-for-python-developers/",
                "course": [
                    ""
                ],
                "provider": "Udemy",
                "status": "completed",
                "review": "ok",
                "rating": 2.5,
                "currency": "₹",
                "amount": 100.0,
                "certificate": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/certificates/20200703095040954827_insta.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=XVnOEjwyPaGG6rPr1jetA%2FyN%2Fh0%3D&Expires=1594017878",
                "receipt": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/receipts/20200703095042738448_insta.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=wYKMu0KduYfSTDBFNhVekgtgFzU%3D&Expires=1594017878",
                "reason": null,
                "last_modified": "2020-07-03T09:50:44.619371Z",
                "rejected_by": "",
                "desc": "Docker &Containers are Foundations of modern DevOps practices. These are must-have skills of Full Stack Developers.Containers have become a standard in Production-grade Deep Learning applications.Every Python Developer must be fluent and comfortable in using Containers at every step of Application lifecycle.You learn Docker and Containers quickly in this Course.It is designed to be very practical and give you Quick Win without spending too much time. I use Minimal Manual teaching approach: each idea, concept or skill has a dedicated Lecture. This way you are going to learn much faster.Here you learn everything important to prove you know Containers:How to build and run Containers with Python AppsContainerize Flask-based Microservices and Django Web AppsUse Docker in Data Science and Machine Learning EnvironmentsCreate complex Development &Test Environments with Docker ComposeYou are going to get practical results in first hour of using this Course!Don't wait any longer, start using Containers now!Course Introduction section is free to watch, as well as first Lectures of each Section. Check them out!"
            }
        ]
    }
]


export const getRecommendedLevelList = (successCallBack, failureCallBack) => {
    successCallBack(recommendedCourseList)
    return
    /*
    const url = `${apiConfig.course.search.recommendList}`
    axios.get(url)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            //failureCallBack(error)
            successCallBack(recommendedCourseList)
        })
        */
}
