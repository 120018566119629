import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
//LOCAL IMPORTS
import { styleDesigns } from '../../../theme/styles';

function CircularLoader(props) {
  //WILL TAKE COLOR AND SIZE FROM PROPS
  return (
    <CircularProgress size={props.size} style={{ color: props.color ? props.color : styleDesigns.fontColour._myAthinaBlue, marginRight: props.marginRight ? props.marginRight : '', marginBottom: props.marginBottom ? props.marginBottom : '' }} />
  )
}

export default CircularLoader;