import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
//import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import StarRatingComponent from 'react-star-rating-component';
import Star from '@mui/icons-material/Star'
import StarHalf from '@mui/icons-material/StarHalf'
import Fab from '@mui/material/Fab';
import { reject } from 'lodash';
import AthenaImage from '../../assests/myAthinaCourse.png'
import CustomTags from './customTags';

const useStyles = makeStyles({
  courseImg: {
    maxWidth: 185,
    minWidth: '20%',
    height: 100,
    objectFit: 'cover',
    objectPosition: 'center center',
    backgroundColor: '#ccc'
  },
  providerImg: {
    maxWidth: 100,
  },
  courseLink: {
    textDecoration: 'none',
    color: 'blue',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  dataRow: {
    '&:nth-of-type(odd)': {
      // backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  },
  removeIcon: {
    backgroundColor: '#fff',
    color: '#ccc',
    boxShadow: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: '#ccc',
      color: '#fff',
    }
  },
  clearAll: {
    display: 'inline-block',
    float: 'right',
    marginRight: '51px',
    textTransform: 'none',
    color: 'blue',
    '&:hover': {
      backgroundColor: '#fff',
      textDecoration: 'underline'
    }
  }
});
const styles = () => ({
  root: {
    margin: 0,
    padding: 16,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#9e9e9e',
  }
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 16,
  },
}))(MuiDialogContent);

export default function CourseComparator(props) {
  const classes = useStyles();
  const getCourseToCompare = () => {
    let courseToCompare = JSON.parse(localStorage.getItem('courseToCompare'))
    return courseToCompare ? courseToCompare : []
  }
  const [courseListToCompare, setCourseToCompare] = React.useState([]);
  const [cellWidth, setCellWidth] = React.useState('20%')

  const closeDrawer = () => {
    props.onCloseCourseComparator()
  }

  const getCellWidth = () => {
    const courseToCompare = getCourseToCompare()
    let cellWidth = 100 / (courseToCompare.length + 1)
    return cellWidth + '%'
  }
  useEffect(() => {
    setCourseToCompare(getCourseToCompare())
    setCellWidth(getCellWidth())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCourseImg = course => {
    return (
      <img src={course.img} onError={(evnt) => {
        evnt.target.src = AthenaImage
      }}
        alt={course.name}
        className={classes.courseImg}
      />
    )
  }
  const comparisonAttributes = [
    {
      key: 'provider',
      label: 'Provider'
    },
    {
      key: 'type',
      label: 'Level'
    },
    {
      key: 'duration',
      label: 'Duration'
    },
    {
      key: 'price',
      label: 'Price'
    },
    {
      key: 'ratings',
      label: 'Ratings'
    },
    {
      key: 'tags',
      label: 'Skills'
    },
  ]
  const getContent = (key, course) => {
    switch (key) {
      case 'provider':
        return (
          <React.Fragment>
            <span>{course.provider}</span>
          </React.Fragment>
        )
      case 'ratings':
        return (
          <div style={{ position: 'relative' }}>
            <StarRatingComponent
              name="rate2"
              editing={false}
              renderStarIcon={() => <span><Star /></span>}
              renderStarIconHalf={() => <span><StarHalf /></span>}
              starCount={5}
              value={course.ratings ? course.ratings : 0}
              starColor="#000"
              emptyStarColor="#ccc"
              className={classes.ratingStar}
            />
            <span style={{ position: 'absolute', top: 4, marginLeft: 10 }}>{course.ratings ? course.ratings : 0}</span>
          </div>
        )
      case 'tags':
        return (
          <React.Fragment>
            {course.tags && course.tags.length ? (
              <div>
                <CustomTags chipData={course.tags} justifyContent="center" />
              </div>
            ) : '-'}
          </React.Fragment>
        )
      default:
        return (
          <span>
            {course[key] ? course[key] : '-'}
          </span>
        )
    }
  }
  const removeFromCompare = courseId => {
    let courseToCompare = JSON.parse(localStorage.getItem('courseToCompare'))
    courseToCompare = reject(courseToCompare, { 'id': courseId })
    localStorage.setItem('courseToCompare', JSON.stringify(courseToCompare))
    setCourseToCompare(courseToCompare)
    setCellWidth(getCellWidth())
    if (courseToCompare.length > 0) {
      const updateText = courseToCompare.length === 1 ? `${courseToCompare.length} course to compare` : `${courseToCompare.length} courses to compare`
      document.getElementById('courseCompareNotice').textContent = updateText
      document.getElementById('courseCompareNotice').style.display = 'block'
    } else {
      document.getElementById('courseCompareNotice').textContent = `No courses to compare`
      document.getElementById('courseCompareNotice').style.display = 'none'
      closeDrawer()
    }
  }
  const clearAll = () => {
    localStorage.setItem('courseToCompare', null)
    closeDrawer()
  }
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={props.open}
      onClose={closeDrawer}
      style={{ marginTop: '5rem' }}
    >
      <DialogTitle id="customized-dialog-title" onClose={closeDrawer}>
        Compare courses
        <Button className={classes.clearAll} onClick={clearAll}>clear all</Button>
      </DialogTitle>
      <DialogContent dividers style={{ padding: 0 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                key={0}
                align="justify"
                style={{ width: cellWidth }}
              ></TableCell>
              {courseListToCompare.map((course, key) => (
                <TableCell
                  key={key}
                  align="center"
                  style={{ width: cellWidth }}
                >
                  <Paper variant="outlined" style={{ padding: 10, margin: 'auto', position: 'relative', width: 250, height: 150 }}>
                    <a href={course.url} target="_blank" rel="noopener noreferrer" className={classes.courseLink}>
                      {getCourseImg(course)}
                    </a>
                    <div>
                      <a href={course.url} target="_blank" rel="noopener noreferrer" className={classes.courseLink}>
                        {course.name}
                      </a>
                    </div>
                    <Fab size="small" color="secondary" aria-label="add" className={classes.removeIcon} onClick={() => removeFromCompare(course.id)}>
                      <CloseIcon />
                    </Fab>
                  </Paper>
                </TableCell>
              ))}

            </TableRow>
          </TableHead>
          <TableBody>
            {comparisonAttributes.map((label, labelKey) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={labelKey} className={classes.dataRow}>
                <TableCell key={0} align="center">
                  {label.label}
                </TableCell>
                {courseListToCompare.map((course, key) => (
                  <TableCell
                    key={key}
                    align="center"
                  >
                    {getContent(label.key, course)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}
