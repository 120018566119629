import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Grid from '@mui/material/Grid';
//LOCAL IMPORTS


export default function GridCardLoader(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} xm={12} md={12} lg={12}>
        <Skeleton width="100%" height={props.height} borderRadius={props.borderRadius} />
      </Grid>
    </Grid>
  )
}