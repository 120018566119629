import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

export const searchCourses = (payLoad, successCallBack, failureCallBack, initSearch = false) => {
  const encodedParams = encodeURIComponent(payLoad.searchTerm)
  const url = `${apiConfig.course.search.searchUrl}`
  //const url = `${apiConfig.course.search.searchUrls}`

  let paramObj = {
    searchKeyword: encodedParams,
    number: payLoad.number,
    offset: payLoad.offset,
    price_low_to_high: payLoad.price_low_to_high,
    price_high_to_low: payLoad.price_high_to_low
  }
  if (payLoad.free) {
    paramObj.free = payLoad.free
  }

  if (payLoad.providers && payLoad.providers.length) {
    paramObj.providers = payLoad.providers
  }
  if (payLoad.price_range && payLoad.price_range.length > 0) {
    paramObj.price_range_min = payLoad.price_range[0];
    paramObj.price_range_max = payLoad.price_range[1];
  }
  if (payLoad.levels && payLoad.levels.length) {
    paramObj.levels = payLoad.levels
  }
  if (payLoad.duration && payLoad.duration.length) {
    paramObj.duration = payLoad.duration
  }
  axios.get(url, {
    params: paramObj
  })
    .then(function (response) {
      successCallBack(response.data, initSearch, payLoad.free)
    })
    .catch(function (error) {
      failureCallBack(error, initSearch)
    })
}

//FOR ARTICLES FETCH
export const fetchArticles = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.search.fetchArticles}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR WEBPAGES FETCH DATA
export const fetchWebPages = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.search.fetchWebPages}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETHCING YOUTUBE VIDEOS
export const fetchVideosData = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.search.fetchVideosData}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR LIKE ARTILCE
export const articlesLikeUnlike = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.search.articleLikeUnlike}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR RECOMMEND
export const articlesRecommend = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.search.articlesRecommend}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING ARTICLES COMMENTS
export const articlesFetchComments = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.search.articlesFetchComments}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR POSTING COMMENT
export const articlesPostComment = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.search.articlesPostCommnet}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING SINGLE USER COMMENT AND RATING
export const fetchAddedUserComment = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.search.fetchAddedUserComment}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchRecommended = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.search.searchRecommendedUrl}`
  axios.get(url, {
    params: {}
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}