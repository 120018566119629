export const mockSkills = [
    {
        id: 1,
        value: 'WordPress',
        title: 'WordPress'
    },
    {
        id: 2,
        value: 'C#',
        title: 'C#'
    },
    {
        id: 3,
        value: 'Android Development',
        title: 'Android Development'
    },
    {
        id: 4,
        value: 'Web Development',
        title: 'Web Development'
    },
    {
        id: 5,
        value: 'Machine Learning',
        title: 'Machine Learning'
    },
    {
        id: 6,
        value: 'Swift',
        title: 'Swift'
    },
    {
        id: 7,
        value: 'HTML',
        title: 'HTML'
    },
    {
        id: 8,
        value: 'CSS',
        title: 'CSS'
    },
    {
        id: 9,
        value: 'ReactJs',
        title: 'ReactJs'
    },
    {
        id: 10,
        value: 'Angular',
        title: 'Angular'
    },
    {
        id: 11,
        value: 'SQL',
        title: 'SQL'
    },
    {
        id: 12,
        value: 'Node.Js',
        title: 'Node.Js'
    },
    {
        id: 13,
        value: 'Bootstrap',
        title: 'Bootstrap'
    },
    {
        id: 14,
        value: 'Data Science',
        title: 'Data Science'
    },
    {
        id: 15,
        value: 'R',
        title: 'R'
    },
    {
        id: 16,
        value: 'HTML5',
        title: 'HTML5'
    },
    {
        id: 17,
        value: 'Game Development Fundamentals',
        title: 'Game Development Fundamentals'
    },
    {
        id: 18,
        value: 'Laravel',
        title: 'Laravel'
    },
    {
        id: 19,
        value: 'MySQL',
        title: 'MySQL'
    },
    {
        id: 20,
        value: 'Product Management',
        title: 'Product Management'
    },
    {
        id: 21,
        value: 'Project Management',
        title: 'Project Management'
    },
    {
        id: 22,
        value: 'Program Management',
        title: 'Program Management'
    },
    {
        id: 23,
        value: 'Business Analyst',
        title: 'Business Analyst'
    },
    {
        id: 24,
        value: 'Data Analyst',
        title: 'Data Analyst'
    },
    {
        id: 25,
        value: 'Data Scientist',
        title: 'Data Scientist'
    },
    {
        id: 26,
        value: 'Automation Testing',
        title: 'Automation Testing'
    },
    {
        id: 27,
        value: 'Manual Testing',
        title: 'Manual Testing'
    },
    {
        id: 28,
        value: 'Agile Methodologies',
        title: 'Agile Methodologies'
    },
    {
        id: 29,
        value: 'Scrum',
        title: 'Scrum'
    },
    {
        id: 30,
        value: 'Kanban',
        title: 'Kanban'
    },
    {
        id: 31,
        value: 'Python',
        title: 'Python'
    },
    {
        id: 32,
        value: 'Java',
        title: 'Java'
    },
    {
        id: 33,
        value: 'JavaScript',
        title: 'JavaScript'
    },
]

export const mockInterests = [
    {
        id: 1,
        value: 'Web Development',
        title: 'Web Development'
    },
    {
        id: 2,
        value: 'Programming Languages',
        title: 'Programming Languages'
    },
    {
        id: 3,
        value: 'Mobile Apps',
        title: 'Mobile Apps'
    },
    {
        id: 4,
        value: 'Game Development',
        title: 'Game Development'
    },
    {
        id: 5,
        value: 'Data Science',
        title: 'Data Science'
    },
    {
        id: 6,
        value: 'Databases',
        title: 'Databases'
    },
    {
        id: 7,
        value: 'Development Tools',
        title: 'Development Tools'
    },
    {
        id: 8,
        value: 'Software Engineering',
        title: 'Software Engineering'
    },
    {
        id: 9,
        value: 'Software Testing',
        title: 'Software Testing'
    },
    {
        id: 10,
        value: 'E-Commerce',
        title: 'E-Commerce'
    }
]
//for skill summary data
export const mockSkillSummary = {
    "results": {
        "Beginner": [
            { 'id': 1, 'skill': 'Python' },
            { 'id': 2, 'skill': 'Java' },
            { 'id': 1, 'skill': 'Django' },
            { 'id': 2, 'skill': 'ReactJs' },
            { 'id': 1, 'skill': 'Node.js' },
            { 'id': 2, 'skill': 'AngularJs' },
            { 'id': 1, 'skill': 'ExpressJs' },
            { 'id': 2, 'skill': 'VueJs' },
            { 'id': 1, 'skill': 'NextJs' },
            { 'id': 2, 'skill': 'RequireJs' },
            { 'id': 1, 'skill': 'TypeScript' },
            { 'id': 1, 'skill': 'Business Negotiation Skills' },
            { 'id': 2, 'skill': 'Sales Planning' },
            { 'id': 2, 'skill': 'OpenCV' },
            { 'id': 2, 'skill': 'Flask' },
            { 'id': 2, 'skill': 'Public Relations' }
        ],
        "Intermediate": [
            { 'id': 1, 'skill': 'jQuery' },
            { 'id': 2, 'skill': 'Deep learning' },
            { 'id': 1, 'skill': 'Machine Learning' },
            { 'id': 2, 'skill': 'PostGreSQL' },
            { 'id': 1, 'skill': 'WordPress' },
            { 'id': 2, 'skill': 'C' },
            { 'id': 1, 'skill': 'C#' },
            { 'id': 2, 'skill': 'C++' },
        ],
        "Expert": [
            { 'id': 1, 'skill': 'Android Development' },
            { 'id': 2, 'skill': 'Web Development' },
            { 'id': 1, 'skill': 'Swift' },
            { 'id': 2, 'skill': 'HTML' },
            { 'id': 1, 'skill': 'CSS' },
            { 'id': 2, 'skill': 'SCSS' },
            { 'id': 1, 'skill': 'SQL' },
            { 'id': 2, 'skill': 'Bootstrap' },
        ]
    }
}

export const mockSkillSummaryCourses = {
    "results": [
        {
            id: 1295,
            course_id: 22150,
            desc: "In this course, we will be learning :PYTHON FUNDAMENTALS where we Gain the knowledge of core concepts of Python including operators, variables and Data Types and learn to store, access and manipulate data in lists.FUNCTIONS AND PACKAGES where we Gain a knowledge of the basics of functions, methods and Packages.NumPy where we Make a step towards Data Science and go deep into Python by learning the basics of the NumPy array.Happy learning !",
            duration: "Approx. 36 hours to complete",
            is_internal: false,
            price: "₹4,326",
            provider: {
                domain: "http://Udemy.com",
                logo: "https://wsre.api.moocpedia.com/media/files/providers/udemy.jpg",
                name: "Udemy"
            },
            status: "ongoing",
            thumbnail: "https://i.udemycdn.com/course/480x270/2047737_a489.jpg",
            title: "Python for Data Science",
            url: "https://www.udemy.com/course/python4ds/",
            started_on: '2022-04-02T11:06:05.435080Z',
            completed_on: null
        },
        {
            "id": 1303,
            "course_id": 35000,
            "title": "Projects in Django and Python",
            "url": "https://www.udemy.com/course/projects-in-django-and-python/",
            "thumbnail": "https://i.udemycdn.com/course/480x270/270808_c9bd_5.jpg",
            "price": "₹4,800",
            "duration": "2 hours",
            "is_internal": false,
            "desc": "Python is arguably the easiest language to start real programming and Django is the most popular web framework for Python. Together they form a deadly combination which helps you build smart web applications quickly and elegantly. Its ease of use and quick development turnaround has made these technologies a darling of Silicon Valley Entrepreneurs.      Our content team has weaved together a course which will not only help you master Python  and Django concepts but will add real world practical expertise to your knowledge. You will learn the mechanism and tricks to get your ideas to life while working on diverse projects such as E commerce sites , Message Boards, Image Gallery Site, Survey Site and Blogging App.       Our course is one of its kind and its completely unique offering is aimed towards working professionals and beginners alike. So get started and have fun learning these amazing technologies.",
            "status": "ongoing",
            "provider": {
                domain: "http://coursera.com",
                logo: "https://wsre.api.moocpedia.com/media/files/providers/coursera.jpg",
                name: "Coursera"
            },
            started_on: '2022-04-02T11:06:05.435080Z',
            completed_on: null
        },
        {
            id: 1295,
            course_id: 22150,
            desc: "This course will teach you python as you would learn in a computer science course at a university. Not only will you learn syntax but also relevant algorithms and problem solving skills to help you think like a programmer. What sets this course apart from others is that it is well rounded and designed to help you progress as a programmer while teaching you a new language. You will not be force-fed lectures that are only about memorizing syntax. Python is a powerful language that is used for a variety of applications. Whether you want to just learn your first programming language, or develop skills that could lead to a future in software development, this is the course for you.",
            duration: "Approx. 36 hours to complete",
            is_internal: false,
            price: "₹4,326",
            provider: {
                domain: "http://coursera.com",
                logo: "https://wsre.api.moocpedia.com/media/files/providers/coursera.jpg",
                name: "Coursera"
            },
            status: "completed",
            thumbnail: "https://i.udemycdn.com/course/480x270/1800830_8d5c_2.jpg",
            title: "Python for Everyone",
            url: "https://www.udemy.com/course/pythonforeveryone/",
            started_on: '2022-04-02T11:06:05.435080Z',
            completed_on: '2022-05-02T11:06:05.435080Z'
        }
    ]
}