
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { groupBy } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Link from '@mui/material/Link';
import { ReactSVG } from 'react-svg';
import Divider from '@mui/material/Divider';
//LOCAL IMPORTS
import { getUserActiveMenus, getUserDetails, hasSearchAccess, redirectTo } from '../../Helpers/basic';
import { getTenant } from '../../Configs/tenantConfig';
import { styleDesigns } from '../../theme/styles';
import { getRecommendedCourseCount } from '../../store/MyCourses/actionCreator';
import { logActivity } from '../../store/ActivityLog/actionCreator';
import { googleTrackView } from '../../Helpers/googleAnalytics';
import { fetchProfilePic } from '../../store/Profile/actionCreator';
import myAthinaDeskLogo from '../../assests/myAthina_Desktop.svg';
import Footer from './footer';
import i18n from '../../i18n/i18n';
import { useLeftMenuContext } from '../../Shared/Context/leftMenuContext';
import AdminModeSwitch from './adminModeSwitch';
import NavController from './navController';
import AvatarIcon from '../../assests/Icons/Profile/avatar.svg';
import PrepTrainingLogo from './prepTraining.svg';

const drawerWidth = 240;
const openedMixin = (theme) => ({
  overflow: 'hidden',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  overflow: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const useStyles = makeStyles(theme => ({
  badge: {
    marginTop: '10px !important',
    marginLeft: '11px !important'
  },
  userName: {
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    fontSize: `${styleDesigns.fontSize._subHeading} !important`,
    textAlign: 'center !important',
    marginBottom: '4px !important',
    fontWeight: '600 !important'
  },
  imgdivWrapper: {
    display: 'flex !important',
    alignItems: 'center !important',
    padding: '13px 12px !important',
  },
  imgdivOpenWrapper: {
    display: 'flex !important',
    alignItems: 'center !important',
    padding: '13px 15px !important',
  },
  imgWrapperClose: {
    width: '47px !important',
    height: '47px !important',
    objectFit: 'cover !important',
    color: 'transparent !important',
    marginTop: '51px !important'
  },
  imgWrapperOpen: {
    display: 'block !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    width: '40% !important',
    objectFit: 'cover !important',
    color: 'transparent !important',
    border: `3px solid ${styleDesigns.fontColour._myAthinaGreen} !important`,
    borderRadius: '50px !important'
  },
  imgIconOpen: {
    display: 'block !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    width: '91px !important',
    height: '91px !important',
    objectFit: 'cover !important'
  },
  imgIconClose: {
    width: '46px !important',
    height: '46px !important',
    marginTop: '51px !important'
  },
  athinaLogo: {
    width: '164px !important',
    height: '90px !important',
    paddingTop: '10px !important',
    marginLeft: '48px !important'
  },
  prepTlogo: {
    width: '136px !important',
    height: '109px !important',
    paddingTop: '22px !important',
    marginLeft: '43px !important',
    marginTop: '-19px'
  },
  menuIconsWrapper: {
    height: '26px !important',
    width: '23px !important',
    marginLeft: '8px !important'
  },
  selectedMenuWrapper: {
    height: '54px !important',
    minWidth: '49px !important',
    color: `${styleDesigns.fontColour._white} !important`,
    backgroundColor: `${styleDesigns.fontColour._myAthinaGreen} !important`,
    '&:hover': {
      backgroundColor: `${styleDesigns.fontColour._myAthinaGreen} !important`,
    },
    cursor: 'pointer !important'
  },
  menuWrapper: {
    height: '54px !important',
    cursor: 'pointer !important',
    backgroundColor: '',
    '&:hover': {
      backgroundColor: `${styleDesigns.fontColour._lightGrey} !important`,
    },
  },
  menuHeadWrapper: {
    fontWeight: `${styleDesigns.fontWeight._heading} !important`,
    fontSize: '16px !important',
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    marginLeft: '18px !important',
    letterSpacing: '1.1px !important',
    marginTop: '21px !important'
  },
  selectedIconSvg: {
    marginLeft: '6px !important',
    height: '26px !important',
    width: '26px !important',
    filter: 'brightness(2) contrast(200%) invert(1) !important'
  },
  iconSvg: {
    marginLeft: '6px !important',
    height: '26px !important',
    width: '26px !important',
  },
  avatarSkeleton: {
    height: '91px !important',
    width: '95px !important',
    lineHeight: 1,
    marginLeft: '55px !important'
  },
  avatarSkeletonClose: {
    height: '47px !important',
    width: '49px !important',
    lineHeight: 1,
    marginTop: '51px !important'
  },
  prfMenuWrapper: {
    textAlign: 'center !important',
    marginBottom: '19px !important'
  },
  linkWrapper: {
    fontSize: '14px !important',
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    color: `${styleDesigns.fontColour._myAthinaBlue} !important`,
    marginRight: '15px !important',
    textDecoration: 'none !important'
  },
  listWrap: {
    margin: '0px !important'
  },
  adminSwitchWrapper: {
    textAlign: 'center !important'
  },
  menuScroller: {
    minHeight: '400px !important',
    overflow: 'hidden !important',
    '&:hover': {
      overflowY: 'scroll !important',
    },
  },
  dividerWrap: {
    borderColor: '#858585',
    borderWidth: '1px 0px thin',
    marginTop: '21px',
  },
}));

let cancelTokenSource = axios.CancelToken.source();

function LeftMenu(props) {
  const menuContext = useLeftMenuContext();
  const classes = useStyles();
  const navigate = useNavigate();
  const userSearchAccess = hasSearchAccess();
  const domainInfo = getTenant();
  const userDeatails = getUserDetails();
  const userActiveMenus = getUserActiveMenus();
  const [recommendedCount, setRecommendedCount] = useState(0);
  const [avatarLoader, setAvatarLoader] = useState(true);

  const enableAdminModeSwitch = false;


  const successCB = (res) => {
    setRecommendedCount(res.data.count)
  }
  const failureCB = (err) => {
    setRecommendedCount(0)
  }

  useEffect(() => {
    getRecommendedCourseCount(successCB, failureCB)
    setAvatarLoader(true);
    props.fetchProfilePic(
      {},
      () => { setAvatarLoader(false) },
      () => { setAvatarLoader(false) }
    )

    const pathName = props && props.location.pathname
    menuContext.setSelectedMenu(pathName && pathName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //for doing the sorting of menus
  userActiveMenus && userActiveMenus.length && userActiveMenus.sort(function (a, b) {
    return b.category.length - a.category.length;
  })

  //FOR DOING THE SEPARATION BASED ON CATEGORY
  const activeMenusList = groupBy(userActiveMenus, menu => menu.category)

  const onMenuClick = menu => {
    const menuName = menu.name;
    const showHosting = false;
    if (showHosting && menuName === "Author Courses") {
      const url = domainInfo.hostingURL
      redirectTo(url, true)
    } else {
      const menuTrackingName = menuName.toLowerCase().replace(' ', '-')
      logActivity({
        action: menu ? menuName : 'left-menu-click',
        url: window.location.href,
        object_name: '',
        element_id: menu.id,
        element_name: menuTrackingName,
        element_class: "sidebar-item-click"
      },
        () => { },
        () => { }
      )
      googleTrackView({
        pageTitle: menuTrackingName
      })
      //------- Cancel Request Handling -------
      cancelTokenSource.cancel('User navigated to different page')
      axios.interceptors.request.use(
        config => {
          config.cancelToken = cancelTokenSource.token
          return config;
        },
        error => Promise.reject(error)
      );
      axios.interceptors.response.use(function (response) {
        // throw new axios.Cancel('Operation canceled by the user.');
        return response;
      }, function (error) {
        if (axios.isCancel(error)) {
          // here you check if this is a cancelled request to drop it silently (without error)
          return new Promise(() => { });
        }
        return Promise.reject(error);
      });
      window.FreshworksWidget('hide');
      navigate(menu.path);
      menuContext.setSelectedMenu(menu.path);
      cancelTokenSource = axios.CancelToken.source();
    }
  }

  const onProfileMenuClick = (type) => {
    if (type === 'home') {
      navigate('/landingpage');
      logActivity({
        action: 'Landing Page',
        url: window.location.href,
        object_name: '',
        element_id: '',
        element_name: 'Landing Page',
        element_class: "sidebar-item-click"
      },
        () => { },
        () => { }
      )
      googleTrackView({
        pageTitle: 'Landing Page'
      })
    } else if (type === 'profile') {
      navigate('/profile');
      logActivity({
        action: 'Profile',
        url: window.location.href,
        object_name: '',
        element_id: '',
        element_name: 'Profile',
        element_class: "sidebar-item-click"
      },
        () => { },
        () => { }
      )
      googleTrackView({
        pageTitle: 'Profile'
      })
    } else {
      const cookieDetails = domainInfo;
      document.cookie = cookieDetails.domainTokenLabel + '=; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' + cookieDetails.domainName;
      document.cookie = cookieDetails.domainUserLabel + '=; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' + cookieDetails.domainName;
      //FOR ACCESS AND REFRESH TOKENS
      document.cookie = cookieDetails.domainUserAccessToken + '=; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' + cookieDetails.domainName;
      document.cookie = cookieDetails.domainUserRefreshToken + '=; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' + cookieDetails.domainName;
      //
      localStorage.removeItem(window.location.origin, "");
      localStorage.removeItem('user_active_menus', []);
      window.location = '/';
    }
  }

  const showRecCnt = false;
  const getMenusList = (data) => {
    return (
      <List className={classes.listWrap} id="leftMenu" aria-label="left menu navigation panel">
        {data && data[1] && data[1].length && data[1].map((data, index) => (
          <div key={index} >
            {data.show ?
              <ListItem button onClick={() => { onMenuClick(data) }} className={menuContext.selectedMenu === data.path ? classes.selectedMenuWrapper : classes.menuWrapper}>
                <ListItemIcon style={{ minWidth: '45px !important' }}>
                  <ReactSVG
                    aria-label={data && data.name}
                    src={data && (data.menu_icon_url_v2 ? data.menu_icon_url_v2 : data.menu_icon_url)}
                    className={menuContext.selectedMenu === data.path ? classes.selectedIconSvg : classes.iconSvg}
                  />
                </ListItemIcon>
                {props.open ? (
                  <React.Fragment>
                    {data.name === "My Learning Path" ? (
                      <ListItemText
                        primary={
                          <div style={{ display: 'flex !important' }}>
                            <div style={{ fontFamily: styleDesigns.fontFamily._heading }}>{i18n.t(data.name)}</div>
                            {showRecCnt && recommendedCount > 0 && (
                              <Badge max={9} badgeContent={recommendedCount} className={classes.badge} color="primary" aria-label={recommendedCount ? `new recommendations count ${recommendedCount}` : null} />
                            )}
                          </div>
                        }
                      />
                    ) : (
                      <ListItemText primary={
                        <div style={{ fontFamily: styleDesigns.fontFamily._heading }}>
                          {i18n.t(data.name)}
                        </div>
                      }
                      />
                    )}
                  </React.Fragment>
                ) : null}
              </ListItem>
              : null}
          </div>
        ))}
        {data && data[0] && data[0] === 'My Organization' && props.open && <Divider className={classes.dividerWrap} />}
      </List>
    )
  }

  return (
    <React.Fragment>
      <NavController
        handleDrawerOpen={props.handleDrawerOpen}
        open={props.open}
        onPinLeftMenu={props.onPinLeftMenu}
        handleDrawerClose={props.handleDrawerClose}
      />
      <Drawer
        variant="permanent"
        open={props.open}
        style={{ position: 'absolute', zIndex: 100 }}
        onMouseOver={props.onMenuMouseOver}
        onMouseLeave={props.onMenuMouseLeave}
      >

        {props.open ? (
          <img
            src={(domainInfo && domainInfo.name && domainInfo.name === 'PREP') ? PrepTrainingLogo : myAthinaDeskLogo}
            alt={(domainInfo && domainInfo.name && domainInfo.name === 'PREP') ? "Prep Training" : "myAthina"}
            className={(domainInfo && domainInfo.name && domainInfo.name === 'PREP') ? classes.prepTlogo : classes.athinaLogo} />
        ) : null}
        <div className={props.open ? classes.imgdivOpenWrapper : classes.imgdivWrapper}>
          {avatarLoader ? (
            <Skeleton
              circle
              height="100%"
              className={props.open ? classes.avatarSkeleton : classes.avatarSkeletonClose}
            />
          ) : (
            <React.Fragment>
              {props.userProfilePicture ? (
                <img src={props.userProfilePicture} alt="user profile" className={props.open ? classes.imgWrapperOpen : classes.imgWrapperClose} />
              ) : (
                <img src={AvatarIcon} alt="upload profile icon" className={props.open ? classes.imgIconOpen : classes.imgIconClose} />
              )}
            </React.Fragment>
          )}
        </div>
        {props.open ? (
          <React.Fragment>
            <Typography className={classes.userName}>{`${userDeatails && userDeatails.first_name ? userDeatails.first_name : ""} ${userDeatails && userDeatails.last_name ? userDeatails.last_name : ""}`}</Typography>
            <div className={classes.prfMenuWrapper}>
              {domainInfo.name !== 'PREP' ? (
                <React.Fragment>
                  {userSearchAccess && <Link component="button" aria-label="Home" onClick={() => { onProfileMenuClick('home') }} className={classes.linkWrapper}>
                    {i18n.t('Home')}
                  </Link>}
                  <Link component="button" aria-label="Profile" onClick={() => { onProfileMenuClick('profile') }} className={classes.linkWrapper}>
                    {i18n.t('Profile')}
                  </Link>
                </React.Fragment>
              ) : null}
              <Link component="button" aria-label="Logout" onClick={() => { onProfileMenuClick('logout') }} className={classes.linkWrapper}>
                {i18n.t('Logout')}
              </Link>
            </div>
            {enableAdminModeSwitch ? (
              <div className={classes.adminSwitchWrapper}>
                <AdminModeSwitch />
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
        <div className={classes.menuScroller}>
          {activeMenusList && Object.keys(activeMenusList) && Object.keys(activeMenusList).length ? (
            activeMenusList && Object.entries(activeMenusList).map((data, index) =>
              <React.Fragment key={index} >
                {props.open ? (<Typography className={classes.menuHeadWrapper}>{data && data[0] ? data[0] : 'NA'}</Typography>) : null}
                {getMenusList(data && data)}
              </React.Fragment>
            )
          ) : null}
        </div>
        {props.open ? (
          <Footer />
        ) : null}
      </Drawer>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    userProfilePicture: state.profilePicReducer.userProfilePicture,
  }
}
export default connect(
  mapStateToProps, { fetchProfilePic }
)(LeftMenu);
