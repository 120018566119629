import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
//LOCAL IMPORTS
import './arrows.css';
import i18n from '../../i18n/i18n';
import { styleDesigns } from '../../theme/styles';
import { articleImgMap } from '../../Configs/airticlesConfig';
import { getTenant } from '../../Configs/tenantConfig';
import { apiConfig } from '../../Configs/apiConfig';
import { getUrlParams, redirectTo, getLoggedInStatus } from '../../Helpers/basic';
import { getCourseProvderLogo } from '../../store/TenantConfigs/actionCreator';
import myAthinaLogo from '../../assests/myAthina_Desktop.svg';
import YoutubePng from '../../assests/videosImages/youtube.jpg';
import GridCardLoader from '../../Shared/Loaders/SkeletonLoaders/gridCard';

const useStyles = makeStyles(theme => ({
  image: {
    verticalAlign: 'middle',
    width: '197px',
    borderRadius: 2,
    backgroundColor: '#fefefe',
    marginTop: '249px',
    marginLeft: '-71px'
  },
  loaderWrapper: {
    verticalAlign: 'middle',
    width: '197px',
    borderRadius: 2,
    backgroundColor: '#fefefe',
    marginTop: '221px',
    marginLeft: '-71px'
  },
  courseImage: {
    width: '123px',
    height: '135px',
    marginTop: '204px',
    marginLeft: '-71px'
  },
  siteNameWrapper: {
    verticalAlign: 'middle',
    color: '#999',
    fontSize: '50px',
    fontFamily: styleDesigns.fontFamily._heading,
    marginTop: '238px',
    marginLeft: '-71px'
  },
  siteWrapper: {
    verticalAlign: 'middle',
    color: '#999',
    fontSize: '29px',
    marginTop: '248px',
    marginLeft: '-71px'
  },
  typo: {
    marginTop: '30px',
    fontFamily: styleDesigns.fontFamily._heading,
    fontSize: '20px'
  },
}));

export default function RedirectPage() {
  const classes = useStyles();
  const provider = getUrlParams(window.location.href, 'provider');
  const type = getUrlParams(window.location.href, 'type');
  const siteName = getUrlParams(window.location.href, 'site_name');
  const url = decodeURIComponent(getUrlParams(window.location.href, 'landingurl'));
  var reg = /:\/\/(.[^/]+)/;
  const site = url && url.match(reg) && url.match(reg)[1];
  let displayLink = ''
  if (type === 'webpages') {
    displayLink = getUrlParams(window.location.href, 'domain')
  }

  const articleImg = articleImgMap[siteName] && articleImgMap[siteName].img ? articleImgMap[siteName].img : null
  const [providerImg, setProviderImage] = useState('');
  const [providerLoader, setProviderLoader] = useState(true);

  //PROVIDERS CALLBACKS
  const successProvidersCB = res => {
    setProviderImage(res && res.logo);
    setProviderLoader(false);
    setTimeout(() => {
      if (!getLoggedInStatus()) {
        localStorage.setItem("onLoginSuccess", window.location.href);
        if (getTenant().sso) {
          redirectTo('/', false)
        } else {
          redirectTo('/', false)
        }
      } else {
        redirectTo(url, false)
      }
    }, apiConfig.course.search.redirectPageTime)
  }

  const failureProvidersCB = err => {
    setProviderImage(myAthinaLogo);
    setProviderLoader(false);
    setTimeout(() => {
      if (!getLoggedInStatus()) {
        localStorage.setItem("onLoginSuccess", window.location.href);
        if (getTenant().sso) {
          redirectTo('/', false)
        } else {
          redirectTo('/', false)
        }
      } else {
        redirectTo(url, false)
      }
    }, apiConfig.course.search.redirectPageTime)
  }

  useEffect(() => {
    if (provider && provider.length) {
      const payload = {
        name: provider
      }
      getCourseProvderLogo(payload, successProvidersCB, failureProvidersCB);
    } else {
      setTimeout(() => {
        if (!getLoggedInStatus()) {
          localStorage.setItem("onLoginSuccess", window.location.href);
          if (getTenant().sso) {
            redirectTo('/', false)
          } else {
            redirectTo('/', false)
          }
        } else {
          redirectTo(url, false)
        }
      }, apiConfig.course.search.redirectPageTime)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div>
        {type && type === 'articles' ? (
          <Grid container spacing={6}>
            <Grid item xs>
              <img src={myAthinaLogo} className={classes.image} id='MyathinaLogo' alt="myAthina" />
            </Grid>
            <Grid item xs>
              <div className="indicatorArrowAnimation">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Grid>
            <Grid item xs={2}>
              {articleImg ? (
                <img src={articleImg} className={classes.courseImage} id={siteName} alt={siteName} />
              ) : (
                <Typography className={classes.siteNameWrapper}>{siteName ? siteName : null}</Typography>
              )}
            </Grid>
          </Grid>
        ) : (
          <React.Fragment>
            {type && type === 'webpages' ? (
              <Grid container spacing={4}>
                <Grid item xs>
                  <img src={myAthinaLogo} className={classes.image} id='MyathinaLogo' alt="myathina" />
                </Grid>
                <Grid item xs>
                  <div className="indicatorArrowAnimation">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  {site ? (
                    <Typography className={classes.siteWrapper}>{site ? site : null}</Typography>
                  ) : (
                    <Typography className={classes.siteWrapper}>{displayLink ? displayLink : null}</Typography>
                  )}
                </Grid>
              </Grid>
            ) : (
              <React.Fragment>
                {type === 'videos' ? (
                  <Grid container spacing={6}>
                    <Grid item xs>
                      <img src={myAthinaLogo} className={classes.image} id='MyathinaLogo' alt="myathina" />
                    </Grid>
                    <Grid item xs>
                      <div className="indicatorArrowAnimation">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <img src={YoutubePng} className={classes.courseImage} id='youtube' alt="youtube" />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={6}>
                    <Grid item xs>
                      <img src={myAthinaLogo} className={classes.image} id='MyathinaLogo' alt="myathina" />
                    </Grid>
                    <Grid item xs>
                      <div className="indicatorArrowAnimation">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      {providerLoader ? (
                        <div className={classes.loaderWrapper}>
                          <GridCardLoader height="100px" />
                        </div>
                      ) : (
                        <React.Fragment>
                          {provider === 'MyAthina' ? (
                            <img src={providerImg} className={classes.image} id='MyathinaLogo' alt={provider} />
                          ) : (
                            <React.Fragment>
                              {provider === 'Linkedin Learning' || provider === 'Linuxfoundation' ? (
                                <img src={providerImg} className={classes.courseImage} id='MyathinaLogo' alt={provider} style={{ backgroundColor: '#fff' }} />
                              ) : (
                                <img src={providerImg} className={classes.courseImage} id='MyathinaLogo' alt={provider} />
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
      {type && type === 'articles' && siteName ? (
        <Typography className={classes.typo} >
          {i18n.t('You found a great article on')} <b style={{ color: styleDesigns.fontColour._appBlue }}>myAthina</b><br />
          {i18n.t('Taking you to the')} <b style={{ color: styleDesigns.fontColour._appBlue }}>{siteName}</b> {i18n.t('website')}
        </Typography>
      ) : (
        <React.Fragment>
          {!type ? (
            <Typography className={classes.typo} >
              {i18n.t('You found a great course on')} <b style={{ color: styleDesigns.fontColour._appBlue }}>myAthina</b><br />
              {i18n.t('Taking you to the')} <b style={{ color: styleDesigns.fontColour._appBlue }}>{provider}</b> {i18n.t('website')}
            </Typography>
          ) : null}
          {type === 'videos' ? (
            <Typography className={classes.typo} >
              {i18n.t('You found a great video on')} <b style={{ color: styleDesigns.fontColour._appBlue }}>myAthina</b><br />
              {i18n.t('Taking you to the')} <b style={{ color: styleDesigns.fontColour._appBlue }}>Youtube</b> {i18n.t('website')}
            </Typography>
          ) : null}
        </React.Fragment>
      )}
    </Container>
  )
}