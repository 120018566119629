import FacebookIcon from '../assests/SocialIcons/facebook.svg';
import InstagramIcon from '../assests/SocialIcons/instagram.svg';
import LinkedinIcon from '../assests/SocialIcons/linkedin.svg';
import TwitterIcon from '../assests/SocialIcons/twitter.svg';

export const socialLinks = [
  { key: 'fb', icon: FacebookIcon, link: 'https://www.facebook.com/myathina.ce/' },
  { key: 'insta', icon: InstagramIcon, link: 'https://instagram.com/myathina_ce/' },
  { key: 'linkedin', icon: LinkedinIcon, link: 'https://in.linkedin.com/showcase/myathina-com' },
  { key: 'twitter', icon: TwitterIcon, link: 'https://twitter.com/myathina_ce' }
]