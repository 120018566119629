import React, { useState } from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
//LOCAL IMPORTS
import { styleDesigns } from '../../theme/styles';
import { ConfirmButton } from '../../Shared/Buttons/confirmBtn';
import { TextInputField } from '../../Shared/InputFields/textInputField';
import { CancelButton } from '../../Shared/Buttons/cancelBtn';
import { requestForgotPassword } from '../../store/Auth/actionCreator';
import InlineAlerts from '../../Shared/AlertMessages/inlineAlerts';
import i18n from '../../i18n/i18n';

const useStyles = makeStyles(theme => ({
  bodyWrapper: {
    paddingTop: '50px',
    maxWidth: '1200px',
    paddingLeft: '24px',
    paddingRight: '24px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    display: 'block',
    textAlign: 'center'
  },
  forgotText: {
    fontSize: `${styleDesigns.fontSize._heading} !important`,
    margin: '0px 0px 16px !important',
    fontWeight: `${styleDesigns.fontWeight._heading} !important`,
    lineHeight: 1.5,
    fontFamily: `${styleDesigns.fontFamily._heading} !important`
  },
  forgotSubText: {
    fontSize: `${styleDesigns.fontSize._infoHeading} !important`,
    margin: '0px 0px 16px !important',
    fontWeight: `${styleDesigns.fontWeight._infoHeading} !important`,
    lineHeight: 1.5,
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    color: styleDesigns.fontColour._grey
  },
  requestIcon: {
    marginBottom: '40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '160px'
  },
  form: {
    width: '100%'
  },
}));

function ForgotPassword(props) {
  const [requestSent, setRequestSent] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const [inProgress, setInProgress] = useState(false);
  const [errStatus, setErrStatus] = useState({
    status: false,
    errMessage: ''
  })

  const onBckToLogin = () => {
    navigate('/')
  }

  const successCallBack = res => {
    setRequestSent(true);
    setInProgress(false);
  }
  const failureCallBack = err => {
    setRequestSent(false);
    setInProgress(false);
    if (err && err.response && err.response.status === 400) {
      setErrStatus({
        status: true,
        errMessage: err.response && err.response.data && err.response.data.email && err.response.data.email[0] ? err.response.data.email[0] : "Something went wrong! Please try again!"
      })
    } else {
      setErrStatus({
        status: true,
        errMessage: i18n.t('Something went wrong! Please try again later!')
      })
    }
  }

  const onRequestChangeClick = evnt => {
    evnt.preventDefault();
    setErrStatus({
      status: false,
      errMessage: ''
    })
    setInProgress(false);
    const userName = document.getElementById('userName').value
    if (userName === '') {
      setErrStatus({
        status: true,
        errMessage: i18n.t('Please enter required information!')
      })
      setInProgress(false);
    } else {
      setInProgress(true);
      requestForgotPassword({ email: userName }, successCallBack, failureCallBack)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.bodyWrapper}>
        <Container component="main" maxWidth="sm">
          {requestSent ? (
            <React.Fragment>
              <Typography className={classes.forgotText}>{i18n.t('Request sent successfully')}</Typography>
              <Typography className={classes.forgotSubText}>
                {i18n.t('We have sent change password link to your email. Please click that link and change your password.')}
              </Typography>
              <CancelButton
                id="cancel"
                ariaLabel="Go back"
                label="back"
                onCancelBtnClick={onBckToLogin}
              />
            </React.Fragment>
          ) : null}
          {!requestSent && <React.Fragment>
            <Typography className={classes.forgotText}>{i18n.t('Forgot your password?')}</Typography>
            <Typography className={classes.forgotSubText}>
              {i18n.t('Please enter the email address associated with your account and We will email you a link to reset your password.')}
            </Typography>
            {errStatus.status ? (
              <InlineAlerts status={errStatus.status ? 'error' : 'success'} message={errStatus.errMessage} />
            ) : null}
            <form className={classes.form} noValidate onSubmit={onRequestChangeClick} autoComplete="off">
              <TextInputField
                type="text"
                id="userName"
                ariaLabel="Please add your email address"
                label={i18n.t("Email Address")}
                name="email"
                startIcon="username"
                required={true}
                autoFocus={true}
              />
              <ConfirmButton
                id="changepassword"
                ariaLabel="Request to change password"
                label={i18n.t("Request to change password")}
                onBtnClick={onRequestChangeClick}
                disabled={inProgress}
              />
            </form>
            <CancelButton
              id="cancel"
              ariaLabel="Go back"
              label={i18n.t("back")}
              disabled={inProgress}
              onCancelBtnClick={onBckToLogin}
            />
          </React.Fragment>}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, {}
)(ForgotPassword);