import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
//LOCAL IMPORTS
import { getLoggedInStatus, getUserDetails, redirectTo, openFreshworksWidget } from '../../Helpers/basic';
import { getTenant } from '../../Configs/tenantConfig';
import { languageConfigs } from '../../Configs/languageConfig';
import { styleDesigns } from '../../theme/styles';
import myAthinaDesktop from '../../assests/myAthina_Desktop.svg';
import NormalLogin from './login';
import SSOLogin from './sso';
import { socialLinks } from '../../Configs/socialLinks';
import BannerImage from '../../assests/LoginFiles/bannerImage.png';
import { getLoginBannerContent } from '../../store/TenantConfigs/actionCreator';
import '../../Shared/CssEffects/keyFrameEffects.css';
import i18n from '../../i18n/i18n';
import GridCardLoader from '../../Shared/Loaders/SkeletonLoaders/gridCard';
import PrepTrainingLogo from '../../Layout/MainLayout/prepTraining.svg';

const useStyles = makeStyles(theme => ({
  paperWrapper: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    boxShadow: 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px !important'
  },
  formWrapper: {
    paddingLeft: '24px',
    paddingRight: '24px',
    width: '100%',
    marginLeft: 'auto !important',
    boxSizing: 'border-box',
    marginRight: 'auto !important',
    padding: '14px 0px',
    display: 'flex',
    maxWidth: '480px !important',
    alignItems: 'center'
  },
  athinaLogo: {
    width: '150px',
    height: '90px'
  },
  prepTLogo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    height: '90px'
  },
  clientLogo: {
    width: '144px',
    height: '88px',
    marginLeft: '15px'
  },
  clientLargeLogo: {
    marginBottom: '26px',
    width: '144px',
    height: '36px',
    marginLeft: '15px'
  },
  niyoLargeLogo: {
    marginBottom: '18px',
    width: '61px',
    height: '59px',
    marginLeft: '15px'
  },
  lngIcon: {
    color: `${styleDesigns.fontColour._myAthinaBlue} !important`,
    float: 'right'
  },
  socialWrapper: {
    width: '30%',
    height: '100%',
    cursor: 'pointer'
  },
  socialLink: {
    display: 'flex',
    textAlign: 'center',
    width: '100%',
    height: '105px'
  },
  footer: {
    position: 'fixed',
    bottom: '0',
  },
  copyWrite: {
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    marginTop: '0px !important'
  },
  categoryWrapper: {
    cursor: 'pointer',
    width: '175px',
    // height: '175px',
    height: '100%',
    paddingTop: '3%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: styleDesigns.fontColour._white,
    fontFamily: styleDesigns.fontFamily._heading,
    fontWeight: styleDesigns.fontWeight._heading,
    textAlign: 'center'
  },
  cateimgWrapper: {
    height: '57px',
    width: '57px',
    marginTop: '19px'
  }
}));

function Copyright(props) {
  const classes = useStyles();
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props} className={classes.copyWrite}>
      {i18n.t('Copyright ©')}
      {i18n.t('myAthina')}
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function LoginComponent(props) {
  const showTranslator = false;
  const theme = createTheme();
  const classes = useStyles();
  const tenantInfo = getTenant();
  const loggedInStatus = getLoggedInStatus();
  const userDetails = getUserDetails();
  const { i18n } = useTranslation();
  const [selectedLng, setSelectedLng] = useState('en-GB');
  const [showLngOpts, setShowLngOpts] = useState(null);
  const open = Boolean(showLngOpts);

  const [bannerData, setBannerData] = useState([]);
  const [bannerImg, setBannerImg] = useState('');
  const [bannerLoader, setBannerLoader] = useState(true);

  const successBannerData = res => {
    setBannerLoader(false);
    setBannerImg(res && res.results && res.results.banner_image);
    setBannerData(res && res.results && res.results.categories && res.results.categories);
  }
  const failureBannerData = err => {
    setBannerLoader(false);
    setBannerData([]);
    setBannerImg(BannerImage)
  }

  useEffect(() => {
    setSelectedLng(localStorage.getItem('i18nextLng'))
    if (loggedInStatus) {
      window.location = userDetails && userDetails.defaultLandingPage ? userDetails.defaultLandingPage : '/app'
    }

    //FOR FETCHING THE BANNER IMAGES AND CONTENT
    setBannerLoader(true);
    getLoginBannerContent({}, successBannerData, failureBannerData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = (event) => {
    setShowLngOpts(event.currentTarget);
  };
  const handleClose = () => {
    setShowLngOpts(null);
  };

  const onLangChange = (key) => {
    i18n.changeLanguage(key);
    setSelectedLng(key);
  }

  const onCategoryClick = (data) => {
    if (data && (data.id === 2 || data.title === 'Help')) {
      openFreshworksWidget('feedback');
    } else {
      redirectTo(data && data.landing, true);
    }
  }

  //FOR RENDETING THE TANANT LOGO
  const getTenantLogo = () => {
    if (tenantInfo && Object.keys(tenantInfo) && Object.keys(tenantInfo).length) {
      if (tenantInfo.client === 'ansrsource' || tenantInfo.client === 'ansrsourcegems') {
        return (
          <img src={tenantInfo && tenantInfo.logo} alt="client Logo" className={classes.clientLargeLogo} />
        )
      } else if (tenantInfo.client === 'goniyo') {
        return (
          <img src={tenantInfo && tenantInfo.logo} alt="client Logo" className={classes.niyoLargeLogo} />
        )
      } else {
        return (
          <img src={tenantInfo && tenantInfo.logo} alt="client Logo" className={classes.clientLogo} />
        )
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {showTranslator && <Tooltip title="Language settings">
        <IconButton aria-label="Change Language" component="span" onClick={handleClick} className={classes.lngIcon}>
          <LanguageIcon />
        </IconButton>
      </Tooltip>}
      <Menu
        anchorEl={showLngOpts}
        open={open}
        onClose={handleClick}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {languageConfigs && languageConfigs.length ? (
          <div>
            {languageConfigs.map((data, index) => {
              return (
                <MenuItem selected={data.key === selectedLng} key={index} onClick={() => { onLangChange(data.key) }}>
                  <ListItemIcon>
                    <LanguageIcon fontSize="small" />
                  </ListItemIcon>
                  {data.name}
                </MenuItem>
              )
            })}
          </div>
        ) : null}
      </Menu>
      <Grid container component="main" sx={{ height: '100vh' }} >
        <CssBaseline />
        <Grid
          item
          sm={8}
          md={6}
          display={{ xs: "none", lg: "block", md: "block" }}
        >
          {bannerLoader ? (
            <GridCardLoader height="690px" />
          ) : (
            <Paper className={classes.paperWrapper} aria-label="Login page banner image" style={{ backgroundImage: `url(${bannerImg})` }}>
              {bannerData && bannerData.length ? (
                <React.Fragment>
                  {bannerData && bannerData.map((data, index) => {
                    return (
                      <div className="fadeintoout pop" aria-label={data && data.title && data.title} key={index} style={{ height: '25%' }}>
                        <div className={classes.categoryWrapper} aria-label="Category images" onClick={() => { onCategoryClick(data) }} style={{ backgroundImage: `url(${data.image})` }}>
                          <img src={data && data.icon} alt={data && data.title} className={classes.cateimgWrapper} />
                          <p aria-label="link to the category" style={{ animation: 'fadeintoout-fade 2s ease-in' }}>{i18n.t(data && data.title)}</p>
                        </div>
                      </div>
                    )
                  })}
                </React.Fragment>
              ) : null}
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} sm={8} md={5} className={classes.formWrapper}>
          <div style={{ width: '100%' }}>
            {tenantInfo && tenantInfo.name && tenantInfo.name === 'PREP' ? (
              <img src={PrepTrainingLogo} alt="Prep Training Logo" className={classes.prepTLogo} />
            ) : (
              <React.Fragment>
                <img src={myAthinaDesktop} alt="myAthina Logo" className={classes.athinaLogo} />
                {getTenantLogo()}
              </React.Fragment>
            )}
            {tenantInfo && tenantInfo.sso ? (
              <SSOLogin location={props.location} history={props.history} />
            ) : (
              <NormalLogin location={props.location} history={props.history} />
            )}
            <div className={classes.footer}>
              {socialLinks && socialLinks.length ? (
                <div className={classes.socialLink}>
                  {socialLinks.map((link, index) => {
                    return (
                      <Link key={index} href={link.link} target="_blank" rel="noreferrer">
                        <img src={link.icon} alt={link.key} className={classes.socialWrapper} />
                      </Link>
                    )
                  })}
                </div>
              ) : null}
              <Copyright sx={{ mt: 5 }} />
            </div>
          </div>
        </Grid>
      </Grid>
    </ThemeProvider >
  );
}
