import { getEnv } from '../Helpers/basic'
const apiDomain = env => {
  switch (env) {
    case 'prod':
      return '//api.myathina.com/'
    case 'clone':
      return '//104.211.75.247/'
    case 'goniyo_dev':
    case 'ce_dev':
    case 'prep_dev':
    case 'zopsmart_dev':
    case 'sandbox_dev':
    case 'falabella_dev':
    case 'ansrsource_dev':
    case 'demo_dev':
    case 'caseys_dev':
    case 'freo_dev':
    case 'ansrsourcegems_dev':
    case 'staging':
    case 'dev_uxv2':
      return 'https://wsre.api.moocpedia.com/'
    case 'staging1':
      return 'https://staging.api.moocpedia.com/'
    case 'dev':
    case 'test':
    default:
      return 'https://wsre.api.moocpedia.com/'
    // return '//52.172.131.129:8000/'
  }
}

//FOR CHANGING THE STAGING SERVERS
let developerServerConfig = ''
if (localStorage.getItem('developer_server')) {
  developerServerConfig = localStorage.getItem('developer_server');
}

const domain = developerServerConfig ? developerServerConfig : apiDomain(getEnv());

export const apiConfig = {
  affiliateId: 'R1sOYsaeUzM',
  affiliateProviders: ['Datacamp'],
  googleAnalytics: {
    id: 'UA-152754387-1' //USED FOR NEW UX
  },
  activityLog: {
    logUrl: `${domain}accounts/activity/` //USED FOR NEW UX.
  },

  userAuth: {
    loginUrl: `${domain}user/authenticate/`, //USED FOR NEW UX.
    logoutUrl: `${domain}user/logout/`,
    reqChangePasswordUrl: `${domain}api/password_reset/`, //USED FOR NEW UX.
    changePasswordUrl: `${domain}api/password_reset/confirm/`, //USED FOR NEW UX.
    etaAuth: `${domain}accounts/auth/profile/` //USED FOR NEW UX.
  },
  dashboard: {
    dashboardDataUrl: `${domain}dashboard/courses/report/`,
    dashboardCustomerData: `${domain}dashboard/customer/report/`, //FOR NUMBER RANGE FILTER OLD API not in use
    trainingDataUrl: `${domain}dashboard/documents/report/`,
    mandateCoursesData: `${domain}dashboard/mandate/report/v2/`, //FOR FETCHING THE MANDATED COURSES
    fetchDeptAvgSpentChart: `${domain}dashboard/learning/`, // /hours/ FOR FETCHING THE TEAMS AVG SPENT TIME CHART
    fetchDeptAvgMemList: `${domain}dashboard/learning/`, //FOR FETCHING THE DEPARTMENT MEMBERS LIST AVG SPENT TIME
    fetchPolicyChartData: `${domain}dashboard/policies/report/`, //for fetching dashboard policy data
    fetchSingleMndtCrsReport: `${domain}dashboard/mandate/courselevel/report/v2/`, //FOR FETCHING THE REPORT OF ONE MANDATED COURSE
    fetchMndtCrsUsersStatusReport: `${domain}dashboard/mandate/courselevel/status/v2/`, //FOR FETCHING SINGLE COURSE STATUS BASED EMP INFO
    fetchCrsUsersStatusReportCSV: `${domain}dashboard/mandate/courselevel/status/v2/`, //for getting users status csv

    //FOR V2 DESIGN API CALLS
    getNumberBoxCounts: `${domain}dashboard/cards/count/`,
    getCourseBreakupTeams: `${domain}dashboard/teams/breakup/`,
    getUniqueSearchTerms: `${domain}dashboard/unique/searches/`,
    getPendingApprovals: `${domain}dashboard/pending/approvals/`,
    getTop5External: `${domain}dashboard/external/topfive/`

  },
  teams: {
    listURL: `${domain}accounts/fetch_team_list/`
  },
  members: {
    enableAddMember: false,
    listUrl: `${domain}accounts/userprofile/reportees/`, //USED FOR NEW UX.
    addMembers: `${domain}accounts/register/`,
    toggleAddMemberAccess: `${domain}accounts/userprofile/`,
    removingUser: `${domain}accounts/userprofile/`,
    membersList: `${domain}accounts/members/`,
    orgMembersList: `${domain}accounts/org-members/`, //USED FOR NEW UX
    orgTeamsList: `${domain}accounts/fetch_team_list/` //USED FOR NEW UX
  },
  profile: {
    fetch: `${domain}accounts/userprofile/`, //USED FOR NEW UX
    update: `${domain}accounts/user/`, //USED FOR NEW UX
    fetchSkills: `${domain}accounts/skills/`,//USED FOR NEW UX
    changePassword: `${domain}accounts/dwphc/`, //USED FOR NEW UX
    updateProfilePic: `${domain}accounts/user_profile_avatar/`, //USED FOR NEW UX.
    fetchProfilePic: `${domain}accounts/user_profile_avatar/`, //USED FOR NEW UX.
    removeProfilePic: `${domain}accounts/user_profile_avatar/`, //USED FOR NEW UX.

    //FOR V2 PROFILE DESIGN
    fetchUserCrsCounts: `${domain}profile/course/counts/`, //USED FOR NEW UX
    fetchV2UserSkills: `${domain}profile/skills/v2/`, //USED FOR NEW UX
    fetchV2LearningGoals: `${domain}profile/learninggoals/v2/`, //USED FOR NEW UX
    fetchV2WorkTimeAllocations: `${domain}profile/workallocation/v2/`, //USED FOR NEW UX
    fetchV2SkillSummary: `${domain}profile/skills/summary/`, //USED FOR NEW UX
    fetchSkillSummarySkillCourses: `${domain}profile/skills/course/`, //USED FOR NEW UX
    postActionAskToUpdateProfile: `${domain}profile/skills/v2/` //USED FOR NEW UX
  },
  rewards: {
    fetchRewards: `${domain}rewards/course/approve/display/`,
    claimRewardPoints: `${domain}rewards/course/approve/redeem/`
  },
  course: {
    search: {
      redirectPageTime: 3000, //USED FOR NEW UX
      searchUrl: `${domain}v1/courses/search/`,
      //FOR fetch LISTING
      fetchArticles: `${domain}v1/courses/search_articles/`,
      fetchWebPages: `${domain}v1/courses/search_google/`,
      fetchVideosData: `${domain}v1/courses/search_youtube/`,
      //actions
      articleLikeUnlike: `${domain}v1/courses/search_like/`,
      articlesRecommend: `${domain}v1/courses/search_recommend/`,
      articlesPostCommnet: `${domain}v1/courses/search_comment/`,
      articlesFetchComments: `${domain}v1/courses/search_comment_fetch/`,
      fetchAddedUserComment: `${domain}v1/courses/fetch_comment/`,

      resultsPerPage: 10,
      searchRecommendedUrl: `${domain}course/track/?level=1`,
      detailsRecommendedUrl: `${domain}course/track/?level=2`,
      recommendToUrl: `${domain}accounts/usercourse/recommend/`,
      recommendedCourseList: `${domain}accounts/recommended_course_list/`,
      recommendTracking: `${domain}accounts/recommended_course_notification/`, //USED FOR NEW UX
      //recommendedCourseList: `${domain}accounts/recommended_course_notification/`,
    },
    detailsUrl: `${domain}v1/course`,
    makeVisitedCourse: `${domain}accounts/activity/`, //USED FOR NEW UX
    relatedCourses: `${domain}v1/courses/search/`,
    myCourses: {
      mycourses: `${domain}accounts/usercourse/`, //USED FOR NEW UX
      archiveVistedCourse: `${domain}accounts/usercourse/`,
      withdrawApprovalReq: `${domain}accounts/usercourse/`,
      requestToExtendDueDate: `${domain}accounts/extend/`, //USED FOR NEW UX
      requestToExternalCourse: `${domain}accounts/usercourse/external/`, //USED FOR NEW UX
    },
    learningMaterials: {
      learningMaterials: `${domain}hosting/course/progress/list/`,
      CourseCategory: `${domain}hosting/category/`,
    }
  },

  internalCourses: {
    internalCoursesListing: `${domain}hosting/course/progress/list/v2/`, //hosting/course/progress/list/ //USED FOR NEW UX.
    prepInternalCoursesListing: `${domain}hosting/course/progress/list/prep/`,
    CourseCategoryConfig: `${domain}hosting/category/`, //USED FOR NEW UX.
  },

  approvalrequests: {
    fetchTabCounts: `${domain}dashboard/courses/tabcount/`, //USED FOR NEW UX
    fetchMngrAppReq: `${domain}dashboard/courses/pending/`, //USED FOR NEW UX
    fetchLeaderAppReq: `${domain}dashboard/leader/`, //USED FOR NEW UX
    dueDateExtensionRequests: `${domain}accounts/extend/view/`, //USED FOR NEW UX
    updateDueDate: `${domain}accounts/extend/verify/`, //USED FOR NEW UX

    downloadManagerReportCsvFile: `${domain}dashboard/courses/pending/`,//USED FOR NEW UX. //NEW API need to work by bankend
    downloadHRReportCsvFile: `${domain}dashboard/leader/`//USED FOR NEW UX. //NEW API need to work by bankend
  },
  myLearningPath: {
    recommendedCourse: `${domain}course/path/`,
    //FOR MYPATH OPRIMIZATION
    mypathRecGetResults: `${domain}course/learning/view/`,
    mypathLearningGoalGetResults: `${domain}course/learninggoal/view/`,
    mypathRecPost: `${domain}course/path/`,
  },
  trainingDocs: {
    trainingUrl: `${domain}docs/user/`,
    finishButton: `${domain}training/path/`,
  },
  myDocs: {
    fetchDocsLibraryTabCounts: `${domain}docs/tabcounts/`,//USED FOR NEW UX //FOR FETCHING THE DOCS LIBRARY TAB COUNTS
    fetchDocsLibraryFiles: `${domain}docs/files/`,//USED FOR NEW UX //FOR FETCHING THE TAB DATA BASED ON QUERY PARAMS
    fetchEmbededGoogleForms: `${domain}docs/googleforms/`,//USED FOR NEW UX //FOR FETCHING THE TAB DATA BASED ON QUERY PARAMS
    deleteDocsLibraryFile: `${domain}docs/files/`,//USED FOR NEW UX //FOR DELETING THE DOC LIBRARY FILE BASED ON ID AND TYPE
    statusForDoc: `${domain}docs/users/?trainingId=`, //USED FOR NEW UX
    exportMyDocsReportCSV: `${domain}docs/users/`, //NEW API BACKEND PENDING//USED FOR NEW UX
    pdfImageViewer: `${domain}docs/log/`, //USED FOR NEW UX
    nextButtonActivation: 8000, //USED FOR NEW UX
    policyDocNextButtonActivation: 1000, //USED FOR NEW UX
    fetchAssessment: `${domain}training/assessment/`, //USED FOR NEW UX
    submitAssessment: `${domain}assessment/user/`, //USED FOR NEW UX
    completeTrainingUrl: `${domain}training/path/`, //USED FOR NEW UX
    searchDocsLibraryFiles: `${domain}docs/search/`,//USED FOR NEW UX //FOR SEARCH QUERY ON ALL THE TABS
    likeUnlikeDocsVideo: `${domain}docs/like_status/`,//USED FOR NEW UX //FOR LIKE OR DISLIKE OF VIDEO IN VIMEO
    postVideoTrackInfo: `${domain}docs/video/tracking/`,//USED FOR NEW UX //FOR POSTING THE VIMEO DOCS VIDEO DETAILS
    getVideoTrackInfo: `${domain}docs/video/tracking/`,//USED FOR NEW UX //FOR GETTIGN THE VIMEO DOCS VIDEO DETAILS
    uploadDocument: `${domain}docs/training/`, //USED FOR NEW UX
    uploadEmbedGoogleForms: `${domain}docs/googleforms/`, //USED FOR NEW UX
    editEmbedGoogleForms: `${domain}docs/googleforms/edit/`, //USED FOR NEW UX
    editDocument: `${domain}docs/`, //USED FOR NEW UX
    postAssessment: `${domain}assessment/construct/`, //USED FOR NEW UX

    nextButton: `${domain}docs/log/`,
    //new docs library flow
    uploadVideo: `${domain}docs/video/`,//USED FOR NEW UX //FOR UPLOAD VIDEO
    updateVideo: `${domain}docs/video/update/`,//USED FOR NEW UX //FOR UPDATE VIDEO
    videoUploadSize: 629000000, //USED FOR NEW UX
    transcriptSize: 200097152, //USED FOR NEW UX
    thumbnailSize: 2097152, //USED FOR NEW UX

    uploadDocsVideoChunks: `${domain}docs/chunk/video/`, //PENDING BACKEND

    getScormPublishedCourses: `${domain}courses/scorm/published/` //PENDING BACKEND
  },
  userProfile: {
    fetchComponents: `${domain}profile/render/`, //USED FOR NEW UX.
    fetchRoles: `${domain}profile/role/`, //USED FOR NEW UX
    fetchSkiils: `${domain}profile/skill/`, //USED FOR NEW UX
    fetchInterest: `${domain}profile/interest/`, //USED FOR NEW UX
    worktimeallocation: `${domain}profile/work/`,//USED FOR NEW UX
    submitDetails: `${domain}profile/create/`, //USED FOR NEW UX

    //FOR NEW CHANGES OF WELCOME PAGE
    fetchCategoryWiseSkills: `${domain}profile/skills/categorywise/`, //BACKEND PENDING
    fetchCategoryWiseInterests: `${domain}profile/interests/categorywise/` //BACKEND PENDING
  },
  skillMap: {
    fetchMainNodeFunctions: `${domain}profile/funtion_domain/`,
    fetchFunctionChartData: `${domain}profile/funtion_domain/`,
    fetchParetoChartData: `${domain}profile/department/`,
    fetchTreeData: `${domain}profile/org_tree/`,
    fetchMembersTableList: `${domain}profile/member/`,
  },
  manageBudget: {
    getMyBudget: `${domain}accounts/budget/user/`,//USED FOR NEW UX //FOR FETCH MY individual BUDGET in req approval page

    addBudget: `${domain}accounts/budget/assign/`, //assign budget
    fetchAllocatedIndividualBudgets: `${domain}accounts/budget/member/`, //FOR ALREADY ALLOCATED MEMBERS FETCH
    fetchAllocatedTeamBudgets: `${domain}accounts/budget/team/`, //FOR ALREADY ALLOCATED TEAMS FETCH

    fetchMyTeamBudget: `${domain}budget/team_budget/`, //FOR FETCH MY TEAM BUDGET for myteam
    fetchMyBudget: `${domain}budget/my_budget/`, //FOR FETCH MY BUDGET in mybudget page
    fetchMyUtilizedBudget: `${domain}budget/my_utilized_budget/`, //FOR FETCH MY UTILIZED BUDGET for utilizatinon in mybudget
    fetchMyApprovalPendingBudget: `${domain}budget.myapproval_budget/` //FOR FETCHING MY APPROVAL PENDING BUDGET in mybudget page
  },
  //FOR NEW OPTIMIZATION MYCOURSES API CALLS
  userCoursesTrack: {
    internalCourses: `${domain}accounts/usercourse/internal/`,
    externalCourses: `${domain}accounts/usercourse/external/`,
    tranings: `${domain}docs/fetch/`,

    //FOR EXTERNAL ASSESSMENTS
    fetchExternalAssessments: `${domain}accounts/external/assessments/`,
    fetchExternalAssignments: `${domain}accounts/external/assignments/`

  },
  //FOR NEW OPTIMIZATION MYLEARNINGPATH API CALLS
  userMyPathTrack: {
    visitedCourses: `${domain}accounts/usercourse/explore/`, //visited tab data
    approvalPendingCourses: `${domain}accounts/usercourse/explore/`, //approval tab data
    rejectedCourses: `${domain}accounts/usercourse/explore/`, //rejected tab data
    recToMeTrack: `${domain}accounts/recommended/view/`, //FOR FETHICNG REC TO ME RECOMMENDATIONS
    recByMeTrack: `${domain}accounts/recommended/view/`, //rec by me
  },
  //FOR VERIFY APPROVALS API CALLS
  verifyApprovals: {
    completedCourses: `${domain}accounts/verify/view/`,//USED FOR NEW UX.      //FOR FETHCING TAB DATA
    verificationStatus: `${domain}accounts/verify/`,//USED FOR NEW UX.        // FOR MARK IT VERIFY BUTTON CALLS
    verificationCounts: `${domain}accounts/verify/`,//USED FOR NEW UX.         // FOR FETHCING TAB COUNTS
    reimburseStatus: `${domain}accounts/reimburse/`, //USED FOR NEW UX.     //FOR REIMBURSEMENT VERIFICATION
    downloadZipFile: `${domain}accounts/downloadzip/`, //USED FOR NEW UX
    downloadReportCsvFile: `${domain}accounts/verify/view/`//USED FOR NEW UX.
  },
  //FOR ENTIRE NOTIFICATION MODULE
  notifications: {
    fetchNotificationCount: `${domain}accounts/notifications_count/`,
    fetchNotificationList: `${domain}accounts/notifications_list/`
  },
  //FOR ENTIRE USER SETTINGS APPCONFIG
  appSettings: {
    fetchColumnNames: `${domain}profile/onboarding/headers/`, //FOR FETCHING THE COLUMNS
    fetchOnboardedUsers: `${domain}profile/onboarding/users/`, //FOR FETCHING THE ALREADY ONBOARDED EMPLOYEES
    uploadUsersFile: `${domain}profile/onboarding/users/`, //FOR UPLOADING XLSX FILE
    verifyEmployees: `${domain}profile/onboarding/verify/`, //FOR VERIFYING THE XLSX FILE USERS
    verifyErrorEntries: `${domain}profile/onboarding/verify/`, //FOR VERIFYING THE ERROR AND WARNING ENTRIES single
    proceedToOnboard: `${domain}profile/onboarding/save/`, //FOR ONBOADRING THE CORRECT ENTRIES
    updateUser: `${domain}profile/onboarding/users/`, //FOR UPDATING THE USER INFO FROM ONBOARDED USERS
    deleteUser: `${domain}profile/onboarding/users/`, //FOR DELETING THE ONBOARDED USER
    makeUserActive: `${domain}profile/onboarding/users/`, //FOR MAKING USER STATUS TO ACTIVE
    proceedAsNewTeam: `${domain}profile/onboarding/newteam/`, //FOR CREATING NEW TEAM AND ADD USERS
    deleteNewDeptFunCard: `${domain}profile/onboarding/delteam/`, //FOR DELETING THE NEW DEPT OR FUN CARD
    fetchFunctionDomains: `${domain}profile/functionlist/` //FOR FETCHING THE FUNCTION DOMAIN LIST
  },
  appSettingsClientManagement: {
    fetchColumnNames: `${domain}clientmanagement/onboarding/headers/`, //FOR FETCHING THE COLUMNS
    fetchOnboardedUsers: `${domain}clientmanagement/onboarding/users/`, //FOR FETCHING THE ALREADY ONBOARDED EMPLOYEES
    uploadUsersFile: `${domain}clientmanagement/onboarding/users/`, //FOR UPLOADING XLSX FILE
    verifyEmployees: `${domain}clientmanagement/onboarding/verify/`, //FOR VERIFYING THE XLSX FILE USERS
    verifyErrorEntries: `${domain}clientmanagement/onboarding/verify/`, //FOR VERIFYING THE ERROR AND WARNING ENTRIES single
    proceedToOnboard: `${domain}clientmanagement/onboarding/save/`, //FOR ONBOADRING THE CORRECT ENTRIES
    updateUser: `${domain}clientmanagement/onboarding/users/`, //FOR UPDATING THE USER INFO FROM ONBOARDED USERS
    deleteUser: `${domain}clientmanagement/onboarding/users/`, //FOR DELETING THE ONBOARDED USER
    makeUserActive: `${domain}clientmanagement/onboarding/users/`, //FOR MAKING USER STATUS TO ACTIVE
    proceedAsNewTeam: `${domain}clientmanagement/onboarding/newteam/`, //FOR CREATING NEW TEAM AND ADD USERS
    deleteNewDeptFunCard: `${domain}clientmanagement/onboarding/delteam/`, //FOR DELETING THE NEW DEPT OR FUN CARD
    fetchFunctionDomains: `${domain}clientmanagement/functionlist/` //FOR FETCHING THE FUNCTION DOMAIN LIST
  },
  //FOR myathina USER/DEPT PERMISSONS
  userPermissions: {
    fetchTenantAdmins: `${domain}profile/adminprivileges/`, //FOR FETCHING THE ALL THE ADMINS DATA
    makeUsersAsAdmins: `${domain}profile/adminprivileges/`, //FOR MAKING THE USERS AS ADMINS
    removeAdminAccess: `${domain}profile/adminprivileges/`, //FOR REMOVING THE ADMIN ACCESS TO USER
    fetchLeftMenus: `${domain}profile/leftpanel_menus/`, //FOR FETCHING THE ALL LEFT MENUS
    assignMenusToDepts: `${domain}profile/department_menus/`, //FOR ASSIGNING THE MENUS TO DEPARTMENTS
    fetchSingleDeptMenus: `${domain}profile/department_menus/`, //FOR FETHICNG THE SINGLE DEPAMENT ACTIVE MENUS
    fetchSingleUserMenus: `${domain}profile/fetch_user_menus/`, //FOR FETCHING THE SINGLE USER ACTIVE MENUS
    fetchSingleMenuUsers: `${domain}profile/menu_users/`, //FOR FETCHING THE SINGLE MENU USERS BASED ON MENU ID
    onSaveSingleMenuUsers: `${domain}profile/menu_users/`, //FOR SAVING THE SELECTED USERS FOR SINGLE MENU
  },
  userSettings: {
    fetchUserSettings: `${domain}users/settings`,
    updateUserSettings: `${domain}accounts/update_settings`,
    fetchAllStores: `${domain}users/stores`,
  },
  skillIndex: {
    fetchTenantSkillIndex: `${domain}tenant/skillindex`,
    fetchTopTenSkillsIndex: `${domain}tenant/toptenskillsindex`,
  },
  myEvents: {
    fetchOnlineScheduledEvents: `${domain}live_events/schedule_events/`, //USED FOR NEW UX //for fetching the scheduled online events
    fetchOnlineAssignedEvents: `${domain}live_events/list_event/new/`, //USED FOR NEW UX //for fetching the assigned events
    fetchOnlinePastEvents: `${domain}live_events/past_event/`, //USED FOR NEW UX //for fetching the past events
    deleteCalenderEvent: `${domain}live_events/live_event/new/`, //USED FOR NEW UX //FOR DELETING THE EVENT
    enrollEvent: `${domain}live_events/enrollEvent/`, //USED FOR NEW UX
    fetchEventEmpInfo: `${domain}live_events/emp_info/`,//USED FOR NEW UX //FOR GETTING ACCEPTED INVITED USERS INFO OF A MEETING
    fetchEvntPariticipants: `${domain}live_events/participants/`,//USED FOR NEW UX //FOR FETCHING THE PARTICIPATED USERS INFO OF A MEETING
    exportCSVEvntEmpInfo: `${domain}live_events/emp_info/`,//USED FOR NEW UX //FOR DOWLOADING THE CSV OF EMP INFO
    fetchZoomHolders: `${domain}accounts/profile/zoom_holders/`, //USED FOR NEW UX
    updateZoomKey: `${domain}accounts/profile/updatekeys/`, //USED FOR NEW UX
    verifyXlsxZoomAttendies: `${domain}live_events/assign_user/`,//USED FOR NEW UX //FOR VERIFYING THE XLSX UPLOAD ATTENDIES
    createEvent: `${domain}live_events/live_event/`, //USED FOR NEW UX
    updateEvent: `${domain}live_events/live_event/new/`,//USED FOR NEW UX //FOR UPDATING THE EVENT

    fetchAssignedEvents: `${domain}live_events/offline_event/`,
    confirmBooking: `${domain}live_events/confirm_booking/`,
    fetchScheduledEvents: `${domain}live_events/offline_event/`,
    fetchZoomKey: `${domain}accounts/auth/profile/`,
    createEventNew: `${domain}live_events/live_event/new/`,

    //FOR FALABELLA ACTIVITY FLOW
    fetchCorporateCategories: `${domain}live_events/category/listing/`,
    fetchMethodologies: `${domain}live_events/methodology/listing/`,
    fetchVendorTypes: `${domain}live_events/vendortype/listing/`,
    getActivityCSV: `${domain}live_events/fevent/report/`,
    crateActivity: `${domain}live_events/fevent/create/`,
    fetchScheduledActivities: `${domain}live_events/fevent/listing/`,
    addAttndeesToActivity: `${domain}live_events/fevent/create/`,
    deleteActivity: `${domain}live_events/fevent/create/`,
    updateActivity: `${domain}live_events/fevent/create/`
  },
  //FOR EXTERNAL Assessments
  externalAssessments: {
    fetchAssignmentsList: `${domain}external/assignments/listing/`, //FOR FETCHING THE ASSIGNMENTS LIST
  },
  certification: {
    getTemplateThumbnails: `${domain}live_events/template_info/`, //USED FOR NEW UX.         //FOR GETTING THE TEMPLATE THUMBNAILS LIST
    generateCertificate: `${domain}live_events/generate/certificates/`, //USED FOR NEW UX.         //FOR GENERATING THE CERTIFICATE TO EMPLOYEES
    fetchMyCertificates: `${domain}live_events/user/certificates/`, //USED FOR NEW UX.         //FOR FETCHING MY CERTIFICATES
    downloadCertificate: `${domain}live_events/mycertificates/` //USED FOR NEW UX.         //FOR DOWNLOADING SINGLE CERTIFICATE
  },
  clientsDashboard: {
    fetchClientsReport: `${domain}clientmanagement/client/`,
    addClient: `${domain}clientmanagement/client/`,
    fetchClientDetail: `${domain}clientmanagement/client/`,
    fetchClientGroups: `${domain}clientmanagement/clientgroup/`,
    addGroup: `${domain}clientmanagement/clientgroup/`,
    updateGroup: `${domain}clientmanagement/clientgroup/`,
    fetchGroupLearners: `${domain}clientmanagement/learner/`,
    addLearner: `${domain}clientmanagement/learner/`,
    removeLearner: `${domain}clientmanagement/learner/`,
    fetchAllCoupons: `${domain}clientmanagement/coupons/`,
    addCoupon: `${domain}clientmanagement/coupons/`,
    fetchCouponInfo: `${domain}clientmanagement/coupons/`,
    uploadXapiCourse: `${domain}hosting/xapicourse/`,
    uploadScormCourse: `${domain}hosting/scormcourse/`,
    fetchCouponCourses: `${domain}clientmanagement/coupons/course/`,
    removeCoursesFromCoupon: `${domain}clientmanagement/coupons/course/`
  },


  //-------------------------------------------NEWLY ADDED APIS PENDING BACKEND
  //NEW UX DESIGN IMPLEMENTATION
  tenantConfigs: {
    fetchDepartments: `${domain}docs/deptlist/`, //USED FOR NEW UX
    fetchOrgDesignations: `${domain}hosting/desinations/view/`, //FOR FETCHING ORG DESIGNATIONS
    getNewRefreshToken: `${domain}user/token/refresh/`,//USED FOR NEW UX  //FOR GETTING THE NEW REFRESH AND ACCESS TOKEN ????DONE
    getTenantConfig: `${domain}profile/tenant/configs/`,//USED FOR NEW UX  //FOR TENANT CONFIGS ?????DONE
    getCourseProviderLogos: `${domain}courses/provider/`,//USED FOR NEW UX  //FOR GETTING THE COURSE PROVIDER IMAGES
    getCourseCategories: `${domain}hosting/category/`,//USED FOR NEW UX //FOR FETCHING THE COURSE CATEGORIES LIST
    fetchCourseTags: `${domain}hosting/skill/`, //USED FOR NEW UX //FOR FETCHING THE COURSE TAGS LIST
    getLoginBannerContent: `${domain}course/banner/login/`, //FOR LOGIN PAGE DATA

  },
  landingPage: {
    landingPageContinueCrs: `${domain}course/mandatory/landing/`, //FOR CONTINUE COURSES
    landingPageMandatoryCrs: `${domain}course/mandatory/landing/`, //FOR MANDATORY COURSES
    landingPageRecommendedCrs: `${domain}course/learning/view/landing/`, //FOR REOCOMMENDED SHORT COURSES
    landingPagePopularCourses: `${domain}course/track/landing/`, //FOR COLLEAGUE WATCHED
    landingPageRecentlyUpdatedCrs: `${domain}course/updates/recent/`, //FOR BECAUSE YOU VIEWD COURSES
    landingPagePeerRecommendations: `${domain}course/recommend/landing/`, //FOR COURSES YOU VIEWED
    getLandingPageBanners: `${domain}course/banner/landing/`, //FOR LANDINGPAGE BANNERS
  },

  //AUTHOR COURSES API CALLS
  authorCourses: {
    addCourse: {
      acceptedScormUploadFileFormats: ["zip", "tar.gz", "gz", "7z"], //FOR SCORM FILES
      uploadScormCourse: `${domain}hosting/scormcourse/`, //FOR UPLOAD SCORM
      uploadXapiCourse: `${domain}hosting/xapicourse/`, //FOR UPLOAD XAPI
      acceptedImageFileSize: 2097152, //IMAGE SIZE
      acceptedVideoFileSize: 629145600, //VIDEO SIZE
      acceptedTranScriptSize: 209715200, //TRANSCRIPT SIZE
      addCourseWelcome: `${domain}hosting/hostcourse/`, //START THE COURSE FORM
      updatingCourseWelcome: `${domain}hosting/editcourse/`, //UPDATE COURSE INFO
      courseDetailsfetch: `${domain}hosting/hostcourse/`, //COURSE DETAILS FETCH BASED ON CRS ID
      fetchCourseLeftMenu: `${domain}hosting/display/`, //COURSE LEFT MENU FETCH
    },
    modules: {
      initiateModule: `${domain}hosting/module/`,
      updateModuleInfo: `${domain}hosting/editmodule/`,
      fetchModuleDetails: `${domain}hosting/module/`,
      deleteUploadedReadings: `${domain}hosting/module/`,
      acceptedReadingFileSize: 11097152
    },
    lessons: {
      initiateLesson: `${domain}hosting/lesson/`,
      updateLessonInfo: `${domain}hosting/editlesson/`,
      fetchLessonDetails: `${domain}hosting/lesson/`,
      deleteUploadedReadings: `${domain}hosting/lesson/` //FOR DELETING THE UPLOADED READINGS
    },
    getCoursesListing: {
      getCreatedCourses: `${domain}hosting/course/listing/v2/`, //FOR GETTING THE PUBLISHED COURSES LIST
    },
    docsLibrary: {
      fetchDocsLibraryFiles: `${domain}docs/files/`, //FOR FETCHING THE DOCS LIBRARY DATA BASED ON QUERY PARAMS
      searchDocsLibraryFiles: `${domain}docs/search/` //FOR SEARCH QUERY ON ALL THE TABS
    },
    assessments: {
      listAssessments: `${domain}hosting/assessment/`,
      create: `${domain}hosting/assessment/`,
      fetchAssessment: `${domain}hosting/question/display/`,
      allQuestions: `${domain}hosting/assessment/display/`,
      update: `${domain}hosting/assessment/`,
      question: `${domain}hosting/assessment/question/`, //FOR ASSESSMENT QUESTIONS
      deleteAssessment: `${domain}hosting/assessment/delete/`,
      fetchAssessmentReport: `${domain}hosting/assessment/result/`,
      deleteAssessmentQuestion: `${domain}hosting/assessment/question/` //FOR DELETING THE ASSESSMENT QUESTION
    },
    assignments: {
      listAssignments: `${domain}assignment/collective/assignment/`,
      acceptedAttachmentFileSize: 11097152,
      fetchAssignment: `${domain}assignment/fetch/`,
      create: `${domain}assignment/create/`,
      update: `${domain}assignment/update/`,
      deleteAssignment: `${domain}assignment/delete/`,
      publishAssignment: `${domain}assignment/status/`,
      assignmentReport: `${domain}assignment/user/`,
      fetchUserReportSubmissions: `${domain}assignment/answer/fetch/`,
      submitGrade: `${domain}assignment/review/`,
    },
    plagiarism: {
      checkPlagiarism: `${domain}assignment/plagiarism/check/`,
    },
    discussions: {
      create: `${domain}discussion/create/`,
      listDiscussions: `${domain}discussion/view/`,
      publishDiscussion: `${domain}discussion/publish/`,
      deleteDiscussion: `${domain}discussion/delete/`,
      update: `${domain}discussion/update/`,
      fetchDiscussion: `${domain}discussion/fetch/`,
      postReply: `${domain}discussion/reply/`,
      postComment: `${domain}discussion/comment/`,
      submitGrade: `${domain}discussion/grading/`,
      updateGrade: `${domain}discussion/grading/update/`,
      respondedStudents: `${domain}discussion/panel/`,
      fetchStudentResponses: `${domain}discussion/user/reply/`,
      fetchResponses: `${domain}discussion/list/reply/paginate/`,
      fetchSubReplies: `${domain}discussion/list/comment/paginate/`,
      fetchPinnedResponses: `${domain}discussion/pin/listing/`,
      pinDiscussion: `${domain}discussion/pin/`
    },
    coursePublish: {
      deptPublishCourse: `${domain}hosting/course/scenario_one/v3/`, //NEW API BACKEND NEEDED
      designationPublishCourse: `${domain}hosting/course/scenario_two/v3/`, //NEW API BACKEND NEEDED
      individualPublishCourse: `${domain}hosting/course/scenario_three/v3/`, //NEW API BACKEND NEEDED
      newJoinersPublishCourse: `${domain}hosting/course/scenario_four/v3/`, //NEW API BACKEND NEEDED
      unPublishCourse: `${domain}hosting/course/unpublish/v3/`, //NEW API BACKEND NEEDED
    },
    courseReport: {
      normalCourseReport: `${domain}hosting/scorm/report/`,
      scormCourseReport: `${domain}hosting/scormcourse_count/`,
      scormFetchLearnerList: `${domain}hosting/scormcourse_count/`,
      xapiCourseReport: `${domain}hosting/xapicourse_count/`,
      xapiFetchLearnerList: `${domain}hosting/xapicourse_count/`,
      xapiUserActivityFetch: `${domain}hosting/xapi/allstatements/`
    },
    gradebook: {
      list: `${domain}assignment/gradebook/`,
      update: `${domain}assignment/gradebook/update/`,
      assessmentReport: `${domain}hosting/assessment/result/`,
      fetchStudentSubmissions: `${domain}assignment/gradebook/submission/`,
    },
  }
}

