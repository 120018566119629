import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { styled } from "@mui/material/styles";
import Chip from '@mui/material/Chip';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import FormControl from '@mui/material/FormControl';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//LOCAL IMPORTS
import i18n from '../../i18n/i18n';
import { styleDesigns } from '../../theme/styles';
import BasicCheckBox from '../Checkboxes/basicCheckbox';

const useStyles = makeStyles(theme => ({
  cssLabel: {
    fontSize: styleDesigns.textField._fontSize,
    fontFamily: `${styleDesigns.textField._fontFamily} !important`,
    color: `${styleDesigns.textField._borderColor}`,
    "&.Mui-focused": {
      color: `${styleDesigns.textField._borderColor}`
    }
  },
  designaioncssLabel: {
    fontSize: styleDesigns.textField._fontSize,
    fontFamily: `${styleDesigns.textField._fontFamily} !important`,
    color: '#707070',
    "&.Mui-focused": {
      color: '#707070'
    }
  },
  labelWrapper: {
    fontFamily: `${styleDesigns.textField._fontFamily} !important`,
    color: `${styleDesigns.textField._borderColor}`,
  },
  designLabelWrapper: {
    fontFamily: `${styleDesigns.textField._fontFamily} !important`,
    color: '#707070',
  },
  cntWrapper: {
    fontSize: '11px',
    fontFamily: styleDesigns.textField._fontFamily,
    color: '#999',
    fontWeight: 500,
    marginTop: '-2px'
  },
  popupIndicator: {
    color: styleDesigns.fontColour._myAthinaGreen,
    fontSize: '42px',
    marginTop: '-4px'
  },
  chipWrap: {
    border: '1px solid #ffffff',
    backgroundColor: styleDesigns.fontColour._appGreen,
    color: '#ffffff !important',
    fontFamily: styleDesigns.fontFamily._heading,
    margin: '8px 13px 10px',
    padding: '0px 13px',
    fontSize: '15px',
    height: '26px',
    paddingTop: '5px'
  },
  customOutline: {
    width: '100%',
    marginBottom: '15px',
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: '3px !important'
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderRadius: '3px !important'
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderRadius: '3px !important'
    },
    "&.MuiOutlinedInput-notchedOutline": {
      borderRadius: '3px !important'
    }
  },
  designationCustomOutline: {
    width: '100%',
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: '25px'
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderRadius: '25px'
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderRadius: '25px'
    },
    "&.MuiOutlinedInput-notchedOutline": {
      borderRadius: '25px'
    }
  },
}))

const StyledAutocomplete = styled(Autocomplete)({
  ".MuiAutocomplete-inputRoot": {
    color: styleDesigns.fontColour._myAthinaBlue,
    fontFamily: styleDesigns.textField._fontFamily,
    backgroundColor: styleDesigns.fontColour._white,
    borderRadius: '25px',
    "& .MuiInputAdornment-root": {
      color: styleDesigns.fontColour._myAthinaBlue,
      borderRadius: '25px',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: styleDesigns.fontColour._myAthinaBlue,
      borderRadius: '25px',
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: styleDesigns.fontColour._myAthinaBlue,
      borderRadius: '25px',
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: styleDesigns.fontColour._myAthinaBlue,
      borderRadius: '25px',
    }
  }
});

const DesignationsAutocomplete = styled(Autocomplete)({
  ".MuiAutocomplete-inputRoot": {
    color: '#707070',
    fontFamily: styleDesigns.textField._fontFamily,
    backgroundColor: styleDesigns.fontColour._white,
    "& .MuiInputAdornment-root": {
      color: '#cccccc'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: '#cccccc'
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: '#cccccc'
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: '#cccccc'
    }
  }
});

function AutoCompleteTeamBox(props) {
  //MULTIPLE FOR ALLOWING THE MULTIPLE TAGS      multiple
  //ID FOR UNIQUE IDENTITY                       id
  //OPTIONS FOR RENDERINGTHE DROPDOWN OPTIONS    options
  //REQUIRED FOR TEXT FIELD                      required
  //LABEL FOR TEXT FIELD LABEL                   label
  //ON CHANGE FOR TEAMS CHANGE                   onChange
  //LOADER FOR API LOADER                        loader
  //LOADING TEXT                                 loadingText
  //FOR CLOSING ON SELECT TEAM                   disableCloseOnSelect
  const classes = useStyles();
  const from = props && props.from ? props.from : '';

  const allSelected = Number(props.options && props.options.length) === Number(props.value && props.value.length + 1);
  return (
    <React.Fragment>
      {from === 'designationsSelect' ? (
        <FormControl
          size="small"
          classes={{ root: classes.designationCustomOutline }}
        >
          <DesignationsAutocomplete
            multiple={props.multiple}
            value={props && props.value && props.value}
            id={props.id}
            loading={props.loader}
            loadingText={i18n.t(props.loadingText)}
            options={props.options && props.options}
            disableCloseOnSelect={props.disableCloseOnSelect}
            getOptionLabel={(option) => option.name}
            onChange={(event, teamName, reason, detail) => {
              props.onChange(event, teamName, reason, detail);
            }}
            limitTags={6}
            popupIcon={<ArrowDropDownIcon className={classes.popupIndicator} />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  style={{
                    backgroundColor: styleDesigns.fontColour._appGreen,
                    color: '#ffffff',
                    fontFamily: styleDesigns.fontFamily._heading,
                    fontWeight: 'bold',
                    padding: '7px',
                    fontSize: '14px',
                    paddingTop: '10px'
                  }}
                  label={option.name}
                  {...getTagProps({ index })}
                  deleteIcon={<ClearRoundedIcon style={{ backgroundColor: '#ffffff', borderRadius: '21px', fontSize: '15px', marginTop: '-2px' }} />}
                />
              ))
            }
            renderOption={(props, option, { selected }) => (
              <React.Fragment key={props.id}>
                <li {...props}>
                  <BasicCheckBox
                    id={option && option.name === 'Select All' ? 'selectall' : props.id}
                    name={option && option.name === 'Select All' ? 'selectall' : props.id}
                    checked={allSelected ? true : selected}
                    from="designationBased"
                  />
                  <div className={classes.designLabelWrapper}>
                    {option && option.name && option.name}
                  </div>
                </li>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={i18n.t(props && props.label)}
                placeholder="Designations"
                error={props.error}
                required={props.required}
                InputLabelProps={{
                  classes: {
                    root: classes.designaioncssLabel
                  },
                }}
              />
            )}
          />
        </FormControl>
      ) : (
        <FormControl
          size="small"
          classes={{ root: classes.customOutline }}
        >
          <StyledAutocomplete
            multiple={props.multiple}
            value={props && props.value && props.value}
            id={props.id}
            loading={props.loader}
            loadingText={i18n.t(props.loadingText)}
            options={props.options && props.options}
            disableCloseOnSelect={props.disableCloseOnSelect}
            getOptionLabel={(option) => from === 'teamselect' ? option.name : option.email}
            onChange={(event, teamName, reason, detail) => {
              props.onChange(event, teamName, reason, detail);
            }}
            renderOption={(props, option, { selected }) => (
              <React.Fragment key={props.id}>
                {from === 'teamselect' ? (
                  <li {...props}>
                    <BasicCheckBox
                      id={props.id}
                      name={props.id}
                      checked={selected}
                    />
                    <React.Fragment>
                      <div className={classes.labelWrapper}>
                        {option && option.name && option.name}
                        <p className={classes.cntWrapper}>
                          {` (Members count - ${option.team_members && option.team_members.length}) `}
                        </p>
                      </div>
                    </React.Fragment>
                  </li>
                ) : (
                  <React.Fragment>
                    <li {...props}>
                      <div className={classes.labelWrapper}>
                        {option && option.email && option.email}
                      </div>
                    </li>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={i18n.t(props && props.label)}
                error={props.error}
                required={props.required}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel
                  },
                }}
              />
            )}
          />
        </FormControl>
      )}
    </React.Fragment>
  )
}

export default AutoCompleteTeamBox;