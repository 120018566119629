
import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
//LOCAL IMPORTS
import { getTenant } from '../../Configs/tenantConfig';
import { courseComparison } from '../../Configs/courseConfigs';
import CourseComparator from "../../Components/CourseComparator";
import { hasSearchAccess, isAdminModeActive } from '../../Helpers/basic';
import AdminSwitchView from './adminModeSwitch';

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: 'calc(100% - 73px)',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: 'white'
  },
  logoWrapper: {
    height: '60px',
  },
  ansrLogo: {
    height: '35px',
  },
  homeWrapp: {
    marginLeft: '-24px'
  },
  switchWrap: {
    marginLeft: 'auto'
  }
}));

export default function Header(props) {
  const classes = useStyles();
  const tenantInfo = getTenant();
  const navigate = useNavigate();
  const userSearchAccess = hasSearchAccess();
  const hasAdminAccess = false //isAdminModeActive();
  const [toOpenCourseComparator, setToOpenCourseComparator] = React.useState(false)

  const getCourseToCompare = () => {
    let courseToCompare = JSON.parse(localStorage.getItem('courseToCompare'))
    return courseToCompare ? courseToCompare : []
  }

  const getCourseCompareNotice = () => {
    const courseToCompare = getCourseToCompare()
    if (!courseToCompare.length) {
      return 'No courses to compare'
    } else {
      return courseToCompare.length === 1 ? `${courseToCompare.length} course to compare` : `${courseToCompare.length} courses to compare`
    }
  }
  const openCourseComparator = () => {
    setToOpenCourseComparator(true)
  }

  const onCloseCourseComparator = () => {
    setToOpenCourseComparator(false)
  }

  const onHomeBtnClick = () => {
    navigate('/landingpage')
  }

  return (
    <AppBar position="fixed" open={props.open} className={classes.header}>
      <Toolbar>
        {tenantInfo && tenantInfo.name && tenantInfo.name === 'PREP' ? null : (
          <React.Fragment>
            {userSearchAccess ? (
              <div className={classes.homeWrapp} >
                <IconButton size="small" aria-label='landing page navigation' onClick={() => { onHomeBtnClick() }}>
                  <img src={tenantInfo.logo} alt="client" className={tenantInfo.client === 'ansrsource' || tenantInfo.client === 'ansrsourcegems' ? classes.ansrLogo : classes.logoWrapper} />
                </IconButton>
              </div>
            ) : (
              <img src={tenantInfo.logo} alt="client" className={tenantInfo.client === 'ansrsource' || tenantInfo.client === 'ansrsourcegems' ? classes.ansrLogo : classes.logoWrapper} />
            )}
          </React.Fragment>
        )}
        {hasAdminAccess ? (
          <div className={classes.switchWrap}>
            <AdminSwitchView />
          </div>
        ) : null}
        {props.showCompare ? (
          <div id="courseCompareNotice" aria-label="Course comparator" style={{ display: getCourseToCompare().length ? 'block' : 'none' }} onClick={openCourseComparator}>
            {getCourseCompareNotice()}
          </div>
        ) : null}
      </Toolbar>
      {courseComparison.enabled && toOpenCourseComparator ? (
        <CourseComparator open={toOpenCourseComparator} onCloseCourseComparator={onCloseCourseComparator} />
      ) : null}
    </AppBar>
  )
}
