import React from 'react';
import { makeStyles } from '@mui/styles';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { openFreshworksWidget } from '../../Helpers/basic';
//LOCAL IMPORTS
import { styleDesigns } from '../../theme/styles';
import i18n from '../../i18n/i18n';
import { getTenant } from '../../Configs/tenantConfig';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: styleDesigns.fontColour._lightGrey,
    marginTop: '-11px',
    bottom: 0,
  },
  linkWrapper: {
    fontSize: '14px',
    backgroundColor: `${styleDesigns.fontColour._myAthinaBlue}`,
    textDecoration: 'underline',
    padding: '1px',
    width: '90px',
    borderRadius: '10px',
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    color: `${styleDesigns.fontColour._white} !important`,
  },
  pageFooter: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'center',
    paddingTop: '11px'
  },
  copyWrite: {
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    marginTop: '0px !important',
    fontSize: '14px'
  },
}));

export default function Footer(props) {
  const classes = useStyles();
  const tenantInfo = getTenant();

  const openFeedBack = (type) => {
    openFreshworksWidget(type)
  }

  return (
    <div className={classes.root}>
      <footer className={classes.pageFooter}>
        {tenantInfo.name !== 'PREP' ? (
          <React.Fragment>
            <Link component="button" onClick={() => { openFeedBack('help') }} className={classes.linkWrapper}>
              {i18n.t('Help')}
            </Link> &nbsp;&nbsp;&nbsp;
            <Link component="button" onClick={() => { openFeedBack('feedback') }} className={classes.linkWrapper}>
              {i18n.t('Feedback')}
            </Link>
          </React.Fragment>
        ) : null}
        <div>
          <Typography variant="body2" color="text.secondary" align="center" {...props} className={classes.copyWrite}>
            {i18n.t('Copyright ©')}
            {tenantInfo.name === 'PREP' ? i18n.t('Continual Engine') : i18n.t('myAthina')}
            {' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </div>
      </footer>
    </div>
  )
}