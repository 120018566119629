import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
//LOCLA IMPORTS
import { generateJwtToken, redirectTo, getActiveStepComponent } from '../../Helpers/basic';
import { getTenant } from '../../Configs/tenantConfig';
import { checkUserAuth } from '../../store/Auth/actionCreator';
import { ConfirmButton } from '../../Shared/Buttons/confirmBtn';
import { fetchStepperComponents } from '../../store/UserProfile/actionCreator';
import InlineAlerts from '../../Shared/AlertMessages/inlineAlerts';
import i18n from '../../i18n/i18n';

function SsoLogin(props) {
  const userToken = useParams();
  const tenantInfo = getTenant();
  const [errStatus, setErrStatus] = useState({
    status: false,
    errMessage: ''
  });
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (userToken && Object.keys(userToken) && Object.keys(userToken).length) {
      if (userToken && userToken.etaToken.split('eta-')[1] && userToken.etaToken.split('eta-')[1]) {
        onInitLogin();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //FOR CHECKING THE WELCOME PAGE DATA FILLING
  const componentsWelcomeSuccessCB = (res, userData) => {
    const stepperList = res && res.results && res.results.component && res.results.component;
    let stepsArrayList = []
    Object.entries(stepperList).forEach(([key, value]) => {
      stepsArrayList.push(value && value.key)
    });
    const filledData = res && res.results && res.results.user_data && res.results.user_data;
    const defaultStep = getActiveStepComponent(stepsArrayList, filledData);
    if (defaultStep === 'allFilled' || defaultStep === 'finish') {
      if (localStorage.getItem('mailRedirectRoute')) {
        const path = localStorage.getItem('mailRedirectRoute')
        window.location = `/${path}`
        localStorage.removeItem('mailRedirectRoute')
      } else {
        window.location = userData.defaultLandingPage
      }
    } else {
      window.location = '/welcome'
    }
  }
  const componentsWelcomeFailureCB = (err, userData) => {
    window.location = userData.defaultLandingPage
  }

  //on init load profile call backs
  const successCallBack = res => {
    setErrStatus({
      status: false,
      errMessage: ''
    })
    setInProgress(false);

    let myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 1);

    //FOR SETTING UP THE USER_ACTIVE_MENUS
    if (res && res.active_menus && res.active_menus.length) {
      let menus = JSON.stringify(res.active_menus)
      const parsedLeftMenus = generateJwtToken(menus)
      localStorage.setItem('user_active_menus', parsedLeftMenus);
    } else {
      localStorage.setItem('user_active_menus', [])
    }

    document.cookie = tenantInfo.domainTokenLabel + "=" + res.token + ";path=/;expires=" + myDate + ";domain=" + tenantInfo.domainName;
    const user_token = generateJwtToken(res.user)
    myDate.setMonth(myDate.getMonth() + 1);
    document.cookie = tenantInfo.domainUserLabel + "=" + user_token + ";path=/;expires=" + myDate + ";domain=" + tenantInfo.domainName;

    //FOR ACCESS AND REFRESH TOKENS
    if (tenantInfo.allowAccessRefreshToken) {
      document.cookie = tenantInfo.domainUserAccessToken + "=" + res.access + ";path=/;expires=" + myDate + ";domain=" + tenantInfo.domainName + ";secure";
      document.cookie = tenantInfo.domainUserRefreshToken + "=" + res.refresh + ";path=/;expires=" + myDate + ";domain=" + tenantInfo.domainName + ";secure";
    }

    if (tenantInfo && tenantInfo.show_welcomepage) {
      const payload = {
        user_id: res && res.user && res.user.id
      }
      if (localStorage.getItem('onLoginSuccess')) {
        const openUrl = localStorage.getItem('onLoginSuccess')
        redirectTo(localStorage.getItem('onLoginSuccess'), true)
        localStorage.removeItem('onLoginSuccess')
        redirectTo(openUrl, true)
      }
      fetchStepperComponents(payload, componentsWelcomeSuccessCB, componentsWelcomeFailureCB, res.user)
    } else {
      if (localStorage.getItem('mailRedirectRoute')) {
        const path = localStorage.getItem('mailRedirectRoute');
        window.location = `/${path}`
        localStorage.removeItem('mailRedirectRoute')
      } else {
        window.location = res && res.user && res.user.defaultLandingPage;
      }
    }
  }

  const failureCallBack = err => {
    setInProgress(false);
    setErrStatus({
      status: true,
      errMessage: 'You alomost done. But, few informations are yet to filled. Please contact your administrator for further details.'
    })
  }

  const onInitLogin = () => {
    setInProgress(true);
    setErrStatus({
      status: false,
      errMessage: ''
    })
    const payload = {
      etaToken: userToken && userToken.etaToken.split('eta-')[1] && userToken.etaToken.split('eta-')[1]
    }
    props.checkUserAuth(payload, successCallBack, failureCallBack)
  }

  const onLogin = () => {
    //window.location = `https://us-central1-sso-b2bformany.cloudfunctions.net/app/login?tenant=${tenantInfo.tenant}`
    window.location = `https://us-central1-sso-myathina-function.cloudfunctions.net/app/login?tenant=${tenantInfo.tenant}`
  }

  return (
    <React.Fragment>
      {errStatus.status ? (
        <InlineAlerts status={errStatus.status ? 'error' : 'success'} message={errStatus.errMessage} />
      ) : null}
      <ConfirmButton
        id="ssosignin"
        ariaLabel="Sign In"
        label={i18n.t("Sign In")}
        onBtnClick={onLogin}
        disabled={inProgress}
      />
    </React.Fragment >
  )
}

const mapStateToProps = state => {
  return {
    authDetails: state.authReducer.authDetails,
  }
}
export default connect(
  mapStateToProps, { checkUserAuth }
)(SsoLogin);