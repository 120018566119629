import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import clsx from 'clsx';
//LOCAL IMPORTS
import { styleDesigns } from '../../theme/styles';

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
  info: ErrorIcon,
  warning: ErrorIcon
};

const useStyles = makeStyles(theme => ({
  info: {
    color: `${styleDesigns.fontColour._myAthinaBlue} !important`,
    borderRadius: '8px',
    padding: '6px 16px',
    boxShadow: 'none !important',
    backgroundImage: 'none',
    lineHeight: 1.57143,
    fontSize: `${styleDesigns.fontSize._infoHeading} !important`,
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    fontWeight: `${styleDesigns.fontWeight._infoHeading} !important`,
    backgroundColor: `${styleDesigns.fontColour._infoColor} !important`,
    marginBottom: '15px',
    marginTop: '10px',
    display: 'flex'
  },
  success: {
    color: `${styleDesigns.fontColour._myAthinaGreen} !important`,
    borderRadius: '8px',
    padding: '6px 16px',
    boxShadow: 'none !important',
    backgroundImage: 'none',
    lineHeight: 1.57143,
    fontSize: `${styleDesigns.fontSize._infoHeading} !important`,
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    fontWeight: `${styleDesigns.fontWeight._infoHeading} !important`,
    backgroundColor: `${styleDesigns.fontColour._successColor} !important`,
    marginBottom: '15px',
    marginTop: '10px',
    display: 'flex'
  },
  error: {
    color: `${styleDesigns.fontColour._red} !important`,
    borderRadius: '8px',
    padding: '6px 16px',
    boxShadow: 'none !important',
    backgroundImage: 'none',
    lineHeight: 1.57143,
    fontSize: `${styleDesigns.fontSize._infoHeading} !important`,
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    fontWeight: `${styleDesigns.fontWeight._infoHeading} !important`,
    backgroundColor: `${styleDesigns.fontColour._errorColor} !important`,
    marginBottom: '15px',
    marginTop: '10px',
    display: 'flex'
  },
  warning: {
    color: `${styleDesigns.fontColour._orange} !important`,
    borderRadius: '8px',
    padding: '6px 16px',
    boxShadow: 'none !important',
    backgroundImage: 'none',
    lineHeight: 1.57143,
    fontSize: `${styleDesigns.fontSize._infoHeading} !important`,
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    fontWeight: `${styleDesigns.fontWeight._infoHeading} !important`,
    backgroundColor: `${styleDesigns.fontColour._warningColor} !important`,
    marginBottom: '15px',
    marginTop: '10px',
    display: 'flex'
  },
  icon: {
    fontSize: '22px !important',
    verticalAlign: '-5px',
    marginRight: '12px !important',
    display: 'flex',
    opacity: '0.9',
    marginTop: '8px'
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: '10px',
  },
  textWrapper: {
    padding: '4px 0px',
    marginTop: '6px !important'
  }
}));

export default function InlineAlerts(props) {
  //FOR THE INLINE ALERTS
  //STATUS WILL DEFINE WHAT TYPE OF ALERT BOXES YOU NEED
  //BASED ON THE STATUS ABOVE CSS WILL BE APPLIED
  //MESSAGE IS FOR THE ALERT MESSAGE
  const classes = useStyles();
  const Icon = variantIcon[props.status];

  return (
    <Paper className={clsx(classes[props.status])}>
      <Icon className={clsx(classes.icon, classes.iconVariant)} />
      <Typography variant="caption" display="block" gutterBottom className={classes.textWrapper}>
        {props.message ? props.message : 'Sorry! Something went wrong. Please try again.'}
      </Typography>
    </Paper>
  )
}