import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
//LOCAL IMPORTS
import { getLoggedInStatus, getUrlParams } from '../src/Helpers/basic';

//FOR CHECKING THE MAIL REDIRECTIONS AFTER LOGIN
if (!getLoggedInStatus()) {
  const url = getUrlParams(window.location.href, 'type')
  if (url && url.length) {
    localStorage.setItem('mailRedirectRoute', url)
  }
}

export const PrivateRoute = () => {
  return getLoggedInStatus() ? <Outlet /> : <Navigate to='/' />
}