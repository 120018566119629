import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from '@mui/styles';
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ReactStars from "react-rating-stars-component";
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useMediaQuery from '@mui/material/useMediaQuery';
//Local Imports
import { showSnackBar } from '../../../store/AlertMessages/actionCreator';
import { closeRecommendDialog } from '../../../store/RecommendCourse/actionCreator';
import { recommendTo } from "../../../store/CourseDetails/actionCreator";
import { articlesRecommend } from '../../../store/Search/actionCreator';
import { fetchOrgMembersList, fetchTeamsList } from "../../../store/Members/actionCreator";
import { getUserDetails, getUserType, convertUTCTimeZone } from '../../../Helpers/basic';
import AthinaCover from '../../../assests/myAthinaCover.png';
import { getTenant } from "../../../Configs/tenantConfig";
import AutoCompleteTeamBox from '../../../Shared/InputFields/autoCompleteTeam';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#fff",
  },
  recommendationWrapper: {
    backgroundColor: "#f5f5f5",
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    padding: "10px 20px",
  },
  recommendationTitle: {
    textAlign: "left",
    color: "#666",
    marginBottom: 15,
  },
  chipWrapper: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 13,
    padding: 20,
    borderRadius: "14px",
    border: "1px solid",
  },
  chipColor: {
    color: "green",
  },
  chip: {
    margin: "0.3rem 0 0 0",
  },
  selCntr: {
    justifyContent: "center",
  },
  membersCntr: {
    maxHeight: "200px",
    overflowY: 'scroll'
  },

  teamsCntr: {
    border: "1px solid #999",
    maxHeight: "400px",
  },
  teamWrapper: {
    margin: "0.5rem",
  },
  teamName: {
    fontSize: "1rem",
    color: "#15528d",
  },
  selMemChip: {
    color: "#2e8eec",
    marginTop: "0.3rem",
    border: "1px solid #2e8eec",
  },
  disabledChip: {
    color: "#bdbdbd",
    border: "1px solid #bdbdbd",
    marginTop: "0.3rem"
  },
  recommendLabel: {
    fontSize: "1rem",
    marginTop: "0.2rem",
  },
  courseName: {
    fontSize: "1.2rem",
    color: "#2e8eec",
  },
  labelCntr: {
    display: "flex",
    margin: "1rem",
  },
  memberListAutoSuggest: {
    color: "#2e8eec",
  },
  memCtr: {
    margin: "1rem",
  },
  itemLabel: {
    display: 'flex',
    marginTop: '0.3rem',
    fontSize: '1rem',
    color: '#414541'
  },
  itemValue: {
    color: "#15528d",
    fontSize: '1rem',
    margin: 0
  },
  appBar: {
    position: 'relative',
  },
  media: {
    height: 140,
    width: '100%',
    backgroundColor: '#ccc'
  },
  courseDesc: {
    margin: '0.5rem',
    fontSize: '1rem',
    color: '#3f3c3c'
  },
  webpageDesc: {
    margin: '0.5rem',
    fontSize: '1rem',
    color: '#3f3c3c',
    marginLeft: '1px'
  },
  ratingWrapper: {
    display: 'flex',
    marginTop: '0.2rem'
  },
  ratingStar: {
    marginTop: '0.2rem'
  },
  autoCompWrapper: {
    marginTop: '1rem'
  },
  buttonWrapper: {
    marginTop: '1rem',
    textAlign: 'center'
  },
  button: {
    marginLeft: '0.5rem',
    color: '#fff',
    backgroundColor: '#2e8eec',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#2e8eec',
    }

  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  datePicker: {
    border: '1px solid',
    borderRadius: '5px',
    padding: '16px',
    marginTop: '10px'
  },
  proceedWrapper: {
    marginLeft: '0.5rem',
    marginTop: '25px',
    textTransform: 'none'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function RecommendCourse(props) {
  const classes = useStyles();
  const courseInfo = props.details && props.details.courseInfo;
  const viewMode = props.details && props.details.courseInfo && props.details.courseInfo.viewMode ? props.details.courseInfo.viewMode : 'courses'
  const [membersList, setMembersList] = useState([])
  const [teamList, setTeamList] = useState([])
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [selectedTeamMembersList, setSelectedTeamMembersList] = useState([]);
  const [nullCheck, setNullCheck] = useState(false);
  const prevStateRef = useRef();
  const [radioValue, setRadioValue] = React.useState('recommend');
  const [mandateDueDate, setMandateDueDate] = React.useState('')
  const [alreadyRecList, setAlreadyRecList] = React.useState([])
  const [alreadyRecIds, setAlreadyRecIds] = React.useState([])
  const [alreadyRecUsersList, setAlreadyRecUsersList] = React.useState([])
  const [showErrorMsg, setShowErrorMsg] = React.useState(false)

  const [recUniqueCount, setRecUniqueCount] = React.useState('')
  const [memberCount, setMembersCount] = React.useState('')
  const [selectedTeamMemCount, setSelectedTeamMemCount] = React.useState('')
  const [teamsDropdownLoader, setTeamsDropdownLoader] = React.useState(true);
  const [singleDropdownLoader, setSingleDropdownLoader] = React.useState(true);

  useEffect(() => {
    prevStateRef.current = props.details.dialogOpen;
  });

  //FOR FETCHING RECOMMEND and MANDATE DATA FOR TEAMS
  const fetchTeamsData = (value) => {
    setTeamsDropdownLoader(true);
    const payLoad = {
      reporting_members: value
    }
    fetchTeamsList(
      payLoad,
      (res) => {
        setTeamList(res && res.data && res.data)
        setTeamsDropdownLoader(false);
      },
      (error) => {
        setTeamList([])
        setTeamsDropdownLoader(false);
      }
    );
  };

  //FOR FETCHING RECOMMEND DATA FOR INDIVIDUAL
  const fetchMembersData = (value) => {
    setSingleDropdownLoader(true);
    const payLoad = {
      reporting_members: value
    }
    fetchOrgMembersList(
      payLoad,
      (res) => {
        setSingleDropdownLoader(false);
        setMembersList(res && res.results && res.results)
      },
      (error) => {
        setSingleDropdownLoader(false);
        setMembersList([])
      }
    );
  };

  //FOR FETCHING MANDATE DATA FOR INDIVIDUAL
  const fetchMembersMandateData = (value) => {
    const payLoad = {
      reporting_members: value
    }
    fetchOrgMembersList(
      payLoad,
      (res) => {
        let membersListArr = []
        if (res.results.length > 0) {
          res.results.forEach((item) => {
            membersListArr.push(item)
          });
        }
        if (membersListArr && membersListArr.length > 0) {
          let dataMembers = membersListArr
          let memArr = [...membersListArr]
          function getData(data) {
            data.forEach(function (item) {
              (item.children).forEach(function (child) {
                memArr.push(child)
              })
              getData(item.children)
            })
          }
          getData(dataMembers)
          setMembersList(memArr)
        }
        //setMembersList(membersListArr)
      },
      (error) => {
        setMembersList([])
      }
    );
  };

  const prevStateVal = prevStateRef.current;
  useEffect(() => {
    if (prevStateVal !== props.details.dialogOpen) {
      if (props.details.dialogOpen) {
        fetchTeamsData(false);
        fetchMembersData(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.details.dialogOpen])

  const handleChange = (event) => {
    setAlreadyRecList([])
    setShowErrorMsg(false)
    setRadioValue(event.target.value);
    setSelectedMembers([]);
    setSelectedTeamMembersList([]);
    setSelectedEmp([]);
    setSelectedTeam([]);
    setNullCheck(false)
    setMandateDueDate('')
    setTeamList([])
    setMembersList([])
    setMembersCount(0)
    setSelectedTeamMemCount(0)
    setRecUniqueCount(0)
    if (event.target.value && event.target.value === 'mandate') {
      if (getUserType() === 'admin') {
        fetchTeamsData(false);
        fetchMembersData(false)
      } else {
        fetchTeamsData(true);
        fetchMembersMandateData(true)
      }
    } else {
      fetchTeamsData(false);
      fetchMembersData(false)
    }
  };


  const handleDialogClose = () => {
    setShowErrorMsg(false)
    setSelectedMembers([])
    setSelectedTeam([])
    setSelectedEmp([]);
    setTeamList([]);
    setMembersList([]);
    setSelectedTeamMembersList([]);
    setRadioValue('recommend')
    setNullCheck(false)
    setMandateDueDate('')
    props.closeRecommendDialog()
    setAlreadyRecList([])
    setMembersCount(0)
    setSelectedTeamMemCount(0)
    setRecUniqueCount(0)
  };

  const successCB = (res) => {
    setShowErrorMsg(false)
    setInProgress(false);
    setAlreadyRecList([])
    props.closeRecommendDialog()
    setSelectedMembers([]);
    setSelectedTeamMembersList([]);
    setSelectedEmp([]);
    setSelectedTeam([]);
    setTeamList([]);
    setMembersList([]);
    setRadioValue('recommend')
    setNullCheck(false)
    setMandateDueDate('')
    setMembersCount(0)
    setSelectedTeamMemCount(0)
    setRecUniqueCount(0)
    props.showSnackBar(
      {
        state: true,
        message: radioValue === 'mandate' ? 'Mandated Successfully!' : 'Recommended Successfully!',
        type: 'success'
      }
    )
  };

  const failureCB = (res) => {
    setNullCheck(false)
    let statusCode = res.response && res.response.status && res.response.status
    setInProgress(false);
    if (statusCode === 400) {
      let recommendedList = []
      if (radioValue === 'recommend') {
        recommendedList = res.response && res.response.data && res.response.data.user_list && res.response.data.user_list.length > 0 && res.response.data.user_list
      } else {
        let mandateData = res.response && res.response.data && res.response.data.user_list && res.response.data.user_list.length > 0 && res.response.data.user_list
        mandateData && mandateData.length && mandateData.forEach((data, index) => {
          if (data && data.is_mandate === true) {
            recommendedList.push(data)
          }
          if (data && data.is_mandate === false) {
            if ((data && data.status && data.status === "completed") || (data && data.status && data.status === "in-progress") || (data && data.status && data.status === "ongoing") || (data && data.status && data.status === "pending-approval")) {
              recommendedList.push(data)
            }
          }
        })
      }
      let recArray = []
      let recIdArray = []
      recommendedList && recommendedList.length && recommendedList.forEach((data) => {
        recArray.push(data && data.email && data.email)
        recIdArray.push(data && data.user_id && data.user_id)
      })

      let membersArr = [];
      if (selectedEmp.length > 0) {
        selectedEmp.forEach((emp, index) => {
          membersArr.push(emp.id);
        });
      }
      if (selectedTeamMembersList.length > 0) {
        selectedTeamMembersList.forEach((item, index) => {
          let mem = item.team_members;
          if (mem.length > 0) {
            mem.forEach((data, index) => {
              membersArr.push(data.id);
            });
          }
        });
      }
      let duplicates = membersArr.filter((item => count => item.has(count) || !item.add(count))(new Set));

      if (duplicates && duplicates.length) {
        let newCount = duplicates.length + recommendedList.length
        setRecUniqueCount(newCount)
      } else {
        setRecUniqueCount(recommendedList && recommendedList.length)
      }
      setAlreadyRecUsersList(recommendedList)
      setAlreadyRecIds(recIdArray)
      setAlreadyRecList(recArray)
      setShowErrorMsg(true)
    }
    else {
      setShowErrorMsg(false)
      setAlreadyRecList([])
      setSelectedMembers([]);
      setSelectedTeamMembersList([]);
      setSelectedEmp([]);
      setSelectedTeam([]);
      setTeamList([]);
      setMembersList([]);
      setRadioValue('recommend')
      setNullCheck(false)
      setMandateDueDate('')
      props.showSnackBar(
        {
          state: true,
          message: 'Something Went Wrong. Try again Later!',
          type: 'error'
        }
      )
    }
  };

  const onSubmitRecommendCourse = () => {
    let membersArr = [];
    if (selectedEmp.length > 0) {
      selectedEmp.forEach((emp, index) => {
        membersArr.push(emp.id);
      });
    }
    if (selectedTeamMembersList.length > 0) {
      selectedTeamMembersList.forEach((item, index) => {
        let mem = item.team_members;
        if (mem.length > 0) {
          mem.forEach((data, index) => {
            membersArr.push(data.id);
          });
        }
      });
    }
    let payloadArr = membersArr.filter((v, i) => membersArr.indexOf(v) === i)
    if (payloadArr.length > 0) {
      setInProgress(true);
      let payLoad = ''
      if (viewMode === 'courses') {
        payLoad = {
          course_type: radioValue === 'mandate' ? 'mandate' : 'recommend',
          course_location: (courseInfo && courseInfo.course_location === 'internal') || (courseInfo && courseInfo.published) || (courseInfo.skill_tags && courseInfo.skill_tags.length) ? 'internal' : 'external',
          due_date_mandate: radioValue === 'mandate' ? mandateDueDate : null,
          user_id: payloadArr,
          course_id: courseInfo.course_id ? courseInfo.course_id : courseInfo.id,
        };
        recommendTo(payLoad, successCB, failureCB);
      }
      else if (viewMode === 'article' || viewMode === 'video' || viewMode === 'webpage') {
        payLoad = {
          type: viewMode,
          data: courseInfo,
          user_id: payloadArr,
        }
        articlesRecommend(payLoad, successCB, failureCB)
      }
    }
    else {
      setNullCheck(true)
    }
  };

  const onSubmitProceedRecommend = () => {
    setMembersCount(0)
    setSelectedTeamMemCount(0)
    setRecUniqueCount(0)
    setShowErrorMsg(false)
    let membersArr = [];
    if (selectedEmp.length > 0) {
      selectedEmp.forEach((emp, index) => {
        membersArr.push(emp.id);
      });
    }
    if (selectedTeamMembersList.length > 0) {
      selectedTeamMembersList.forEach((item, index) => {
        let mem = item.team_members;
        if (mem.length > 0) {
          mem.forEach((data, index) => {
            membersArr.push(data.id);
          });
        }
      });
    }
    let payloadArr = membersArr.filter((v, i) => membersArr.indexOf(v) === i)
    let filteredRecArr = []
    payloadArr && payloadArr.length && payloadArr.forEach((data, index) => {
      if ((alreadyRecIds && alreadyRecIds.length && alreadyRecIds.indexOf(data) > -1) === false) {
        filteredRecArr.push(data)
      }
    })
    if (filteredRecArr.length > 0) {
      setInProgress(true);
      let payLoad = ''
      if (viewMode === 'courses') {
        payLoad = {
          course_type: radioValue === 'mandate' ? 'mandate' : 'recommend',
          course_location: (courseInfo && courseInfo.course_location === 'internal') || (courseInfo && courseInfo.published) || (courseInfo.skill_tags && courseInfo.skill_tags.length) ? 'internal' : 'external',
          due_date_mandate: radioValue === 'mandate' ? mandateDueDate : null,
          user_id: filteredRecArr,
          course_id: courseInfo.course_id ? courseInfo.course_id : courseInfo.id,
        }
        recommendTo(payLoad, successCB, failureCB)
      }
      else if (viewMode === 'article' || viewMode === 'video' || viewMode === 'webpage') {
        payLoad = {
          type: viewMode,
          data: courseInfo,
          user_id: payloadArr,
        }
        articlesRecommend(payLoad, successCB, failureCB)
      }
    }
    else {
      setSelectedEmp([])
      setSelectedTeamMembersList([])
      setAlreadyRecList([])
      setSelectedMembers([])
      setSelectedTeam([])
      setNullCheck(true)
    }
  }

  const handleDelete = (memberToDelete) => () => {
    let alreadyRecName = alreadyRecList && alreadyRecList.indexOf(memberToDelete.email) > -1
    if (alreadyRecName) {
      let newCount = recUniqueCount - 1
      setRecUniqueCount(newCount)
    }
    setShowErrorMsg(false)
    let selectedemployees = selectedEmp;
    let selectedChipUsers = selectedMembers;
    let newData = selectedemployees.filter(
      (member) => member.id !== memberToDelete.id
    );
    let chipUsers = selectedChipUsers.filter(
      (member) => member.id !== memberToDelete.id
    );
    setSelectedMembers(chipUsers)
    setSelectedEmp(newData);
    setMembersCount(newData.length)
  };

  const handleAddMembers = (members) => {
    setAlreadyRecList([])
    setMembersCount(members.length)
    setNullCheck(false)
    setShowErrorMsg(false)
    setSelectedMembers(members);
    let oldEmp = selectedEmp;
    if (members.length > 0) {
      members.forEach((item, index) => {
        if (oldEmp.length > 0) {
          let existingNode = oldEmp.find((member) => member.id === item.id);
          if (!existingNode) {
            oldEmp.push(item);
          }
        } else {
          oldEmp.push(item);
        }
      });
    }
    setSelectedEmp(oldEmp);
  };

  const handleTeamMemberDelete = (teamMemberToDelete) => {
    let alreadyRecName = alreadyRecList && alreadyRecList.indexOf(teamMemberToDelete.email) > -1
    if (alreadyRecName) {
      let newCount = recUniqueCount - 1
      setRecUniqueCount(newCount)
    }
    setShowErrorMsg(false)
    let selectedMemList = [...selectedTeamMembersList];
    if (selectedMemList.length > 0) {
      selectedMemList.forEach((item, index) => {
        let removedMemList = [];
        let teamObj = item.team_members.find(
          (member) => member.name === teamMemberToDelete.name
        );
        if (teamObj) {
          removedMemList = item.team_members.filter(
            (member) => member.id !== teamMemberToDelete.id
          );
          item["team_members"] = removedMemList;
        }
      });
      let newMemCount = 0
      selectedMemList && selectedMemList.length && selectedMemList.forEach((item, index) => {
        newMemCount = newMemCount + item.team_members.length
        setSelectedTeamMemCount(newMemCount)
      })
      setSelectedTeamMembersList(selectedMemList);
    }
  };

  const handleSelectTeam = (event, teamName, actionType, actionChipData) => {
    setAlreadyRecList([])
    setNullCheck(false)
    setShowErrorMsg(false)
    setSelectedTeam(teamName);
    if (actionType && (actionType === 'remove-option' || actionType === 'removeOption')) {
      let removedData = actionChipData && actionChipData.option && actionChipData.option
      if (removedData && Object.keys(removedData) && Object.keys(removedData).length) {
        let teamInfo = [...selectedTeamMembersList];
        teamInfo.forEach(function (team) {
          if (removedData.name === team.team) {
            team.team_members = []
          }
        });
      }
    }
    //FOR APPENDING THE ADDED TEAM CHIP TO SELECTED TABLE DATA
    let arr = []
    if (teamName && teamName.length) {
      teamName.forEach((item) => {
        let teamObj = { id: item.id, team: item.name, team_members: item.team_members }
        arr.push(teamObj)
      })
    }
    //FOR CHECKING THE UNIQUE TEAMS AND SET TO SELECTED TEAMS
    let teamData = [...selectedTeamMembersList, ...arr];
    const uniqueData = [...teamData.reduce((map, obj) => map.set(obj.team, obj), new Map()).values()];

    setSelectedTeamMembersList(uniqueData);
    setSelectedTeamMemCount(uniqueData.length)
  };

  const renderTeamListChip = (teamsList) => {
    if (teamsList.length > 0) {
      let arr = [];
      teamsList.forEach((item, index) => {
        let label = item.name + " - " + item.email;
        let alreadyRecName = alreadyRecList && alreadyRecList.indexOf(item.email) > -1
        let userData = alreadyRecUsersList && alreadyRecUsersList.find((data) => data.email === item.email);
        let labelName =
          label.length > 40 ? label.substring(0, 35) + "..." : label;
        arr.push(
          <React.Fragment key={index}>
            <Chip
              variant="outlined"
              label={labelName}
              deleteIcon={<HighlightOffIcon style={{ color: '#2e8eec' }} />}
              onDelete={() => {
                handleTeamMemberDelete(item);
              }}
              className={!alreadyRecName ? classes.selMemChip : classes.disabledChip}
            />
            {alreadyRecName ? <span style={{ marginLeft: '15px', color: '#9e9e9e', fontSize: '12px', letterSpacing: '1px' }}>{radioValue === 'recommend' ? (userData && userData.status && userData.status) : (userData && userData.is_mandate === true ? 'Already Mandated' : (userData && userData.status && userData.status))}</span> : null}
            <br />
          </React.Fragment>
        );
      });
      return arr;
    }
  };

  let mandateBtn = false
  if (inProgress) {
    mandateBtn = true
  } else if (!mandateDueDate) {
    mandateBtn = true
  }

  const getDescription = (desc) => {
    const textDesc = desc && desc.length && desc.replace(/(?:&nbsp;|<[^>]*>?)/gm, '')
    if (textDesc && textDesc.length) {
      if (textDesc.length > 400) {
        return (
          textDesc.substring(0, 350) + '...'
        )
      } else {
        return (
          textDesc
        )
      }
    }
  }

  const loadAlterImage = evnt => {
    evnt.target.src = courseInfo.provider && courseInfo.provider_logo ? courseInfo.provider_logo : AthinaCover
  }

  const recTotalUsersCount = selectedTeamMemCount + memberCount
  const requireRecCount = recTotalUsersCount - recUniqueCount
  const maxWidth700 = useMediaQuery('(max-width:700px)');
  return (
    <React.Fragment>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={props.details.dialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
        aria-label="Recommend"
      >
        <DialogTitle id="customized-dialog-title"><div className={classes.dialogTitle} ><div>{`Recommend ${viewMode}`} </div><div>  <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton></div></div></DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              {(courseInfo && courseInfo.skill_tags && courseInfo.skill_tags.length) || (courseInfo && courseInfo.course_location && courseInfo.course_location === 'internal') || (courseInfo && courseInfo.published) || (courseInfo && courseInfo.status && courseInfo.status === "yet-to-start") ? (
                <Paper elevation={3} className={classes.courseInfoCntr}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={5} lg={5}>
                      <img alt="courseImage" onError={loadAlterImage} src={courseInfo.banner_url} className={classes.media} />
                    </Grid>
                    <Grid item xs={12} sm={8} md={7} lg={7}>
                      <Typography className={classes.courseName} gutterBottom variant="h5" component="h2">
                        {courseInfo && courseInfo.title ? courseInfo.title : ''}
                      </Typography>
                      {courseInfo && courseInfo.provider ? (
                        <Typography variant="body2" color="textSecondary" component="div">
                          <div className={classes.itemLabel}>by:&nbsp;<p className={classes.itemValue}> {courseInfo && courseInfo.provider ? courseInfo.provider : getTenant().provider_name}</p></div>
                        </Typography>
                      ) : null}
                      {courseInfo && courseInfo.course_duration ? (
                        <Typography variant="body2" color="textSecondary" component="div">
                          <div className={classes.itemLabel}>Est Hours:&nbsp;<p className={classes.itemValue}> {courseInfo.course_duration}</p></div>
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                  {!maxWidth700 ? (
                    <Typography className={classes.courseDesc} variant="body2" color="textSecondary" component="p">
                      {courseInfo && courseInfo.desc && courseInfo.desc.length ? (
                        getDescription(courseInfo.desc)
                      ) : null}
                    </Typography>
                  ) : null}
                </Paper>
              ) : (
                <React.Fragment>
                  {viewMode === 'courses' ? (
                    <div className={classes.courseInfoCntr}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} md={5} lg={5}>
                          <img alt="courseImage" onError={loadAlterImage} src={courseInfo && courseInfo.img} className={classes.media} />
                        </Grid>
                        <Grid item xs={12} sm={8} md={7} lg={7}>
                          <Typography className={classes.courseName} gutterBottom variant="h5" component="h2">
                            {courseInfo && courseInfo.name}
                          </Typography>
                          {courseInfo && courseInfo.provider ? (
                            <Typography variant="body2" color="textSecondary" component="div">
                              <div className={classes.itemLabel}>by:&nbsp;<p className={classes.itemValue}> {courseInfo && courseInfo.provider && courseInfo.provider}</p></div>
                            </Typography>
                          ) : null}
                          {courseInfo && courseInfo.price ? (
                            <Typography variant="body2" color="textSecondary" component="div">
                              <div className={classes.itemLabel}>Price:&nbsp;<p className={classes.itemValue}> {courseInfo && courseInfo.price && courseInfo.price}</p></div>
                            </Typography>
                          ) : null}
                          {courseInfo && courseInfo.course_duration ? (
                            <Typography variant="body2" color="textSecondary" component="div">
                              <div className={classes.itemLabel}>Est Hours:&nbsp;<p className={classes.itemValue}> {courseInfo.course_duration}</p></div>
                            </Typography>
                          ) : null}
                          {courseInfo && courseInfo.ratings && courseInfo.ratings > 0 ? (
                            <React.Fragment>
                              <div id="main" tabIndex="-1" className="accessibility">
                                <span className={classes.label}>Ratings: {courseInfo.ratings ? courseInfo.ratings : 0}</span>
                              </div>
                              <div className={classes.ratingWrapper} aria-hidden="true">
                                <div className={classes.itemLabel}>Ratings: </div>
                                <ReactStars
                                  size={15}
                                  count={5}
                                  edit={false}
                                  color="#999"
                                  activeColor="#f6ba44"
                                  value={courseInfo && courseInfo.ratings ? courseInfo.ratings : 0}
                                  a11y={true}
                                  isHalf={true}
                                  emptyIcon={<i className="far fa-star" style={{ marginTop: '10px' }} />}
                                  halfIcon={<i className="fa fa-star-half-alt" style={{ marginTop: '10px' }} />}
                                  filledIcon={<i className="fa fa-star" style={{ marginTop: '10px' }} />}
                                  className={classes.ratingStar}
                                />
                              </div>
                            </React.Fragment>
                          ) : null}
                        </Grid>
                        {!maxWidth700 ? (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.courseDesc} variant="body2" color="textSecondary" component="p">
                              {courseInfo && courseInfo.desc && courseInfo.desc.length > 250 ? courseInfo.desc.substring(0, 250) + '...' : courseInfo && courseInfo.desc && courseInfo.desc}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </div>
                  ) : null}

                  {viewMode && viewMode === 'article' ? (
                    <div className={classes.courseInfoCntr}>
                      <Grid container spacing={2}>
                        {courseInfo && courseInfo.article_image ? (
                          <Grid item xs={6} sm={6} md={5} lg={5}>
                            <img alt="articleImage" onError={loadAlterImage} src={courseInfo && courseInfo.article_image && courseInfo.article_image} className={classes.media} />
                          </Grid>
                        ) : null}
                        <Grid item xs={6} sm={6} md={7} lg={7} style={{ marginLeft: courseInfo && !courseInfo.article_image ? '8px' : null }}>
                          <Typography className={classes.courseName} gutterBottom variant="h5" component="h2">
                            {courseInfo && courseInfo.article_title && courseInfo.article_title}
                          </Typography>
                          {courseInfo && courseInfo.author_name ? (
                            <Typography variant="body2" color="textSecondary" component="div">
                              <div className={classes.itemLabel}>Author:&nbsp;<p className={classes.itemValue}> {courseInfo && courseInfo.author_name && courseInfo.author_name}</p></div>
                            </Typography>
                          ) : null}
                          {courseInfo && courseInfo.article_reading_time ? (
                            <Typography variant="body2" color="textSecondary" component="div">
                              <div className={classes.itemLabel}>Reading:&nbsp;<p className={classes.itemValue}> {courseInfo && courseInfo.article_reading_time && courseInfo.article_reading_time}</p></div>
                            </Typography>
                          ) : null}
                          {courseInfo && courseInfo.article_timestamp ? (
                            <Typography variant="body2" color="textSecondary" component="div">
                              <div className={classes.itemLabel}>Posted On:&nbsp;<p className={classes.itemValue}> {courseInfo && courseInfo.article_timestamp && convertUTCTimeZone(courseInfo.article_timestamp)}</p></div>
                            </Typography>
                          ) : null}
                          {courseInfo && courseInfo.site_name ? (
                            <Typography variant="body2" color="textSecondary" component="div">
                              <div className={classes.itemLabel}>Site:&nbsp;<p className={classes.itemValue}> {courseInfo && courseInfo.site_name && courseInfo.site_name}</p></div>
                            </Typography>
                          ) : null}
                        </Grid>
                        {!maxWidth700 ? (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.courseDesc} variant="body2" color="textSecondary" component="p">
                              {courseInfo && courseInfo.article_summary && courseInfo.article_summary.length > 250 ? courseInfo.article_summary.substring(0, 250) + '...' : courseInfo && courseInfo.article_summary && courseInfo.article_summary}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </div>
                  ) : null}

                  {viewMode && viewMode === 'video' ? (
                    <Paper elevation={3} className={classes.courseInfoCntr}>
                      <Grid container spacing={2}>
                        {courseInfo && courseInfo.thumbnail && courseInfo.thumbnail.url ? (
                          <Grid item xs={6} sm={6} md={5} lg={5}>
                            <img alt="video" onError={loadAlterImage} src={courseInfo.thumbnail.url} className={classes.media} />
                          </Grid>
                        ) : null}
                        <Grid item xs={6} sm={6} md={7} lg={7} style={{ marginLeft: courseInfo && !courseInfo.title ? '8px' : null }}>
                          <Typography className={classes.courseName} gutterBottom variant="h5" component="h2">
                            {courseInfo && courseInfo.title && courseInfo.title}
                          </Typography>
                          {courseInfo && courseInfo.channel_title ? (
                            <Typography variant="body2" color="textSecondary" component="div">
                              <div className={classes.itemLabel}>By:&nbsp;<p className={classes.itemValue}> {courseInfo && courseInfo.channel_title && courseInfo.channel_title}</p></div>
                            </Typography>
                          ) : null}
                          {courseInfo && courseInfo.publish_time && courseInfo.publish_time ? (
                            <Typography variant="body2" color="textSecondary" component="div">
                              <div className={classes.itemLabel}>Posted On:&nbsp;<p className={classes.itemValue}> {courseInfo && courseInfo.publish_time && convertUTCTimeZone(courseInfo.publish_time)}</p></div>
                            </Typography>
                          ) : null}
                        </Grid>
                        {!maxWidth700 ? (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.courseDesc} variant="body2" color="textSecondary" component="p">
                              {courseInfo && courseInfo.description && courseInfo.description.length >= 100 ? courseInfo.description.substring(0, 100) + '...' : courseInfo && courseInfo.description && courseInfo.description}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Paper>
                  ) : null}
                  {viewMode && viewMode === 'webpage' ? (
                    <Paper elevation={2} style={{ height: '128px' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '13px' }}>
                          <Typography className={classes.courseName} gutterBottom variant="h5" component="h2">
                            {courseInfo && courseInfo.title && courseInfo.title}
                          </Typography>
                          {courseInfo && courseInfo.display_link ? (
                            <Typography variant="body2" color="textSecondary" component="div">
                              <div className={classes.itemLabel}><p className={classes.itemValue}> {courseInfo && courseInfo.display_link && courseInfo.display_link}</p></div>
                            </Typography>
                          ) : null}
                          <Typography className={classes.webpageDesc} variant="body2" color="textSecondary" component="p">
                            {courseInfo && courseInfo.snippet && courseInfo.snippet.length >= 300 ? courseInfo.description.substring(0, 295) + '...' : courseInfo && courseInfo.snippet && courseInfo.snippet}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                </React.Fragment>
              )}
              {(getUserType() === 'admin' || (getUserDetails() && getUserDetails().members > 0)) && viewMode === 'courses' ? (
                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="course" name="course" value={radioValue} onChange={handleChange}>
                      <FormControlLabel value="recommend" control={<Radio color="primary" />} label="Recommend a course" />
                      <FormHelperText>This course will be available to the employee under the recommendation tab in My Path</FormHelperText>
                      <FormControlLabel value="mandate" control={<Radio color="primary" />} label="Make a course mandatory" />
                      <FormHelperText>The course will be mandated for the employee with the set due date, available under Required Courses</FormHelperText>
                    </RadioGroup>
                  </FormControl>
                </div>
              ) : null}
              {radioValue === 'recommend' ? (
                <React.Fragment>
                  <div className={classes.autoCompWrapper}>
                    <AutoCompleteTeamBox
                      onChange={handleSelectTeam}
                      value={selectedTeam}
                      multiple
                      loader={teamsDropdownLoader}
                      loadingText="Loading..."
                      id="teamselect"
                      label="Search for Teams"
                      options={teamList && teamList.length > 0 ? teamList : []}
                      disableCloseOnSelect={false}
                      error={false}
                      required
                      from="teamselect"
                      defaultValue={selectedTeam}
                    />
                    <br />
                    <Autocomplete
                      value={selectedMembers}
                      onChange={(event, newValue) => {
                        handleAddMembers(newValue);
                      }}
                      multiple
                      id="checkboxes-tags-members-demo"
                      loading={singleDropdownLoader}
                      loadingText="Loading..."
                      options={membersList && membersList.length > 0 ? membersList : []}
                      getOptionLabel={(option) => option.email}
                      className={classes.memberListAutoSuggest}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ color: "red", fontSize: "1rem" }}
                          variant="outlined"
                          label="Search your Colleagues..."
                        />
                      )}
                    />
                  </div>
                  {nullCheck && <div style={{ textAlign: 'center', fontSize: '0.8rem', color: 'red' }}>{`*Please select atleast one member to Recommend ${viewMode}`}</div>}
                  {showErrorMsg && <div style={{ textAlign: 'center', fontSize: '0.8rem', color: 'red' }}>{`*Kindly remove the selected employee name(s) as this ${viewMode} has already been taken by them.`}</div>}
                  <div className={classes.buttonWrapper}>
                    <Button
                      className={classes.button}
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={inProgress}
                      onClick={
                        onSubmitRecommendCourse
                      }
                    >
                      {inProgress && (
                        <CircularProgress size={25} className={classes.buttonProgress} />
                      )}
                      Recommend
                    </Button>
                    <Button
                      className={classes.button}
                      variant="contained"
                      size="small"
                      onClick={handleDialogClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className={classes.autoCompWrapper}>
                    <AutoCompleteTeamBox
                      onChange={handleSelectTeam}
                      value={selectedTeam}
                      multiple
                      loader={teamsDropdownLoader}
                      loadingText="Loading..."
                      id="teamselect"
                      label="Search your reporting Teams..."
                      options={teamList && teamList.length > 0 ? teamList : []}
                      disableCloseOnSelect={false}
                      error={false}
                      required
                      from="teamselect"
                    />
                    <br />
                    <Autocomplete
                      value={selectedMembers}
                      onChange={(event, newValue) => {
                        handleAddMembers(newValue);
                      }}
                      multiple
                      id="checkboxes-tags-members-demo"
                      loading={singleDropdownLoader}
                      loadingText="Loading..."
                      options={membersList && membersList.length > 0 ? membersList : []}
                      getOptionLabel={(option) => option.email}
                      className={classes.memberListAutoSuggest}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ color: "red", fontSize: "1rem" }}
                          variant="outlined"
                          label="Search your reporting members..."
                        />
                      )}
                    />
                    <DatePicker
                      className={classes.datePicker}
                      selected={mandateDueDate}
                      selectsStart
                      startDate={mandateDueDate}
                      //endDate={endDate}
                      onChange={date => setMandateDueDate(date)}
                      minDate={new Date()}
                      dateFormat="MMMM d, yyyy"
                      placeholderText="Click to select a date"
                    />
                    <FormHelperText >Please select course due date *</FormHelperText>
                  </div>
                  {showErrorMsg && <div style={{ textAlign: 'center', fontSize: '0.8rem', color: 'red' }}>*Kindly remove the selected employee name(s) as this course has already been taken by them.</div>}
                  {nullCheck && <div style={{ textAlign: 'center', fontSize: '0.8rem', color: 'red' }}>*Please select atleast one member to mandatory Course</div>}
                  <div className={classes.buttonWrapper}>
                    <Button
                      className={classes.button}
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={mandateBtn}
                      onClick={
                        onSubmitRecommendCourse
                      }
                    >
                      {inProgress && (
                        <CircularProgress size={25} className={classes.buttonProgress} />
                      )}
                      Mandatory
                    </Button>
                    <Button
                      className={classes.button}
                      variant="contained"
                      size="small"
                      onClick={handleDialogClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Grid container spacing={2} style={{ justifyContent: "center" }}>
                {selectedTeamMembersList.length > 0 && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper elevation={3} className={classes.membersCntr}>
                      <div className={classes.memCtr}>
                        {selectedTeamMembersList.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              {item.team_members.length > 0 && (
                                <div className={classes.teamWrapper}>
                                  <div className={classes.teamName}>
                                    {item.team} - Team
                                  </div>
                                  <div>{renderTeamListChip(item.team_members)}</div>
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </Paper>
                  </Grid>
                )}
                {selectedEmp.length > 0 && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper elevation={3} className={classes.membersCntr}>
                      {selectedEmp.length > 0 && (
                        <div className={classes.memCtr}>
                          <div className={classes.teamName}>Selected Members </div>
                          {selectedEmp.map((item, index) => {
                            let label = item.name + " - " + item.email;
                            let alreadyRecName = alreadyRecList && alreadyRecList.indexOf(item.email) > -1
                            let userData = alreadyRecUsersList && alreadyRecUsersList.find((data) => data.email === item.email);
                            let labelName =
                              label.length > 40
                                ? label.substring(0, 35) + "..."
                                : label;
                            return (
                              <React.Fragment key={index}>
                                <Chip
                                  key={index}
                                  variant="outlined"
                                  deleteIcon={<HighlightOffIcon style={{ color: '#2e8eec' }} />}
                                  label={labelName}
                                  onDelete={handleDelete(item)}
                                  className={!alreadyRecName ? classes.selMemChip : classes.disabledChip}
                                />
                                {alreadyRecName ? <span style={{ marginLeft: '15px', color: '#9e9e9e', fontSize: '12px', letterSpacing: '1px' }}>{radioValue === 'recommend' ? (userData && userData.status && userData.status) : (userData && userData.is_mandate === true ? 'Already Mandated' : (userData && userData.status && userData.status))}</span> : null}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      )}
                    </Paper>
                  </Grid>
                )}
              </Grid>
              {(selectedTeamMembersList.length > 0 || selectedEmp.length > 0) && alreadyRecList && alreadyRecList.length ? (
                <React.Fragment>
                  <Button
                    className={classes.proceedWrapper}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={inProgress}
                    onClick={
                      onSubmitProceedRecommend
                    }
                  >
                    {inProgress && (
                      <CircularProgress size={25} className={classes.buttonProgress} />
                    )}
                    {radioValue === 'recommend' ? (`Proceed with recommend ${requireRecCount}/${recTotalUsersCount} employee(s)`) : (`Proceed with mandatory ${requireRecCount}/${recTotalUsersCount} employee(s)`)}
                  </Button>
                  <FormHelperText style={{ marginLeft: '10px' }}>Clicking on proceed will remove the above selected employee name(s) who has already taken this course.</FormHelperText>
                </React.Fragment>
              ) : null}
            </Grid>
          </Grid>

        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    details: state.recommendReducer
  }
}

export default connect(mapStateToProps, { closeRecommendDialog, showSnackBar })(RecommendCourse)
