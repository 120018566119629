import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
//LOCAL IMPORTS
import { styleDesigns } from '../../theme/styles';

const useStyles = makeStyles(theme => ({
  checkBoxWrapper: {
    color: `${styleDesigns.fontColour._myAthinaBlue} !important`,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._myAthinaBlueShadow
    }
  },
  green: {
    color: `${styleDesigns.fontColour._appGreen} !important`,
    '&:hover': {
      backgroundColor: styleDesigns.fontColour._myAthinaGreenShadow
    }
  },
  designationBased: {
    color: `#707070 !important`
  }
}));

export default function BasicCheckBox(props) {
  //FOR RENDERING THE BASIC CHECKBOX
  //ID IS FOR UNIQUE IDENTITY OF A CHECKBOX
  //CHECKED IS FOR SHOWING THE CHECKED ICON
  //DISALBED IS TO DISABLE THE CHECKBOX
  //ONCHANGE IS TO LOAD THE ONCHANGE EVENT
  //INDETERMINATE FOR SELECTING ALL THE CHECK ALL
  const classes = useStyles();
  return (
    <Checkbox
      className={props.from ? clsx(classes[props.from]) : classes.checkBoxWrapper}
      size="small"
      id={props.id}
      name={props.name}
      checked={props.checked}
      disabled={props.disabled}
      onChange={props.onChange}
      indeterminate={props.indeterminate}
    />
  )
}