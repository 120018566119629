import { sample } from 'lodash'
const jwt = require('jsonwebtoken');
var downloadCSVfile = require('js-file-download');

export const getEnv = () => {
  switch (window.location.origin) {
    case 'http://testgoniyo.localhost:3000':
    case 'https://testgoniyo.moocpedia.com':
      return 'goniyo_dev'
    case 'http://testce.localhost:3000':
    case 'http://testce.myathina.localhost:3000':
    case 'https://testce.moocpedia.com':
      return 'ce_dev'
    case 'http://caseys.localhost:3000':
    case 'https://caseys.moocpedia.com':
      return 'caseys_dev'
    case 'http://ansrsourcegems.localhost:3000':
    case 'http://ansrsourcegems.myathina.localhost:3000':
    case 'https://ansrsourcegems.moocpedia.com':
      return 'ansrsourcegems_dev'
    case 'http://sandbox.localhost:3000':
    case 'http://sandbox.myathina.localhost:3000':
    case 'https://sandbox.moocpedia.com':
      return 'sandbox_dev'
    case 'http://zopsmart.localhost:3000':
    case 'https://zopsmart.localhost:3000':
    case 'https://zopsmart.moocpedia.com':
      return 'zopsmart_dev'
    case 'http://ansrsource.localhost:3000':
    case 'https://ansrsource.localhost:3000':
    case 'https://ansrsource.moocpedia.com':
      return 'ansrsource_dev'
    case 'http://falabella.localhost:3000':
    case 'https://falabella.localhost:3000':
    case 'https://falabella.moocpedia.com':
      return 'falabella_dev'
    case 'http://freo.localhost:3000':
    case 'https://freo.localhost:3000':
    case 'https://freo.moocpedia.com':
      return 'freo_dev'
    case 'http://demo.localhost:3000':
    case 'https://demo.localhost:3000':
    case 'https://demo.moocpedia.com':
      return 'demo_dev'
    case 'http://prep.localhost:3000':
    case 'https://prep.moocpedia.com':
      return 'prep_dev'
    case 'http://testansr.localhost:3000':
      return 'ansr_dev'
    case 'http://localhost:3000':
      return 'dev'
    case 'http://myathina-b2b.s3-website.ap-south-1.amazonaws.com':
      return 'test'
    case 'http://myathina-b2b-clone.s3-website.ap-south-1.amazonaws.com':
      return 'clone'

    //FOR NEW UX V2 VERSION CONFIGS LOCAL HOST AND MOOCPEDIA
    case 'http://testgoniyov2.localhost:3000':
    case 'http://testcev2.localhost:3000':
    case 'http://caseysv2.localhost:3000':
    case 'http://ansrsourcegemsv2.localhost:3000':
    case 'http://sandboxv2.localhost:3000':
    case 'https://zopsmartv2.localhost.3000':
    case 'http://ansrsourcev2.localhost:3000':
    case 'http://falabellav2.localhost:3000':
    case 'http://freov2.localhost:3000':
    case 'http://demov2.localhost:3000':
    case 'http://prepv2.localhost:3000':
      return 'dev_uxv2'
    case 'https://testgoniyov2.moocpedia.com':
    case 'https://testcev2.moocpedia.com':
    case 'https://caseysv2.moocpedia.com':
    case 'https://ansrsourcegemsv2.moocpedia.com':
    case 'https://sandboxv2.moocpedia.com':
    case 'https://zopsmartv2.moocpedia.com':
    case 'https://ansrsourcev2.moocpedia.com':
    case 'https://falabellav2.moocpedia.com':
    case 'https://freov2.moocpedia.com':
    case 'https://demov2.moocpedia.com':
    case 'https://prepv2.moocpedia.com':
      return 'staging'
    //NEW UX CONFIG END

    case 'https://goniyo.myathina.com':
    case 'https://continualengine.myathina.com':
    case 'https://prep.myathina.com':
    case 'https://zopsmart.myathina.com':
    case 'https://sandbox.myathina.com':
    case 'https://falabella.myathina.com':
    case 'https://ansrsource.myathina.com':
    case 'https://demo.myathina.com':
    case 'https://freo.myathina.com':
    case 'https://caseys.myathina.com':
    case 'https://ansrsourcegems.myathina.com':
      return 'prod'
    default:
      return 'dev'
  }
}

export const generateJwtToken = obj => {
  return jwt.sign(obj, 'shhhhh')
}

export const parseJwt = (token) => {
  var base64Url = token && token.split('.')[1];
  var base64 = base64Url && base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = base64 && decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
};

export const getDomainInfo = () => {
  const tenantConfigs = parseJwt(localStorage.getItem(window.location.origin))
  return tenantConfigs
}

export const getCookie = (name) => {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

export const getUserDetails = () => {
  const domainDet = getDomainInfo();
  if (domainDet !== null) {
    const userToken = getCookie(domainDet.domainUserLabel);
    if (userToken) {
      return parseJwt(userToken)
    } else {
      return {
        roles: []
      }
    }
  }
}

export const getUserType = () => {
  if (getUserDetails() && Object.keys(getUserDetails())) {
    if (getUserDetails().roles.indexOf('Admin') >= 0) {
      return 'admin'
    }
    else if (getUserDetails().members > 0) {
      return 'manager'
    }
    else if (getUserDetails().members === 0) {
      return 'employee'
    }
  }
}

export const openInNewTab = (url = '/') => {
  const win = window.open(url, '_blank');
  win.focus();
}
export const redirectTo = (url = '/', newTab = false) => {
  if (newTab) {
    openInNewTab(url)
  } else {
    window.location = url
  }
}

export const bytesToSize = bytes => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export const convertUTCTimeZone = (utcStr, hasTimeStamp, type) => {
  var option = {}
  if (hasTimeStamp) {
    option = { year: 'numeric', month: 'short', day: "2-digit", hour: '2-digit', minute: '2-digit' }
  } else if (type === 'short') {
    option = { year: 'numeric', month: '2-digit', day: "2-digit" };
  } else {
    option = { year: 'numeric', month: 'short', day: "2-digit" };
  }
  var indiaTime = new Date(utcStr).toLocaleString(type === 'short' ? "en-GB" : "en-US", option);
  return indiaTime.toLocaleString();
}

export const getActiveStepComponent = (stepsArray, filledData) => {
  for (let i in stepsArray) {
    if ((i !== stepsArray[stepsArray.length - 1] && filledData[stepsArray[i]] === null) || (filledData[stepsArray[i]] && filledData[stepsArray[i]].exp_start === null)) {
      return stepsArray[i]
    }
  }
  return 'allFilled'
}

export const getLoggedInStatus = () => {
  const domainInfo = getDomainInfo();
  if (domainInfo !== null) {
    const token = getCookie(domainInfo.domainTokenLabel);
    if (token) {
      return true
    } else {
      return false
    }
  }
}

export const getUrlParams = (url, param) => {
  let hashes = url.slice(url.indexOf('?') + 1).split('&')
  let params = {}
  hashes.map(hash => {
    let [key, val] = hash.split('=')
    return params[key] = decodeURIComponent(val)
  })
  return params[param]
}

export const isRecommendedCourse = courseDetails => {
  const enableSuggestedRibbon = false
  const affiliateProviders = ['Datacamp']
  if (enableSuggestedRibbon) {
    const courseUrl = courseDetails.url ? courseDetails.url : ''
    const provider = courseDetails.provider ? courseDetails.provider : ''
    // const id = getUrlParams(courseUrl, 'id')
    return courseDetails.affiliate || courseUrl.indexOf('click.linksynergy.com') > -1 || affiliateProviders.indexOf(provider) > -1
  } else {
    return false
  }
}

export const hasSearchAccess = () => {
  const userActiveMenus = getUserActiveMenus();
  let searchAccess = '';
  if (getUserType() === 'admin') {
    searchAccess = true
    return searchAccess
  } else {
    const searchMenu = userActiveMenus.find((menu) => (menu.name === 'Search'));
    if (searchMenu && Object.keys(searchMenu) && Object.keys(searchMenu).length) {
      searchAccess = true
    } else {
      searchAccess = false
    }
  }
  return searchAccess
}

export const getCurrentYear = () => {
  let year = new Date().getFullYear()
  return year
}

export const getUserActiveMenus = () => {
  const decodedMenus = parseJwt(localStorage.getItem('user_active_menus'))
  if (decodedMenus && decodedMenus.length) {
    const menus = decodedMenus;
    return menus
  } else {
    return []
  }
}

export const openFreshworksWidget = (type) => {
  if (window.FreshworksWidget) {
    if (type === 'help') {
      const userDetails = getUserDetails()
      window.FreshworksWidget('identify', 'ticketForm', {
        name: userDetails && userDetails.first_name ? `${userDetails.first_name}  ${userDetails.last_name}` : '',
        email: userDetails && userDetails.email ? userDetails.email : '',
      });
      window.FreshworksWidget('open')
    } else {
      const userDetails = getUserDetails()
      window.FreshworksWidget('identify', 'ticketForm', {
        name: userDetails && userDetails.first_name ? `${userDetails.first_name}  ${userDetails.last_name}` : '',
        email: userDetails && userDetails.email ? userDetails.email : '',
      });
      window.FreshworksWidget('open', 'ticketForm')
    }
  }
}

export const validateUploadedFile = (type, acceptedSize, sizeInMbs, allowExtensions) => {
  //TYPE FOR FILE TYPE CLICK
  //ACCEPTED SIZE FOR HOW MUCH CAN BE ACCEPTED
  //ACCEPTED SIZE IN MBS
  //ALLOWED FILE EXTENSIONS
  const selectedFile = document.getElementById(type).files;
  let fileDetails = { fileError: false, fileErrorErrorMsg: '' }
  if (!selectedFile.length || selectedFile.length > 1) {
    fileDetails = { fileError: false, fileErrorErrorMsg: '' }
  } else {
    const file = selectedFile[0]
    if (file && file.size > acceptedSize) {
      fileDetails = { fileError: true, fileErrorErrorMsg: `File size should be less than ${sizeInMbs}` }
    } else {
      const allowedExtensions = allowExtensions
      const fileExtension = file && file.name && file.name.lastIndexOf('.')
      const currentExtension = file && file.name && file.name.slice(fileExtension) && file.name.slice(fileExtension).split('.')[1] && file.name.slice(fileExtension).split('.')[1].toLowerCase()
      if (allowedExtensions.indexOf(currentExtension) === -1) {
        fileDetails = { fileError: true, fileErrorErrorMsg: 'Invalid file format.' }
      } else {
        fileDetails = {
          fileError: false,
          fileErrorErrorMsg: '',
          name: file.name,
          size: bytesToSize(file.size),
          type: file.type,
          file: file
        }
      }
    }
  }
  return fileDetails
}
export const getRandomImage = (array) => {
  if (array && array.length) {
    return (
      sample(array)
    )
  }
}
export const cryptCourseId = (data) => {
  let cipherText = btoa(data);
  return cipherText;
}

//FOR GETTING THE COURSE DURATION IN HOURS
export const getCourseDurationInHourse = (courseDuration = '') => {
  const actualDuration = courseDuration.toLowerCase()
  let type = 'hours'
  type = (actualDuration.indexOf('min') > -1) ? 'minutes' : type
  type = (actualDuration.indexOf('hour') > -1) ? 'hours' : type
  type = (actualDuration.indexOf('month') > -1) ? 'month' : type
  type = (actualDuration.indexOf('week') > -1) ? 'week' : type

  let numArr = courseDuration.split(' ')
  let num = 0
  if (numArr.length) {
    if (numArr.length > 2) {
      num = numArr[1]
    } else {
      num = numArr[0]
    }
  }
  num = Number(num)
  switch (type) {
    case 'hours': {
      return num
    }
    case 'minutes': {
      return Number(num) / 60
    }
    case 'week': {
      return (Number(num) * 7) * 24
    }
    case 'month': {
      return (Number(num) * 30) * 24
    }
    default: {
      return num
    }
  }
}

//FOR DOWNLOADING THE CSV FILE
export const generateCSVReportFile = (fileData, fileName) => {
  downloadCSVfile(fileData, fileName);
}
export const isAdminModeActive = () => {
  return getUserType() === 'admin'
}

export const getCourseOpenModeType = () => {
  //for checking the edit course mode and decoding the base64
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1]

  if (base64EncodeValue === 'Y291cnNlX3Zpc2l0X21vZGU=') {
    return 'visit_mode'
  } else if (base64EncodeValue === 'ZWRpdF9jb3Vyc2U=') {
    return 'edit_mode'
  } else if (base64EncodeValue === 'ZnJvbV9teWNvdXJzZXM=') {
    return 'from_mycourses'
  }
}