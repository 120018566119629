import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { connect } from 'react-redux';
//LOCAL IMPORTS
import { getTenant } from '../Configs/tenantConfig';
import { getCookie, getEnv, getUserDetails } from '../Helpers/basic';
import { PrivateRoute } from '../router';
//COMPONENTS
import Login from '../Components/Login';
import ForgotPassword from '../Components/Login/forgetPassword';
import ChangePassword from '../Components/Login/changePassword';
import SnackbarAlerts from '../Shared/AlertMessages/snackBarAlerts';
import RecommendCourse from '../Shared/DialogPopups/Recommend';
import RedirectPage from '../Components/RedirectPage';

import {
  LandingPage,
  Dashboard,
  SkillMap,
  SkillIndex,
  ManageBudget,
  MyTeam,
  Search,
  MyCourses,
  MyLearningPath,
  MyBudget,
  ApprovalRequests,
  AuthorCourses,
  VerifyApprovals,
  MyEvents,
  MyCertificates,
  MyDocs,
  UserSettings,
  InternalCourses,
  Profile,
  UserProfile,
  DeveloperConfigs,
  ManageClients,
  ClientDashboard,
  ManageGroups,
  ManageGroupLearners,
  ManageCoupons,
  CouponDashboard,
  InitiateCourse,
  EditAuthoredCourse,
  Assessments,
  Assignments,
  Discussions,
  CoursePublish,
  CourseReport,
  GradeBook
} from './components';


function Layout(props) {
  const tenantInfo = getTenant();
  const token = tenantInfo && getCookie(tenantInfo.domainTokenLabel);
  const alertReducer = props.alertReducer;
  const apiEnvConfig = getEnv();
  const userDetails = getUserDetails();
  useEffect(() => {
    const url = window.location.href
    const pathName = window.location.pathname
    const isChangePasswordPage = pathName.indexOf('/Y2hhbmdlX3Bhc3N3b3Jk/') > -1
    if (tenantInfo && !tenantInfo.sso) {
      if (window.location.pathname !== '/' && window.location.pathname !== '/forgotpassword' && !isChangePasswordPage && !token) {
        window.location = '/'
      } else if (window.location.pathname === '/' && token) {
        window.location = userDetails && userDetails.defaultLandingPage ? userDetails.defaultLandingPage : '/app'
      }
    } else {
      if (window.location.pathname !== '/' && url.indexOf('/redirect?') < 0 && url.indexOf('/init') < 0 && url.indexOf('/auth/') < 0 && !token) {
        // window.location = `https://us-central1-sso-b2bformany.cloudfunctions.net/app/login?tenant=${tenantInfo.tenant}`
        window.location = '/'
      } else {
        if (window.location.pathname === '/' && token) {
          window.location = userDetails && userDetails.defaultLandingPage ? userDetails.defaultLandingPage : '/app'
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {!token ? (
        <Routes>
          <Route path="/" element={<Login location={props.location} history={props.history} />} />
          <Route path='/auth/sso-mard/:etaToken' element={<Login location={props.location} history={props.history} />} />
          <Route path="/forgotpassword" element={<ForgotPassword location={props.location} history={props.history} />} />
          <Route path="/Y2hhbmdlX3Bhc3N3b3Jk/:userToken" element={<ChangePassword location={props.location} history={props.history} />} />
          <Route path="/redirect" element={<RedirectPage location={props.location} history={props.history} />} />
        </Routes>
      ) : (
        <Routes>
          <Route path='/auth/sso-mard/:etaToken' element={<Login location={props.location} history={props.history} />} />
          <Route path="/redirect" element={<RedirectPage location={props.location} history={props.history} />} />
          <Route path="/welcome" element={<PrivateRoute />} >
            <Route path="/welcome" element={<UserProfile location={props.location} history={props.history} />} />
          </Route>

          <Route path="/profile" element={<PrivateRoute />} >
            <Route path="/profile" element={<Profile location={props.location} history={props.history} />} />
          </Route>

          <Route path="/landingpage" element={<PrivateRoute />} >
            <Route path="/landingpage" element={<LandingPage location={props.location} history={props.history} />} />
          </Route>
          <Route path="/app" element={<PrivateRoute />} >
            <Route path="/app" element={<Dashboard location={props.location} history={props.history} />} />
          </Route>
          <Route path="/skillmap" element={<PrivateRoute />} >
            <Route path="/skillmap" element={<SkillMap location={props.location} history={props.history} />} />
          </Route>
          <Route path="/skillindex" element={<PrivateRoute />} >
            <Route path="/skillindex" element={<SkillIndex location={props.location} history={props.history} />} />
          </Route>
          <Route path="/budget" element={<PrivateRoute />} >
            <Route path="/budget" element={<ManageBudget location={props.location} history={props.history} />} />
          </Route>
          <Route path="/members" element={<PrivateRoute />} >
            <Route path="/members" element={<MyTeam location={props.location} history={props.history} />} />
          </Route>

          <Route path="/appconfig" element={<PrivateRoute />} >
            <Route path="/appconfig" element={<UserSettings location={props.location} history={props.history} />} />
          </Route>

          <Route path="/search" element={<PrivateRoute />} >
            <Route path="/search" element={<Search location={props.location} history={props.history} />} />
          </Route>
          <Route path="/mycourses" element={<PrivateRoute />} >
            <Route path="/mycourses" element={<MyCourses location={props.location} history={props.history} />} />
          </Route>
          <Route path="/mypath" element={<PrivateRoute />} >
            <Route path="/mypath" element={<MyLearningPath location={props.location} history={props.history} />} />
          </Route>
          <Route path="/mybudget" element={<PrivateRoute />} >
            <Route path="/mybudget" element={<MyBudget location={props.location} history={props.history} />} />
          </Route>
          <Route path="/approvalrequests" element={<PrivateRoute />} >
            <Route path="/approvalrequests" element={<ApprovalRequests location={props.location} history={props.history} />} />
          </Route>
          <Route path="/authorcourses" element={<PrivateRoute />} >
            <Route path="/authorcourses" element={<AuthorCourses location={props.location} history={props.history} />} />
          </Route>
          <Route path="/learningmaterials" element={<PrivateRoute />} >
            <Route path="/learningmaterials" element={<InternalCourses location={props.location} history={props.history} />} />
          </Route>
          <Route path="/verifyapprovals" element={<PrivateRoute />} >
            <Route path="/verifyapprovals" element={<VerifyApprovals location={props.location} history={props.history} />} />
          </Route>
          <Route path="/myevents" element={<PrivateRoute />} >
            <Route path="/myevents" element={<MyEvents location={props.location} history={props.history} />} />
          </Route>
          <Route path="/mycertificates" element={<PrivateRoute />} >
            <Route path="/mycertificates" element={<MyCertificates location={props.location} history={props.history} />} />
          </Route>
          <Route path="/mydocs" element={<PrivateRoute />} >
            <Route path="/mydocs" element={<MyDocs location={props.location} history={props.history} />} />
          </Route>
          <Route path="/clients" element={<PrivateRoute />} >
            <Route path="/clients" element={<ManageClients location={props.location} history={props.history} />} />
          </Route>
          <Route path="/client/:slugName" element={<PrivateRoute />} >
            <Route path="/client/:slugName" element={<ClientDashboard location={props.location} history={props.history} />} />
          </Route>
          <Route path="/client/:slugName/adduser" element={<PrivateRoute />} >
            <Route path="/client/:slugName/adduser" element={<UserSettings location={props.location} history={props.history} />} />
          </Route>
          <Route path="/client/:slugName/groups" element={<PrivateRoute />} >
            <Route path="/client/:slugName/groups" element={<ManageGroups location={props.location} history={props.history} />} />
          </Route>
          <Route path="/client/:slugName/grouplearners/:groupId" element={<PrivateRoute />} >
            <Route path="/client/:slugName/grouplearners/:groupId" element={<ManageGroupLearners location={props.location} history={props.history} />} />
          </Route>
          <Route path="/coupons" element={<PrivateRoute />} >
            <Route path="/coupons" element={<ManageCoupons location={props.location} history={props.history} />} />
          </Route>
          <Route path="/coupon/courses/:couponId" element={<PrivateRoute />} >
            <Route path="/coupon/courses/:couponId" element={<CouponDashboard location={props.location} history={props.history} />} />
          </Route>

          <Route path="/initiatecourse" element={<PrivateRoute />}>
            <Route path="/initiatecourse" element={<InitiateCourse location={props.location} history={props.history} />} />
          </Route>
          <Route path="/mycourse/:courseId" element={<PrivateRoute />}>
            <Route path="/mycourse/:courseId" element={<EditAuthoredCourse location={props.location} history={props.history} />} />
          </Route>
          <Route path="/mycourse/:courseId/:moduleId" element={<PrivateRoute />}>
            <Route path="/mycourse/:courseId/:moduleId" element={<EditAuthoredCourse location={props.location} history={props.history} />} />
          </Route>
          <Route path="/mycourse/:courseId/:moduleId/:lessonId" element={<PrivateRoute />}>
            <Route path="/mycourse/:courseId/:moduleId/:lessonId" element={<EditAuthoredCourse location={props.location} history={props.history} />} />
          </Route>

          <Route path="/assessments/:courseId" element={<PrivateRoute />}>
            <Route path="/assessments/:courseId" element={<Assessments location={props.location} history={props.history} />} />
          </Route>
          <Route path="/assessments/:courseId/:moduleId" element={<PrivateRoute />}>
            <Route path="/assessments/:courseId/:moduleId" element={<Assessments location={props.location} history={props.history} />} />
          </Route>
          <Route path="/assessments/:courseId/:moduleId/:lessonId" element={<PrivateRoute />}>
            <Route path="/assessments/:courseId/:moduleId/:lessonId" element={<Assessments location={props.location} history={props.history} />} />
          </Route>

          <Route path="/assignments/:courseId" element={<PrivateRoute />}>
            <Route path="/assignments/:courseId" element={<Assignments location={props.location} history={props.history} />} />
          </Route>
          <Route path="/assignment/report/:courseId/:assignmentId" element={<PrivateRoute />}>
            <Route path="/assignment/report/:courseId/:assignmentId" element={<Assignments location={props.location} history={props.history} />} />
          </Route>
          <Route path="/assignments/:courseId/:moduleId" element={<PrivateRoute />}>
            <Route path="/assignments/:courseId/:moduleId" element={<Assignments location={props.location} history={props.history} />} />
          </Route>
          <Route path="/assignment/report/:courseId/:moduleId/:assignmentId" element={<PrivateRoute />}>
            <Route path="/assignment/report/:courseId/:moduleId/:assignmentId" element={<Assignments location={props.location} history={props.history} />} />
          </Route>
          <Route path="/assignments/:courseId/:moduleId/:lessonId" element={<PrivateRoute />}>
            <Route path="/assignments/:courseId/:moduleId/:lessonId" element={<Assignments location={props.location} history={props.history} />} />
          </Route>
          <Route path="/assignment/report/:courseId/:moduleId/:lessonId/:assignmentId" element={<PrivateRoute />}>
            <Route path="/assignment/report/:courseId/:moduleId/:lessonId/:assignmentId" element={<Assignments location={props.location} history={props.history} />} />
          </Route>

          <Route path="/discussions/:courseId" element={<PrivateRoute />}>
            <Route path="/discussions/:courseId" element={<Discussions location={props.location} history={props.history} />} />
          </Route>
          <Route path="/discussions/:courseId/:discussionId" element={<PrivateRoute />}>
            <Route path="/discussions/:courseId/:discussionId" element={<Discussions location={props.location} history={props.history} />} />
          </Route>

          <Route path="/coursepublish/:courseId" element={<PrivateRoute />}>
            <Route path="/coursepublish/:courseId" element={<CoursePublish location={props.location} history={props.history} />} />
          </Route>

          <Route path="/coursereport/:courseType/:courseId" element={<PrivateRoute />}>
            <Route path="/coursereport/:courseType/:courseId" element={<CourseReport location={props.location} history={props.history} />} />
          </Route>

          <Route path="/gradebook/:courseId" element={<PrivateRoute />}>
            <Route path="/gradebook/:courseId" element={<GradeBook location={props.location} history={props.history} />} />
          </Route>

          {apiEnvConfig === 'dev_uxv2' || apiEnvConfig === 'staging' ? (
            <Route path="/developerconfigs" element={<PrivateRoute />} >
              <Route path="/developerconfigs" element={<DeveloperConfigs location={props.location} history={props.history} />} />
            </Route>
          ) : null}
        </Routes>
      )}
      <SnackbarAlerts state={alertReducer.state} message={alertReducer.message} type={alertReducer.type} />
      <RecommendCourse />
    </React.Fragment >
  )

}

const mapStateToProps = state => {
  return {
    authDetails: state.authReducer.authDetails,
    alertReducer: state.alertReducer,
  }
}
export default connect(
  mapStateToProps, {}
)(Layout);
