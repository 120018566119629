import React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
//LOCAL IMPORTS
import { styleDesigns } from '../../theme/styles';
import { getTenant } from '../../Configs/tenantConfig';
import { getUserType } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  navIconWrapper: {
    zIndex: 1000,
    position: 'fixed !important',
    top: '8px !important',
    marginLeft: '10px !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  navIconOpenWrapper: {
    zIndex: 1000,
    position: 'fixed !important',
    top: '7px !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  prepIcon: {
    zIndex: 1000,
    position: 'fixed !important',
    top: '23px !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  prepAdminIcon: {
    zIndex: 1000,
    position: 'fixed !important',
    top: '15px !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  iconWrapper: {
    color: styleDesigns.fontColour._myAthinaBlue,
    fontSize: '33px !important'
  },
  iconOpenWrapper: {
    color: styleDesigns.fontColour._myAthinaBlue,
    fontSize: '32px !important'
  }
}));

export default function NavController(props) {
  const classes = useStyles();
  const tenantInfo = getTenant();
  const userType = getUserType();

  const handleDirectionClick = () => {
    if (props.open) {
      props.onPinLeftMenu(false)
      props.handleDrawerClose()
    } else {
      props.onPinLeftMenu(true)
      props.handleDrawerOpen()
    }
  }

  return (
    <IconButton aria-label="expand or collapse menu" onClick={handleDirectionClick} className={props.open ? (tenantInfo.name === 'PREP' && userType === 'admin') ? classes.prepAdminIcon : (tenantInfo.name === 'PREP' && userType !== 'admin') ? classes.prepIcon : classes.navIconOpenWrapper : classes.navIconWrapper}>
      {props.open ? <CloseIcon className={classes.iconOpenWrapper} /> : <MenuIcon className={classes.iconWrapper} />}
    </IconButton>
  );
}
