import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
//LOCAL IMPORTS
import { styleDesigns } from '../../theme/styles';
import { closeSnackBar } from '../../store/AlertMessages/actionCreator';

const useStyles = makeStyles(theme => ({
  info: {
    color: `#1890FF !important`,
    borderLeft: `12px solid #1890FF`,
    borderRadius: '8px',
    padding: '6px 16px',
    backgroundImage: 'none',
    lineHeight: 1.57143,
    fontSize: `${styleDesigns.fontSize._infoHeading} !important`,
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    fontWeight: 500,
    backgroundColor: `${styleDesigns.fontColour._white}`,
    marginBottom: '10px',
    marginTop: '10px',
    display: 'flex',
  },
  success: {
    color: `${styleDesigns.fontColour._myAthinaGreen} !important`,
    borderLeft: `12px solid ${styleDesigns.fontColour._myAthinaGreen}`,
    borderRadius: '8px',
    padding: '6px 16px',
    backgroundImage: 'none',
    lineHeight: 1.57143,
    fontSize: `${styleDesigns.fontSize._infoHeading} !important`,
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    fontWeight: 500,
    backgroundColor: `${styleDesigns.fontColour._white} !important`,
    marginBottom: '10px',
    marginTop: '10px',
    display: 'flex'
  },
  error: {
    color: `#FF4842 !important`,
    borderLeft: `12px solid #FF4842`,
    borderRadius: '8px',
    padding: '6px 16px',
    backgroundImage: 'none',
    lineHeight: 1.57143,
    fontSize: `${styleDesigns.fontSize._infoHeading} !important`,
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    fontWeight: 500,
    backgroundColor: `${styleDesigns.fontColour._white} !important`,
    marginBottom: '10px',
    marginTop: '10px',
    display: 'flex'
  },
  warning: {
    color: `#ED6D37 !important`,
    borderLeft: `12px solid #ED6D37`,
    borderRadius: '8px',
    padding: '6px 16px',
    backgroundImage: 'none',
    lineHeight: 1.57143,
    fontSize: `${styleDesigns.fontSize._infoHeading} !important`,
    fontFamily: `${styleDesigns.fontFamily._heading} !important`,
    fontWeight: 500,
    backgroundColor: `${styleDesigns.fontColour._white} !important`,
    marginBottom: '10px',
    marginTop: '10px',
    display: 'flex'
  },
  icon: {
    fontSize: '22px !important',
    verticalAlign: '-5px',
    marginRight: '12px !important',
    display: 'flex',
    opacity: '0.9',
    marginTop: '8px'
  },
  textWrapper: {
    padding: '4px 0px',
    marginTop: '6px !important'
  },
  messageWrapper: {
    fontFamily: styleDesigns.fontFamily._heading
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: '10px',
    fontSize: 20,
    verticalAlign: '-5px'
  }
}))

function SnackbarAlerts(props) {
  //FOR THE SNACKBAR ALERTS
  //STATUS WILL DEFINE WHAT TYPE OF ALERT BOXES YOU NEED
  //BASED ON THE STATUS ABOVE CSS WILL BE APPLIED
  //MESSAGE IS FOR THE ALERT MESSAGE
  const classes = useStyles();

  const state = {
    open: props.state,
    vertical: 'top',
    horizontal: 'center',
  };
  const { vertical, horizontal } = state;

  const handleClose = () => {
    props.closeSnackBar()
  };

  const getIcon = () => {
    if (props.type === 'success') {
      return (
        <TaskAltIcon className={classes.iconVariant} />
      )
    }
    else if (props.type === 'error') {
      return (
        <ErrorOutlineIcon className={classes.iconVariant} />
      )
    }
    else if (props.type === 'info') {
      return (
        <InfoOutlinedIcon className={classes.iconVariant} />
      )
    }
    else if (props.type === 'warning') {
      return (
        <WarningAmberIcon className={classes.iconVariant} />
      )
    }
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      key={`${vertical},${horizontal}`}
      open={props.state}
      onClose={handleClose}
      autoHideDuration={props.type !== 'error' ? 5000 : 2000}

      ContentProps={{
        'aria-describedby': 'message-id',
        classes: {
          root: classes[props.type]
        }
      }}
      message={
        <React.Fragment>
          {getIcon()}
          <span id="message-id" className={classes.messageWrapper}>{props.message}</span>
        </React.Fragment>
      }
    />
  );
}
const mapStateToProps = state => {
  return {
    alertReducer: state.alertReducer
  }
}
export default connect(
  mapStateToProps, { closeSnackBar }
)(SnackbarAlerts);